import { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid2 as Grid, Button, IconButton, Typography, Divider, Box,
  Accordion as AccordionComponent, AccordionSummary as AccordionSummaryComponent, AccordionDetails } from '@mui/material'
import { deletePartnerRateCard } from '../../actions/rateCardsManagementActions'
import { getS3File } from '../../actions/commonActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DownloadButton from '../../components/DownloadButton'
import RateCardsManagementUpload from './RateCardsManagementUpload'

const Accordion = styled(props => <AccordionComponent square disableGutters defaultExpanded elevation={0} {...props} />)(() => ({
  padding: '8px',
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(0,0,0,0.12)'
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => <AccordionSummaryComponent expandIcon={<KeyboardArrowDownIcon />} {...props} />)(() => ({
  height: '48px',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const AccordionTitle = styled(props => <Typography {...props}>{props.children}</Typography>)(() => ({
  fontWeight: 600
}))

const Seperator = styled(props => <Divider {...props} />)(() => ({
  backgroundColor: 'transparent',
  marginBottom: '12px',
  marginTop: '12px'
}))

const Label = styled(props => <Typography {...props}>{props.children}</Typography>)(() => ({
  width: '100px',
  minWidth: '100px',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#616161'
}))

const ALERT = {
  UPDATE_SUCCESS: "UPDATE SUCCESS",
  UPDATE_FAILED: "UPDATE FAILED",
  DELETE_SUCCESS: "DELETE SUCCESS",
  DELETE_FAILED: "DELETE FAILED",
  DOWNLOAD_SUCCESS: "DOWNLOAD SUCCESS",
  DOWNLOAD_FAILED: "DOWNLOAD FAILED",
}

function Item({ label, text, component }) {
  return (
    <Box display='flex'>
      <Label>{label}:</Label>
      {component || <Typography sx={{ paddingX: '4px' }}>{text}</Typography>}
    </Box>
  )
}

function RateCard({ rateCard, download, pending }) {
  return (
    <Grid container spacing={1}>
      <Grid size={6}>
        <Item
          label='Status'
          text={pending ? 'Pending Effective Date' : rateCard.status ? 'Active' : 'Inactive'}
        />
      </Grid>
      <Grid size={6}>
        {/* <Item
          label='Service Type'
          text={rateCard.service_type}
        /> */}
      </Grid>
      <Grid size={6}>
        <Item
          label='Price Sheet'
          component={
            <Box display='flex' flexDirection='column' alignItems='flex-start' overflow='hidden' paddingLeft='4px'>
              {rateCard.price_sheets.map(({ name, path }) => {
                return (
                  <DownloadButton
                    onClick={() => download(path)}
                    key={path}>
                    {name.length > 30 ? name.slice(0, 30) + '...' : name}
                  </DownloadButton>
                )
              })}
            </Box>
          }
        />
      </Grid>
      <Grid size={6}>
        <Item
          label='Zone Version'
          text={rateCard.zone_version}
        />
      </Grid>
      <Grid size={6}>
        <Item
          label='FSA Version'
          text={rateCard.fsa_version}
        />
      </Grid>
      <Grid size={6}>
        <Item
          label='Effective Date'
          text={rateCard.effective_date}
        />
      </Grid>
      <Grid size={6}>
        <Item
          label='Billing Date'
          text={rateCard.billing_date}
        />
      </Grid>
      <Grid size={6}>
        <Item
          label='Expiry Date'
          text={rateCard.expiry_date}
        />
      </Grid>
      <Grid size={12}>
        <Item
          label='Note'
          text={rateCard.note}
        />
      </Grid>
    </Grid>
  )
}

// function BasicInfoForm({ partnerId, kaName, kaEmail, onCancel, onSubmit }) {
//   const dispatch = useDispatch()
//   const [name, setName] = useState(kaName)
//   const [email, setEmail] = useState(kaEmail)

//   async function save() {
//     try {
//       await dispatch(updatePartnerKA(partnerId, { name, email }))
//       dispatch(showAlertSnackbar({ message: ALERT.UPDATE_SUCCESS, type: 'success' }));
//       onSubmit(name, email)
//     }
//     catch (e) {
//       dispatch(showAlertSnackbar({ message: ALERT.UPDATE_FAILED, type: 'error' }));
//     }
//   }

//   return (<>
//     <Grid container spacing={1}>
//       <Grid size={6}>
//         <Box display='flex' alignItems='center'>
//           <Label>KA Manager:</Label>
//           <TextField
//             value={name}
//             onChange={e => setName(e.target.value)}
//             variant='standard'
//           />
//         </Box>
//       </Grid>
//       <Grid size={6}>
//         <Box display='flex' alignItems='center'>
//           <Label>KA Email:</Label>
//           <TextField
//             value={email}
//             onChange={e => setEmail(e.target.value)}
//             variant='standard'
//           />
//         </Box>
//       </Grid>
//     </Grid>
//     <Box marginTop='24px'>
//       <Button onClick={save} variant='contained' color='secondary'>
//         Save
//       </Button>
//       <Button onClick={onCancel} color='error' sx={{ marginLeft: 1 }}>
//         Cancel
//       </Button>
//     </Box>
//   </>)
// }

// function BasicInfo({ partner, onUpdate }) {
//   const [expanded, setExpanded] = useState(true)
//   const [editing, setEditing] = useState(false)
//   const [name, setName] = useState(partner.ka_name)
//   const [email, setEmail] = useState(partner.ka_email)

//   return (
//     <Accordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)}>
//       <AccordionSummary>
//         <AccordionTitle>
//           Basic information
//         </AccordionTitle>
//         {!editing &&
//           <IconButton onClick={e => { setEditing(true); if (expanded) e.stopPropagation() }} size='small'>
//             <EditIcon />
//           </IconButton>
//         }
//       </AccordionSummary>
//       <AccordionDetails>
//         {editing ?
//           <BasicInfoForm
//             partnerId={partner.partner_id}
//             kaName={name}
//             kaEmail={email}
//             onCancel={() => setEditing(false)}
//             onSubmit={(name, email) => {
//               setName(name)
//               setEmail(email)
//               setEditing(false)
//               onUpdate()
//             }}
//           />
//           :
//           <Grid container spacing={1}>
//             <Grid size={6}>
//               <Item
//                 label='KA Manager'
//                 text={name}
//               />
//             </Grid>
//             <Grid size={6}>
//               <Item
//                 label='KA Email'
//                 text={email}
//               />
//             </Grid>
//           </Grid>
//         }
//       </AccordionDetails>
//     </Accordion>
//   )
// }

export default function RateCardsManagementDetail({ partner, onClose, onUpdate }) {
  const dispatch = useDispatch()
  const [deleting, setDeleting] = useState(false)
  const [uploading, setUploading] = useState(false)

  async function deleteUpcomingDateCard() {
    try {
      await dispatch(deletePartnerRateCard(partner.partner_id, partner.upcoming_rate_card.id))
      onUpdate()
      onClose()
      dispatch(showAlertSnackbar({ message: ALERT.DELETE_SUCCESS, type: 'success' }));
      setDeleting(false)
    }
    catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.DELETE_FAILED, type: 'error' }));
    }
  }

  async function download(path) {
    try {
      const blob = await dispatch(getS3File(path))

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      dispatch(showAlertSnackbar({ message: ALERT.DOWNLOAD_SUCCESS, type: 'success' }));
    }
    catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.DOWNLOAD_FAILED, type: 'error' }));
    }
  }

  return (<>
    <Dialog
      open={Boolean(partner)}
      onClose={() => {
        onClose()
        setUploading(false)
      }}
      scroll='paper'
      maxWidth='md'
      fullWidth>
      {partner && <>
        {uploading ?
          <RateCardsManagementUpload
            partnerId={partner.partner_id}
            onBack={() => setUploading(false)}
            onUpload={() => {
              onUpdate()
              onClose()
              setUploading(false)
            }}
          />
          : <>
          <DialogTitle>{partner.partner_name}</DialogTitle>
          <DialogContent dividers sx={{ padding: 0 }}>
            {/* <BasicInfo
              partner={partner}
              onUpdate={onUpdate}
            /> */}
            {partner.rate_cards.length > 0 &&
              <Accordion>
                <AccordionSummary>
                  <AccordionTitle>
                    Latest Three Rate Cards
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  {partner.rate_cards.map((rateCard, index) => {
                    return (
                      <Fragment key={rateCard.id}>
                        <RateCard
                          rateCard={rateCard}
                          download={download}
                        />
                        {index !== partner.rate_cards.length - 1 && <Seperator />}
                      </Fragment>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            }
            {partner.upcoming_rate_card &&
              <Accordion sx={{ backgroundColor: '#EDF6FF' }}>
                <AccordionSummary>
                  <AccordionTitle>
                    Upcoming Rate Card
                  </AccordionTitle>
                  <IconButton onClick={e => { e.stopPropagation(); setDeleting(true) }}>
                    <DeleteIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <RateCard
                    rateCard={partner.upcoming_rate_card}
                    download={download}
                    pending
                  />
                  {(partner.upcoming_rate_card.sample_bill || partner.upcoming_rate_card.faulty_parcels) &&
                    <Box marginY='12px'>
                      <AccordionTitle>
                        Sample Bill
                      </AccordionTitle>
                    </Box>
                  }
                  <Grid container spacing={1}>
                    {partner.upcoming_rate_card.sample_bill &&
                      <Grid size={6}>
                        <Item
                          label='Sample Bill'
                          component={
                            <DownloadButton onClick={() => download(partner.upcoming_rate_card.sample_bill.path)}>
                              {partner.upcoming_rate_card.sample_bill.name}
                            </DownloadButton>
                          }
                        />
                      </Grid>
                    }
                    {partner.upcoming_rate_card.faulty_parcels &&
                      <Grid size={6}>
                        <Item
                          label='Faulty Pacels'
                          component={
                            <DownloadButton onClick={() => download(partner.upcoming_rate_card.faulty_parcels.path)}>
                              {partner.upcoming_rate_card.faulty_parcels.name}
                            </DownloadButton>
                          }
                        />
                      </Grid>
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            }
          </DialogContent>
          <DialogActions sx={{ padding: 3, justifyContent: 'space-between' }}>
            <Button onClick={() => setUploading(true)} startIcon={<AddIcon />} variant='contained' color='secondary'>
              Rate Card
            </Button>
            <Button onClick={onClose} color='secondary'>
              Close
            </Button>
          </DialogActions>
        </>}
      </>}
    </Dialog>
    <Dialog
      open={deleting}
      onClose={() => setDeleting(false)}
      maxWidth='xs'>
      <DialogTitle>Delete Upcoming Rate Card</DialogTitle>
      <DialogContent dividers>
        Confirm deletion of this upcoming rate card? The expiry date of the current rate card will be restored.
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={() => setDeleting(false)} color='secondary'>
          Cancel
        </Button>
        <Button onClick={deleteUpcomingDateCard} color='error'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}
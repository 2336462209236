import {
  Card, Box, Button, Grid2 as Grid, Link,
  Dialog, DialogTitle, DialogActions, DialogContent,
  Accordion, AccordionSummary, AccordionDetails,
  Typography, MenuItem, TextField, IconButton, InputAdornment
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FaultyPackageIssueType, FaultyPackageRecordStatus, FaultyPackageClientDecision,
  FaultyPackageActionTaken, FaultyPackagePackageStatus
} from '../../utilities/constants.js';
import { getFileById, deleteRecord, getImagesById, saveImagesById, updateRecordInformation, getInfoByTrackingNumber } from '../../actions/faultyPackageActions.js';
import { showAlertSnackbar } from '../../reducers/sliceReducer.js';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import * as XLSX from 'xlsx';

const RECORD_INFO = {
  record_date: "",
  warehouse: "",
  tracking_number: "",
  sender_reference: "",
  item_number: "",
  issue_type: "",
  uni_notes: "",
  package_status: "",
  verified_tracking_number: "",
  client_decision: "",
  action_taken: "",
  client_notes: "",
  closing_notes: "",
  closed_at: ""
};

const NEW_RECORD_STATUS = 1;
const CLIENT_REVIEWED = 2;
const UNI_CONFIRMED = 3;

export default function FaultyPackageDisplay(props) {
  const { records, stats, warehouseDropDown, refresh, setloading } = props;
  const dispatch = useDispatch();
  const [editUniRecordOpen, setEditUniRecordOpen] = useState(false);
  const [recordInfo, setRecordInfo] = useState(RECORD_INFO);
  const [editing, startEdit] = useState(false);
  const [confirming, startConfirm] = useState(false);
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [deletConf, openDeleteConf] = useState(false);
  const [file, setFile] = useState(null);
  const [imageDetail, setImageDetail] = useState(null);
  const [dialogStateSettings, setdialogStateSettings] = useState({});

  const faultyPackageColumns = [
    {
      field: 'created_at',
      headerName: "Creation Time",
      width: 200,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'warehouse',
      headerName: "Warehouse",
      width: 200,
      valueGetter: (value) => {
        return warehouseDropDown[value];
      },
    },
    {
      field: 'tracking_number',
      headerName: "Tracking No.",
      width: 200,
    },
    {
      field: 'sender_reference',
      headerName: "Sender Ref",
      width: 200,
    },
    {
      field: 'item_number',
      headerName: "Item No.",
      width: 200,
    },
    {
      field: 'issue_type',
      headerName: "Issue Type",
      width: 200,
      valueGetter: (value) => {
        return FaultyPackageIssueType[value];
      },
    },
    {
      field: 'package_status',
      headerName: "Package Status",
      width: 200,
      valueGetter: (value) => {
        return FaultyPackagePackageStatus[value];
      },
    },
    {
      field: 'record_status',
      headerName: "Record Status",
      width: 200,
      valueGetter: (value) => {
        if (value === CLIENT_REVIEWED) {
          return "Action Required";
        } else {
          return FaultyPackageRecordStatus[value];
        }
      },
    },
  ]

  const handleOpenRecord = async (e) => {
    setloading(true);
    let temp = e.row;
    //set the states of accordion sections based on role and record state
    if (temp.record_status === NEW_RECORD_STATUS) {
      setdialogStateSettings({
        disabled1: false,
        editable1: true,
        expanded1: true,
        disabled2: true,
        editable2: false,
        expanded2: false,
        disabled3: true,
        editable3: false,
        expanded3: false,
      })
    } else if (temp.record_status === CLIENT_REVIEWED) {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: false,
        expanded3: false,
      })
    } else if (temp.record_status === UNI_CONFIRMED) {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: true,
        expanded3: true,
      })
    } else {
      setdialogStateSettings({
        disabled1: false,
        editable1: false,
        expanded1: true,
        disabled2: false,
        editable2: false,
        expanded2: true,
        disabled3: false,
        editable3: false,
        expanded3: true,
      })
    }

    Object.keys(temp).forEach(function (key) {
      if (temp[key] === null) {
        temp[key] = '';
      }
    })
    setRecordInfo(temp);
    await dispatch(getImagesById(temp.id)).then((res) => {
      setImages(res);
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: "Fetch Image Failed: " + err.message, type: 'error' }));
    })

    handleGetFile(e.row);

    setEditUniRecordOpen(true);
    setloading(false);
  }
  const handleGetFile = async (row) => {
    try {
      const res = await dispatch(getFileById(row.id));
      // Decode the Base64 string to binary data
      const binaryData = atob(res);

      // Create a Uint8Array from the binary data
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Create a File object (optional, but useful if you want to set a file name)
      const file = new File([blob], "faulty_package_record.xlsx", { type: blob.type });

      setFile(file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUploadImages = (e) => {
    if (e.target.files.length <= 5) {
      setNewImages([...e.target.files]);
    } else {
      dispatch(showAlertSnackbar({ type: "error", message: "Max 5 images allowed" }));
    }
  }
  function removeImage(index) {
    let temp = [...newImages];
    temp.splice(index, 1);
    setNewImages(temp);
  }

  async function handleDeleteRecord() {
    setEditUniRecordOpen(false);
    openDeleteConf(false);

    await dispatch(deleteRecord(recordInfo.id))
      .then((res) => {
        dispatch(showAlertSnackbar({ message: "Record Deleted", type: 'success' }));
      })
      .catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })

    refresh();

  }
  const handleDownload = () => {
    if (file) {
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(file);
      downloadLink.href = url;
      downloadLink.download = 'faulty_package_record.xlsx';
      downloadLink.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecordInfo({
      ...recordInfo,
      [name]: value,
    });
  };

  function downloadAllRecords() {
    var filtered = records;
    filtered = filtered.map(({ created_at, warehouse, tracking_number, sender_reference, item_number, issue_type, package_status, record_status, ...rest }) => ({ "Created At": created_at, "Warehouse": warehouseDropDown[warehouse], " Tracking Number": tracking_number, "Sender Reference": sender_reference, "Item Number": item_number, "Issue Type": FaultyPackageIssueType[issue_type], "Package Status": FaultyPackagePackageStatus[package_status], "Record Status": FaultyPackageRecordStatus[record_status] }));

    filtered.reverse();
    var workbook = XLSX.utils.book_new();
    var workSheet = XLSX.utils.json_to_sheet(filtered);
    workbook.SheetNames.push("Faulty_Package_Records");
    workbook.Sheets["Faulty_Package_Records"] = workSheet;
    XLSX.writeFile(workbook, "Faulty_Package_Records.xlsx");
  }

  async function updateRecord(updateStatus) {
    if (newImages.length > 0) {
      let formData = new FormData();
      newImages?.forEach(img => {
        formData.append('images[]', img)
      });

      await dispatch(saveImagesById(recordInfo.id, formData)).then((res) => {
        dispatch(showAlertSnackbar({ message: "Images Saved", type: 'success' }));
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })
    }
    let params = recordInfo;
    if (updateStatus) {
      params = { ...recordInfo, update_values_only: false };
    }
    await dispatch(updateRecordInformation(recordInfo.id, params)).then((res) => {
      dispatch(showAlertSnackbar({ message: "Record Updated", type: 'success' }));
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    })
    refresh();
  }

  async function closeRecord() {
    await dispatch(updateRecordInformation(recordInfo.id, { update_values_only: false })).then((res) => {
      dispatch(showAlertSnackbar({ message: "Record Closed", type: 'success' }));
    }).catch((err) => {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    })
    refresh();
  }

  function resetDialog() {
    startEdit(false);
    startConfirm(false);
    setImages([]);
    setNewImages([]);
  }

  function getPackageInfo() {
    if (recordInfo.tracking_number) {
      dispatch(getInfoByTrackingNumber(recordInfo.tracking_number)).then((res) => {
        setRecordInfo({
          ...recordInfo,
          package_status: res.state,
          warehouse: res.warehouse,
        });
      }).catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        setRecordInfo({
          ...recordInfo,
          tracking_number: "",
        });
      })
    }
  }

  return (
    <Card variant='outlined'>
      <Box display='flex' alignItems='center' padding={2}>
        <Typography variant='subtitle2' flex={1}>
          Faulty Package List
        </Typography>
        <Typography marginRight={1}>
          {stats && stats.length > 0 && <>
            <span style={{ color: "red" }}>{stats.reduce((count, obj) => obj.record_status === CLIENT_REVIEWED ? obj.count : count, 0) + " Action Required"}</span>
            <span >{" / " + stats.reduce((count, obj) => obj.record_status === NEW_RECORD_STATUS ? obj.count : count, 0) + " Waiting for Client's Review"}</span>
            <span >{" / " + stats.reduce((count, obj) => obj.record_status === UNI_CONFIRMED ? obj.count : count, 0) + " UniUni Confirmed"}</span>
          </>}
        </Typography>
        <Button
          onClick={downloadAllRecords}
          variant='outlined'
          color='secondary'
          size='small'>
          DOWNLOAD ALL
        </Button>
      </Box>

      <DataGrid
        autoHeight
        rows={records}
        columns={faultyPackageColumns}
        onRowClick={handleOpenRecord}
        getCellClassName={(params) => {
          if (params.field === 'record_status' && params.value === "Action Required") {
            return 'ActionRequired';
          }
        }}
        sx={{
          border: 'none',
          borderRadius: 0,
          '& .ActionRequired': {
            color: '#D32F2FDE'
          }
        }}
      />

      <Dialog fullWidth maxWidth='sm' open={editUniRecordOpen} onClose={() => { setEditUniRecordOpen(false); resetDialog() }}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          {/* step 1 */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} disabled={dialogStateSettings.disabled1} expanded={dialogStateSettings.expanded1}>
            <AccordionSummary
              sx={{ alignItems: 'center' }}
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded1: !dialogStateSettings.expanded1 })}>
              <Typography>Basic Information</Typography>
              {dialogStateSettings.editable1 &&
                <IconButton size='small'>
                  <EditIcon
                    fontSize='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      startEdit(true);
                    }}
                  />
                </IconButton>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={6}>
                  <TextField
                    label="Record Date *"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="created_date"
                    value={recordInfo.created_at}
                    onChange={handleInputChange}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    select
                    name='warehouse'
                    label="Warehouse"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.warehouse}
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    onChange={handleInputChange}
                    required={(dialogStateSettings.editable1 && editing)}>
                    {Object.entries(warehouseDropDown).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Tracking No."
                    name="tracking_number"
                    variant="outlined"
                    fullWidth
                    value={recordInfo.tracking_number}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton onClick={getPackageInfo} edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Sender Ref"
                    name="sender_reference"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.sender_reference}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Item No."
                    name="item_number"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.item_number}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    required={(dialogStateSettings.editable1 && editing)}
                    select
                    label="Issue Type"
                    name="issue_type"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.issue_type}>
                    {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {(dialogStateSettings.editable1 && editing) &&
                  <Grid size={12}>
                    <Button
                      component='label'
                      for='uploadImages'
                      variant='outlined'
                      color='secondary'>
                      UPLOAD IMAGE
                    </Button>
                    <Typography
                      variant='caption'
                      color='secondary'
                      marginLeft={1}>
                      *Accept only .png .jpeg. Max 5 images
                    </Typography>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      hidden
                      onChange={handleUploadImages}
                      id="uploadImages"
                    />
                  </Grid>
                }
                <Grid size={12}>
                  {
                    newImages.length > 0 ?
                      newImages.map((img, index) => (
                        <Button
                          onClick={() => removeImage(index)}
                          startIcon={<ImageIcon />}
                          endIcon={<CancelIcon color='error' />}
                          sx={{ marginTop: 0.5 }}>
                          {img.name}
                        </Button>
                      ))
                      :
                      images?.map((image) => (
                        <img
                          src={`data:image/jpeg;base64,${image}`}
                          alt=''
                          style={{
                            width: 100,
                            height: 100,
                            zIndex: 1000,
                            borderRadius: 4,
                            cursor: 'pointer',
                            margin: '5px'
                          }}
                          onClick={() => setImageDetail(image)}
                        />
                      ))
                  }
                </Grid>
                <Grid size={12}>
                  <TextField
                    name="uni_notes"
                    disabled={!(dialogStateSettings.editable1 && editing)}
                    label="Notes"
                    multiline
                    minRows={4}
                    defaultValue="Add notes here"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.uni_notes}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography>{"Current Package Status: " + FaultyPackagePackageStatus[recordInfo.package_status]}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* step 2: always disabled */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} disabled={dialogStateSettings.disabled2} expanded={dialogStateSettings.expanded2}>
            <AccordionSummary
              sx={{ alignItems: 'center' }}
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded2: !dialogStateSettings.expanded2 })}>
              <Typography>Client Review</Typography>
              {
                dialogStateSettings.editable2 &&
                <IconButton size='small'>
                  <EditIcon
                    fontSize='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      startEdit(true);
                    }}
                  />
                </IconButton>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    label="Verify Tracking No."
                    name="verified_tracking_number"
                    variant="outlined"
                    fullWidth
                    value={recordInfo.verified_tracking_number}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    select
                    label="Decision"
                    name="client_decision"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.client_decision}
                  >
                    {Object.entries(FaultyPackageClientDecision).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!(dialogStateSettings.editable2 && editing)}
                    label="Notes"
                    name="client_notes"
                    multiline
                    minRows={4}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.client_notes}
                  />
                </Grid>
                <Grid size={12}>
                  {file && file.size > 0 && (
                    <Link
                      variant='caption'
                      color='secondary'
                      sx={{ cursor: 'pointer' }}
                      onClick={handleDownload}>
                      Download File
                    </Link>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* step 3 */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} disabled={dialogStateSettings.disabled3} expanded={dialogStateSettings.expanded3}>
            <AccordionSummary
              sx={{ alignItems: 'center' }}
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setdialogStateSettings({ ...dialogStateSettings, expanded3: !dialogStateSettings.expanded3 })}
            >
              <Typography>Confirmation</Typography>
              {
                dialogStateSettings.editable3 &&
                <IconButton size='small'>
                  <EditIcon
                    fontSize='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      startEdit(true);
                    }} />
                </IconButton>
              }
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    requried={((dialogStateSettings.editable3 && editing) || confirming)}
                    label="Close Date"
                    name="closed_at"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.closed_at}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    requried={((dialogStateSettings.editable3 && editing) || confirming)}
                    select
                    label="Action"
                    name="action_taken"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.action_taken}
                    onChange={handleInputChange}
                  >
                    {Object.entries(FaultyPackageActionTaken).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    disabled={!((dialogStateSettings.editable3 && editing) || confirming)}
                    label="Notes"
                    name="closing_notes"
                    multiline
                    minRows={4}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={recordInfo.closing_notes}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography>{"Current Package Status: " + FaultyPackagePackageStatus[recordInfo.package_status]}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          {recordInfo.record_status === NEW_RECORD_STATUS &&
            <Button variant='outlined' color='error' sx={{ marginRight: 'auto' }} onClick={() => openDeleteConf(true)}>
              DELETE RECORD
            </Button>
          }
          {(recordInfo.record_status === UNI_CONFIRMED && !editing) &&
            <Button variant='outlined' color='secondary' sx={{ marginRight: 'auto' }} onClick={() => { setEditUniRecordOpen(false); closeRecord() }}>
              CLOSE RECORD
            </Button>
          }
          {!editing &&
            <Button autoFocus onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>
              CLOSE
            </Button>
          }
          {(recordInfo.record_status === CLIENT_REVIEWED && !confirming) &&
            <Button variant='contained' onClick={() => { startConfirm(true); startEdit(true); setdialogStateSettings({ ...dialogStateSettings, expanded3: true }) }}>
              START TO CONFIRM
            </Button>
          }
          {confirming ?
            <>
              <Button autoFocus onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>
                CANCEL
              </Button>
              <Button variant='contained' onClick={() => { setEditUniRecordOpen(false); resetDialog(); updateRecord(true) }}>
                CONFIRM
              </Button>
            </>
            :
            editing &&
            <>
              <Button autoFocus onClick={() => { setEditUniRecordOpen(false); resetDialog() }}>
                CANCEL
              </Button>
              <Button variant='contained' onClick={() => { setEditUniRecordOpen(false); resetDialog(); updateRecord() }}>
                SAVE CHANGES
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='md' fullWidth={true} open={imageDetail !== null} onClose={() => setImageDetail(null)}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent dividers>
          <img
            src={`data:image/jpeg;base64,${imageDetail}`}
            alt=''
            style={{
              zIndex: 1000,
              borderRadius: 4,
              cursor: 'pointer',
              margin: '5px'
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setImageDetail(null)}>BACK</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth={true} open={deletConf} onClose={() => openDeleteConf(false)}>
        <DialogTitle>Delete Record</DialogTitle>
        <DialogContent dividers>
          <Typography>
            {"You are going to delete this record. Do you want to proceed?"}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => openDeleteConf(false)}>Cancel</Button>
          <Button variant='contained' color='error' autoFocus onClick={handleDeleteRecord}>DELETE</Button>
        </DialogActions>
      </Dialog>

    </Card>
  );
}
import { useDispatch } from 'react-redux';
import { TextField, Typography, Card, Stack, IconButton, Box } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { getPartnersRateCards } from "../../actions/rateCardsManagementActions";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import SearchIcon from '@mui/icons-material/Search';
import RateCardsManagementDetail from "./RateCardsManagementDetail";

function FilterForm({ onSubmit }) {
  const [partnerId, setPartnerId] = useState('');
  const [partnerName, setPartnerName] = useState('');

  return (
    <Card
      component='form'
      onSubmit={e => { e.preventDefault(); onSubmit(partnerId, partnerName) }}
      variant='outlined'
      sx={{ marginBottom: 2 }}>
      <Stack direction='row' alignItems='center' spacing={2} sx={{ padding: 3 }}>
        <TextField
          id='partner_id_text'
          label='Partner Id'
          variant='outlined'
          type='number'
          onChange={e => setPartnerId(e.target.value)}
          value={partnerId}
        />
        <TextField
          id='partner_name_text'
          label='Partner Name'
          variant='outlined'
          onChange={e => setPartnerName(e.target.value)}
          value={partnerName}
        />
        <IconButton
          type='submit'
          size='large'
          color='primary'>
          <SearchIcon />
        </IconButton>
      </Stack>
    </Card>
  )
}

const ALERT = {
  SEARCH_SUCCESS: "FETCH DATA SUCCESS",
  SEARCH_FAILED: "FETCH DATA FAILED",
};

const columns = [
  {
    field: 'partner_id',
    headerName: 'Partner ID',
    flex: 1
  },
  {
    field: 'partner_name',
    headerName: 'Partner Name',
    flex: 1
  },
  // {
  //   field: 'service_type',
  //   headerName: 'Service type',
  //   flex: 1
  // },
  {
    field: 'price_sheet',
    headerName: 'Price sheet',
    flex: 1
  },
  {
    field: 'zone_version',
    headerName: 'Zone version',
    flex: 1
  },
  {
    field: 'fsa_version',
    headerName: 'FSA version',
    flex: 1
  },
  {
    field: 'expiry_date',
    headerName: 'Expiry Date',
    flex: 1
  }
];

export default function RateCardsManagement() {
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [partners, setPartners] = useState([])
  const [selected, setSelected] = useState(null)  
  const [filters, setFilters] = useState({ partnerId: '', parterName: '' })
  const [paging, setPaging] = useState({ totalCount: 0 })
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)

  const handleSearch = useCallback(async (partnerId, partnerName, pageSize, page) => {
    setLoading(true);
    try {
      const [data, paging] = await dispatch(getPartnersRateCards(partnerId, partnerName, pageSize, page));

      const rows = data.map(item => {
        const rateCard = item.rate_cards[0]
        return {
          id: item.partner_id,
          partner_id: item.partner_id,
          partner_name: item.partner_name,
          price_sheet: rateCard?.price_sheets[0]?.name, // only one for now
          zone_version: rateCard?.zone_version,
          fsa_version: rateCard?.fsa_version,
          expiry_date: rateCard?.expiry_date
        }
      });

      setPartners(data);
      setRows(rows)

      setFilters({ partnerId, partnerName })
      setPaging(paging)

      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch])

  useEffect(() => {
    handleSearch('', '', 10, 1)
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Rate Cards Management
      </Typography>
      
      <FilterForm
        onSubmit={(partnerId, partnerName) => {
          setPage(1)
          handleSearch(partnerId, partnerName, pageSize, page)
        }}
      />

      <Card variant='outlined'>
        <Box padding={2}>
          <Typography variant='subtitle2'>
            Partner List
          </Typography>
        </Box>

        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          page={page}
          pageSize={pageSize}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          disableRowSelectionOnClick
          pageSizeOptions={[10, 20, 30]}
          rowCount={paging.totalCount}
          pagination
          paginationMode="server"
          paginationModel={{
            page: page - 1,
            pageSize: pageSize
          }}
          onPaginationModelChange={(model) => {
            setPage(model.page + 1)
            setPageSize(model.pageSize)
            handleSearch(filters.partnerId, filters.parterName, model.pageSize, model.page + 1)
          }}
          onRowClick={({ row }) => {
            setSelected(partners.find(partner => partner.partner_id === row.partner_id))
          }}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>

      <RateCardsManagementDetail
        partner={selected}
        onClose={() => setSelected(null)}
        onUpdate={() => handleSearch(filters.partnerId, filters.parterName, pageSize, page)}
      />
    </div>
  )
}
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, Button, Dialog, DialogContent, DialogTitle, Typography, Divider } from '@mui/material';
import { getPartnerCapacities, getWarehouseCapacities } from "../../actions/deliveryCapacityActions";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import { useAlert } from "../../utilities/hooks";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

const ALERT = {
  SEARCH_SUCCESS: "FETCH DATA SUCCESS",
  SEARCH_FAILED: "FETCH DATA FAILED"
};

export const top4PartnerIds = [57, 83, 85, 54] // Shein, Temu, TikTok, Cainiao

export default function DeliveryCapacityDisplay() {
  const dispatch = useDispatch();
  const alertDialog = useAlert();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [partnerRows, setPartnerRows] = useState([]);
  const [sparedCapacity, setSparedCapacity] = useState(null);
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(0);
  const [warehouse, setWarehouse] = useState('');
  const [openView, setOpenView] = useState(false);

  const localizedNumberFormatter = new Intl.NumberFormat('en-US');

  const columns = [
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      valueFormatter: (value) => localizedNumberFormatter.format(value),
    },
    {
      field: 'counts',
      headerName: 'Received Volume',
      flex: 1,
      valueFormatter: (value) => localizedNumberFormatter.format(value),
    },
    {
      field: 'rate',
      headerName: 'Inbound Rate',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
      valueFormatter: (value) => (value + '%'),
    },
    {
      field: 'status',
      headerName: 'Inbound Status',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
    }
  ];

  const partnerCol = [
    {
      field: 'name',
      headerName: 'Partner Name',
      width: 200
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      valueFormatter: (value) => localizedNumberFormatter.format(value),
    },
    {
      field: 'counts',
      headerName: 'Received Volume',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
    },
    {
      field: 'orders',
      headerName: 'Planned Rate',
      flex: 1,
      valueFormatter: (value) => (value + '%'),
    },
    {
      field: 'rate',
      headerName: 'Actual Rate',
      flex: 1,
      cellClassName: (params) => getRateCellClassName(params, 'rate'),
      valueFormatter: (value) => (value + '%'),
    }
  ];

  const getRateCellClassName = (params, field) => {
    const rate = parseFloat(params.row[field]);
    if (rate >= 100) {
      return 'red-text';
    } else if (rate >= 80) {
      return 'yellow-text';
    }
    return 'green-text';
  };

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getWarehouseCapacities());
      const updatedRows = res.map((row) => ({
        id: row.warehouse_id,
        warehouse: row.name,
        capacity: row.capacity,
        counts: row.current_total,
        rate: row.rate,
        status: row.status ? 'Open' : 'Close',
      }));
      setRows(updatedRows);
      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch])

  const handleRowClick = async (selectedRow) => {
    setLoading(true);
    try {
      setWarehouse(selectedRow.row['warehouse']);
      const res = await dispatch(getPartnerCapacities(selectedRow.id));

      const rows = res.map((row) => ({
        id: row.id,
        name: row.name,
        capacity: row.capacity,
        counts: row.count,
        orders: row.threshold_percentage,
        rate: parseFloat(row.percentage),
      }));

      // Put top 4 partners in the beginning
      for (let partnerId of top4PartnerIds.toReversed()) {
        const index = rows.findIndex(row => row.id === partnerId)
        if (index !== -1) {
          const row = rows.splice(index, 1)[0]
          rows.unshift(row)
        }
      }

      // Extract spared capacity out
      const rowIndex = rows.findIndex(row => row.name === 'Spared Capacity')
      if (rowIndex !== -1) {
        const sparedCapacity = rows[rowIndex].capacity
        rows.splice(rowIndex, 1)
        setSparedCapacity(sparedCapacity)
      }
      else {
        setSparedCapacity(null)
      }

      setPartnerRows(rows);
      setOpenView(true);
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message);
      alertDialog.showAlert();
    } finally {
      setLoading(false);
    }
  }

  const SparedCapacityFooter = () => (<>
    <Divider />
    <Box height='52px' display='flex' alignItems='center'>
      <Typography variant='body2' color='textSecondary' sx={{ width: '200px', padding: '0 10px' }}>Spared</Typography>
      <Typography variant='body2' color='textSecondary' sx={{ paddingLeft: '10px' }}>{sparedCapacity.toLocaleString()}</Typography>
    </Box>
  </>)

  useEffect(() => {
    handleSearch();

    dayjs.extend(isoWeek);
    const date = dayjs();
    setYear(date.format('YYYY'));
    setWeek(date.isoWeek());
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Delivery Capacity
      </Typography>

      <Card variant='outlined'>
        <Box display='flex' alignItems='center' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Week: {week}, {year}
          </Typography>
          <Button
            onClick={handleSearch}
            variant='outlined'
            color='secondary'>
            Refresh
          </Button>
        </Box>

        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={handleRowClick}
          sx={{
            border: 'none',
            borderRadius: 0,
            '& .green-text': {
              color: 'green'
            },
            '& .yellow-text': {
              color: 'orange'
            },
            '& .red-text': {
              color: 'red'
            }
          }}
        />
      </Card>

      <Dialog maxWidth='md' fullWidth open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>{warehouse}</DialogTitle>
        <DialogContent sx={{ paddingBottom: 3 }}>
          <DataGrid
            autoHeight
            rows={partnerRows}
            columns={partnerCol}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            slots={{
              footer: sparedCapacity !== null ? SparedCapacityFooter : null,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
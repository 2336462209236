import { useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from "react-redux";
import {
  cancelOrder, checkServiceAvailability, createBatch,
  createBusinessOrder, estimateShipping, getCustomerToken,
  getOrders, printLabel, pushTracking, trackingUniuni, updateOrderBagNo
} from "../../actions/sandboxActions";
import { createOrderServerInstance, createOrderServerInstanceDev } from "../../utilities/instances";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import FullscreenLoading from "../../components/FullscreenLoading";
import axios from "axios";
import { useCustomer } from "../../utilities/hooks";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&:before': {
    display: 'none',
  }
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  border: 'none',
  alignItems: 'baseline !important'
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0
}));
const TextFieldStyled = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '10px !important',
    }
  },
}))(TextField);
const useStyles = makeStyles((theme) => ({
  borderBackground: {
    width: 940,
    backgroundColor: '#fff',
    padding: '14px 20px 19px',
    border: 'solid 1px #a3a4a0',
    borderRadius: 4,
    marginTop: 16,
    marginBottom: 16
  },
  summaryTitle: {
    display: 'flex',
    alignItems: 'center !important'
  },
  summaryApiName: {
    fontSize: 20,
    fontWeight: 600,
    color: '#262626',
    lineHeight: '31px'
  },
  summaryApi: {
    display: 'flex',
    marginTop: 16,
    alignItems: 'center'
  },
  apiMethod: {
    width: 77,
    height: 41,
    padding: '10px 18px 8px',
    objectFit: 'contain',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: '#fff',
  },
  api: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    width: 716,
    height: 41,
    fontSize: 16,
    fontWeight: 500,
    padding: '10px 18px 8px',
    textAlign: 'left',
  },
  detailGroup: {
    marginTop: 10,
    width: 900,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  parameters: {
    fontSize: 16,
    fontWeight: 600,
    color: '#262626'
  },
  parameterContent: {
    marginLeft: 12,
    marginTop: 16
  },
  key: {
    display: 'flex',
    fontSize: 16,
    fontWeight: 500,
    color: '#8a8b8a',
    width: 900
  },
  contentRequest: {
    width: '100%',
    marginTop: 21
  },
  keyTitle: {
    fontSize: 16,
    color: '#262626'
  },
  separateLine: {
    height: 1,
    width: 940,
    borderBottom: 'solid 1px #a3a4a0',
    marginLeft: -21,
    marginTop: 42
  },
  contentReponse: {
    width: 900,
    marginTop: 21
  },
  titleResponse: {
    fontSize: 16,
    fontWeight: 600,
    color: '#262626'
  },
  apiDescription: {
    width: '100%',
    padding: '13px 27px 15px 16px',
    backgroundColor: '#f1f1f1',
    borderBottomLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  },
  paramDescription: {
    width: 900
  },
  token: {
    width: 899,
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflow: 'hidden',
    marginTop: 8,
    padding: '15px 11px 14px 12px',
    borderradius: 4,
    border: 'solid 1px #d4d4d9',
    backgroundColor: 'rgba(228, 228, 227, 0.2)'
  },
  string: {
    length: 10
  }
}))

export default function SandboxInfo(props) {
  const classes = useStyles();
  const { t } = useTranslation('sandbox');
  const dispatch = useDispatch();

  const apiDescription = useSelector(state => state.sandbox.getApiDescription);

  const key = 'SMq45nJhQuNR3WHsJA6N';

  const { customerData } = useCustomer();
  const [type, setType] = useState(0);
  const [orderId, setOrderId] = useState('');
  const [openApiDescription, serOpenApiDescription] = useState(false);
  const [retrieveTokenInfo, setRetrieveTokenInfo] = useState([]);
  const [createOrderInfo, setCreateOrderInfo] = useState([]);
  const [trackingUniuniInfo, setTrackingUniuniInfo] = useState([]);
  const [printLabelInfo, setPrintLabelInfo] = useState([]);
  const [pushTrackingInfo, setPushTrackingInfo] = useState([]);
  const [estimateShippingInfo, setEstimateShippingInfo] = useState([]);
  const [checkServiceAvailabilityInfo, setCheckServiceAvailabilityInfo] = useState([]);
  const [createBatchInfo, setCreateBatchInfo] = useState([]);
  const [cancelOrderInfo, setCancelOrderInfo] = useState([]);
  const [getOrdersInfo, setGetOrdersInfo] = useState([]);
  const [updateOrderBagNoInfo, setUpdateOrderBagNoInfo] = useState([]);
  const [retrieveTokenMsg, setRetrieveTokenMsg] = useState([]);
  const [createOrderMsg, setCreateOrderMsg] = useState([]);
  const [printLabelMsg, setPrintLabelMsg] = useState([]);
  // const [trackingUniuniMsg, setTrackingUniuniMsg] = useState([]);
  const [pushTrackingMsg, setPushTrackingMsg] = useState([]);
  const [estimateShippingMsg, setEstimateShippingMsg] = useState([]);
  const [checkServiceAvailabilityMsg, setCheckServiceAvailabilityMsg] = useState([]);
  const [createBatchMsg, setCreateBatchMsg] = useState([]);
  const [cancelOrderMsg, setCancelOrderMsg] = useState([]);
  const [getOrdersMsg, setGetOrdersMsg] = useState([]);
  const [updateOrderBagNoMsg, setUpdateOrderBagNoMsg] = useState([]);
  const [token, setToken] = useState('');
  const [retrieveTokenRes, setRetrieveTokenRes] = useState('');
  const [createOrderRes, setCreateOrderRes] = useState('');
  const [printLabelRes, setPrintLabelRes] = useState('');
  const [trackingUniuniRes, setTrackingUniuniRes] = useState('');
  const [pushTrackingRes, setPushTrackingRes] = useState('');
  const [estimateShippingRes, setEstimateShippingRes] = useState('');
  const [checkServiceAvailabilityRes, setCheckServiceAvailabilityRes] = useState('');
  const [createBatchRes, setCreateBatchRes] = useState('');
  const [cancelOrderRes, setCancelOrderRes] = useState('');
  const [getOrdersRes, setGetOrdersRes] = useState('');
  const [updateOrderBagNoRes, setUpdateOrderBagNoRes] = useState('');
  const [loading, setLoading] = useState(false);

  const [apiTitle, setApiTitle] = useState('');
  const [apiDes, setApiDes] = useState('');
  const [url, setUrl] = useState('');
  const [method, setMethod] = useState('');
  const [res, setRes] = useState('');

  const [expanded, setExpanded] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const initArr = useCallback((arr, isCreate) => {
    let lengthArr = [];
    if (arr) {
      let length = arr.length;
      for (let i = 0; i < length; i++) {
        if (i === 0 && isCreate) {
          lengthArr.push(customerData.id);
        } else {
          lengthArr.push('');
        }
      }
    }
    return lengthArr;
  }, [customerData.id])

  useEffect(() => {
    if (props.type === 'retrieve-token') {
      setType(1);
    } else if (props.type === 'create-order') {
      setType(2);
    } else if (props.type === 'order-verification') {
      setType(3);
    } else if (props.type === 'print-label') {
      setType(4);
    } else if (props.type === 'push-tracking') {
      setType(5);
    } else if (props.type === 'estimate-shipping') {
      setType(6);
    } else if (props.type === 'check-service-availability') {
      setType(7);
    } else if (props.type === 'create-batch') {
      setType(8);
    } else if (props.type === 'cancel-order') {
      setType(9);
    } else if (props.type === 'get-orders') {
      setType(10);
    } else if (props.type === 'update-order-bag-no') {
      setType(11);
    }
  }, [props.type])
  useEffect(() => {
    if (apiDescription) {
      if (apiDescription.customer_token) {
        setRetrieveTokenInfo(apiDescription.customer_token[0]);
        let arr = ['client_credentials']
        if (window.localStorage.getItem('client_id')) {
          arr.push(window.localStorage.getItem('client_id'));
        }
        if (window.localStorage.getItem('client_secret')) {
          arr.push(window.localStorage.getItem('client_secret'));
        }
        setRetrieveTokenMsg(arr);
      }
      if (apiDescription.create_business_order) {
        setCreateOrderInfo(apiDescription.create_business_order[0]);
        setCreateOrderMsg(initArr(apiDescription.create_business_order[0].input_fields, true));
      }
      if (apiDescription.tracking_uniuni) {
        setTrackingUniuniInfo(apiDescription.tracking_uniuni[0]);
        // setTrackingUniuniMsg(apiDescription.tracking_uniuni[0].input_fields);
      }
      if (apiDescription.print_label) {
        setPrintLabelInfo(apiDescription.print_label[0]);
        setPrintLabelMsg(initArr(apiDescription.print_label[0].input_fields, false));
      }
      if (apiDescription.push_tracking) {
        setPushTrackingInfo(apiDescription.push_tracking[0]);
        setPushTrackingMsg(initArr(apiDescription.push_tracking[0].input_fields, false))
      }
      if (apiDescription.estimateshipping) {
        setEstimateShippingInfo(apiDescription.estimateshipping[0]);
        setEstimateShippingMsg(initArr(apiDescription.estimateshipping[0].input_fields, false))
      }
      if (apiDescription.checkServiceAvailability) {
        setCheckServiceAvailabilityInfo(apiDescription.checkServiceAvailability[0]);
        setCheckServiceAvailabilityMsg(initArr(apiDescription.checkServiceAvailability[0].input_fields, false))
      }
      if (apiDescription.createBatch) {
        setCreateBatchInfo(apiDescription.createBatch[0]);
        setCreateBatchMsg(initArr(apiDescription.createBatch[0].input_fields, false))
      }
      if (apiDescription.cancelOrder) {
        setCancelOrderInfo(apiDescription.cancelOrder[0]);
        setCancelOrderMsg(initArr(apiDescription.cancelOrder[0].input_fields, false))
      }
      if (apiDescription.getOrders) {
        setGetOrdersInfo(apiDescription.getOrders[0]);
        setGetOrdersMsg(initArr(apiDescription.getOrders[0].input_fields, false))
      }
      if (apiDescription.updateOrderBagno) {
        setUpdateOrderBagNoInfo(apiDescription.updateOrderBagno[0]);
        setUpdateOrderBagNoMsg(initArr(apiDescription.updateOrderBagno[0].input_fields, false))
      }
    }
  }, [apiDescription, customerData, initArr])
  useEffect(() => {
    if (props.token) {
      setToken(props.token);
    }
  }, [props.token])

  function modifyResStr(res) {
    let json = JSON.stringify(res, undefined, 2);
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
      // let cls = 'number';
      // if (/^"/.test(match)) {
      //   if (/:$/.test(match)) {
      //     cls = 'key';
      //   } else {
      //     cls = 'string';
      //   }
      // } else if (/true|false/.test(match)) {
      //   cls = 'boolean';
      // } else if (/null/.test(match)) {
      //   cls = 'null';
      // }
      return '<span style=white-space:pre-wrap;display:inline;width:fit-content;>' + match + '</span>';
    });
    var parser = new DOMParser();
    var doc = parser.parseFromString('<pre>' + json + '</pre>', 'text/html');
    return doc.body.innerHTML
  }
  const handleParams = useCallback((value, index, msg) => {
    let params = JSON.parse(JSON.stringify(msg));
    params[index] = value;
    if (type === 2) {
      setCreateOrderMsg(params);
    } else if (type === 4) {
      setPrintLabelMsg(params);
    } else if (type === 5) {
      setPushTrackingMsg(params);
    } else if (type === 6) {
      setEstimateShippingMsg(params);
    } else if (type === 7) {
      setCheckServiceAvailabilityMsg(params);
    } else if (type === 8) {
      setCreateBatchMsg(params);
    } else if (type === 9) {
      setCancelOrderMsg(params);
    } else if (type === 10) {
      setGetOrdersMsg(params);
    } else if (type === 11) {
      setUpdateOrderBagNoMsg(params);
    }
  }, [type])
  // function printLabelPdf(params) {
  //   try {
  //     dispatch(printLabel(params))
  //   } catch(err) {
  //     if(err.status === 'FAIL') {
  //       setPrintLabelRes(modifyResStr(err));
  //     } else {

  //     }
  //   }
  // }

  useEffect(() => {
    let info = {};
    if (type === 1) {
      info = retrieveTokenInfo;
      setApiTitle(t('retrieve_token'));
      setRes(retrieveTokenRes);
    }
    if (type === 2) {
      info = createOrderInfo;
      setApiTitle(t('create_order'));
      setRes(createOrderRes);
    }
    if (type === 3) {
      info = trackingUniuniInfo;
      setApiTitle(t('order_verification'));
      let url = `${process.env.REACT_APP_SJ_SERVER_URL}`;
      let relativeUrl = trackingUniuniInfo.relative_url || '';
      let paramIndex = 0;
      let params = [key, orderId];

      for (var i = 0; i < relativeUrl.length; i++) {
        if (relativeUrl[i] === '#') {
          url += params[paramIndex];
          paramIndex++;
        } else {
          url += relativeUrl[i];
        }

      }
      setUrl(`${url}`);
      setRes(trackingUniuniRes);
    }
    if (type === 4) {
      info = printLabelInfo;
      setApiTitle(t('print_label'));
      setRes(printLabelRes);
    }
    if (type === 5) {
      info = pushTrackingInfo;
      setApiTitle(t('push_tracking'));
      setRes(pushTrackingRes);
    }
    if (type === 6) {
      info = estimateShippingInfo;
      setApiTitle(t('estimate_shipping'));
      setRes(estimateShippingRes);
    }
    if (type === 7) {
      info = checkServiceAvailabilityInfo;
      setApiTitle(t('check_service_availability'));
      setRes(checkServiceAvailabilityRes);
    }
    if (type === 8) {
      info = createBatchInfo;
      setApiTitle(t('create_batch'));
      setRes(createBatchRes);
    }
    if (type === 9) {
      info = cancelOrderInfo;
      setApiTitle(t('cancel_order'));
      setRes(cancelOrderRes);
    }
    if (type === 10) {
      info = getOrdersInfo;
      setApiTitle(t('get_orders'));
      setRes(getOrdersRes);
    }
    if (type === 11) {
      info = updateOrderBagNoInfo;
      setApiTitle(t('update_order_bag_no'));
      setRes(updateOrderBagNoRes);
    }
    setApiDes(info.description);
    setMethod(info.method);
    if (type !== 3) {
      let url = process.env.REACT_APP_SJ_SERVER_URL;
      if (info.relative_url) {
        url += info.relative_url;
      }
      setUrl(url);
    }
  }, [type, retrieveTokenInfo, createOrderInfo, createBatchInfo, cancelOrderInfo, updateOrderBagNoInfo, trackingUniuniInfo, estimateShippingInfo, checkServiceAvailabilityInfo, getOrdersInfo, pushTrackingInfo, printLabelInfo, retrieveTokenRes, createOrderRes, trackingUniuniRes, printLabelRes, key, orderId, estimateShippingRes, pushTrackingRes, checkServiceAvailabilityRes, createBatchRes, cancelOrderRes, getOrdersRes, updateOrderBagNoRes, t])
  useEffect(() => {
    if (selectedFile && type === 11) {
      handleParams(selectedFile, 1, updateOrderBagNoMsg);
    }
  }, [handleParams, selectedFile, type, updateOrderBagNoMsg])

  return (
    <div className={classes.borderBackground}>
      {
        type === 11 &&
        <input
          color="primary"
          type="file"
          id="button-file"
          onChange={(e) => setSelectedFile(e.target.files[0])}
          style={{ display: 'none' }}
        />
      }
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.summaryTitle}>
              <InfoIcon
                style={
                  openApiDescription ?
                    {
                      height: 36,
                      width: 34,
                      marginRight: 7,
                      color: '#262626',
                      backgroundColor: '#f1f1f1',
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4
                    } : {
                      height: 36,
                      width: 34,
                      marginRight: 7,
                      color: '#262626',
                      backgroundColor: 'white',
                      paddingLeft: 5,
                      paddingRight: 5
                    }
                }
                onClick={(e) => {
                  e.stopPropagation();
                  serOpenApiDescription(!openApiDescription);
                }}
              />
              <div
                className={classes.summaryApiName}
              >
                {
                  apiTitle
                }
              </div>
              <div style={{ marginLeft: 8, marginTop: 6 }}>
                {
                  expanded ?
                    <img
                      src='/images/icons/arrow-up.svg'
                      alt=''
                      style={{
                        height: 22,
                        width: 22
                      }}
                    />
                    :
                    <img
                      src='/images/icons/arrow-down.svg'
                      alt=''
                      style={{
                        height: 22,
                        width: 22
                      }}
                    />
                }
              </div>
            </div>
            {
              openApiDescription &&
              <div className={classes.apiDescription}>
                {
                  apiDes
                }
              </div>
            }
            <div className={classes.summaryApi}>
              <div
                style={{
                  border: 'solid 1px #e4e4e3',
                  borderRadius: 4,
                  display: 'flex'
                }}
              >
                <div
                  className={classes.apiMethod}
                  style={
                    (type === 1 || type === 2 || type === 4 || type === 5 || type === 6 || type === 7 || type === 8 || type === 9) ? {
                      backgroundColor: '#74b6ff'
                    } : {
                      backgroundColor: '#5accad'
                    }
                  }
                >
                  {
                    method?.toUpperCase()
                  }
                </div>
                <div
                  className={classes.api}
                  style={
                    (type === 1 || type === 2 || type === 4 || type === 5 || type === 6 || type === 7 || type === 8 || type === 9) ? {
                      backgroundColor: 'rgba(116, 182, 255, 0.16)'
                    } : {
                      backgroundColor: 'rgba(90, 204, 173, 0.16)',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  }
                >
                  {
                    url
                  }
                </div>
              </div>
              <Button
                variant='contained'
                size='large'
                sx={{ marginLeft: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setLoading(true);
                  if (type === 1) {
                    dispatch(getCustomerToken(retrieveTokenMsg))
                      .then((res) => {
                        props.handleToken(res.data.access_token)
                        setRetrieveTokenRes(modifyResStr(res));
                        createOrderServerInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
                        createOrderServerInstanceDev.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
                        setLoading(false);
                      })
                      .catch((err) => {
                        setRetrieveTokenRes(modifyResStr(err));
                        setLoading(false);
                      })
                  } else if (type === 2) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};

                    createOrderMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = createOrderInfo.input_fields[index].name;

                        if (name === 'custom_field' && typeof msg === 'string') {
                          try {
                            params[name] = JSON.parse(msg);
                          } catch (error) {
                            console.error('Error parsing custom_field JSON:', error);
                            params[name] = {};
                          }
                        } else {
                          params[name] = msg;
                        }
                      }
                    });

                    dispatch(createBusinessOrder(params))
                      .then((res) => {
                        setCreateOrderRes(modifyResStr(res));
                        setLoading(false);
                      })
                      .catch((err) => {
                        setCreateOrderRes(modifyResStr(err));
                        setLoading(false);
                      })
                  } else if (type === 3) {
                    if (!orderId) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('order_id') }));
                      setLoading(false);
                      return;
                    }
                    dispatch(trackingUniuni(key, orderId))
                      .then((res) => {
                        setTrackingUniuniRes(modifyResStr(res));
                        setLoading(false);
                      })
                      .catch((err) => {
                        setTrackingUniuniRes(modifyResStr(err));
                        setLoading(false);
                      })
                  } else if (type === 4) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    printLabelMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = printLabelInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    if (printLabelMsg[4] === 'pdf') {
                      dispatch(printLabel(params))
                        .catch((err) => {
                          if (err.status === 'FAIL') {
                            setPrintLabelRes(modifyResStr(err));
                            setLoading(false);
                          } else {
                            setPrintLabelRes('');
                            let serverUrl = process.env.REACT_APP_SJ_SERVER_URL;
                            axios.post(`${serverUrl}/orders/printlabel`, {
                              ...params
                            }, {
                              headers: {
                                common: {
                                  'Authorization': `Bearer ${token}`,
                                }
                              },
                              responseType: 'blob'
                            }
                            )
                              .then((response) => {
                                setLoading(false);
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Label.pdf');
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                              })
                          }
                        })
                    } else {
                      dispatch(printLabel(params))
                        .then((res) => {
                          setPrintLabelRes(modifyResStr(res));
                          setLoading(false);
                        })
                        .catch((err) => {
                          setPrintLabelRes(modifyResStr(err));
                          setLoading(false);
                        })
                    }
                  } else if (type === 5) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    pushTrackingMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = pushTrackingInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(pushTracking(params))
                      .then((res) => {
                        setPushTrackingRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setPushTrackingRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  } else if (type === 6) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    estimateShippingMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = estimateShippingInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(estimateShipping(params))
                      .then((res) => {
                        setEstimateShippingRes(modifyResStr(res));
                        setLoading(false);
                      })
                      .catch((err) => {
                        setEstimateShippingRes(modifyResStr(err));
                        setLoading(false);
                      })
                  } else if (type === 7) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    checkServiceAvailabilityMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = checkServiceAvailabilityInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(checkServiceAvailability(params))
                      .then((res) => {
                        setCheckServiceAvailabilityRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setCheckServiceAvailabilityRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  } else if (type === 8) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    createBatchMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = createBatchInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(createBatch(params))
                      .then((res) => {
                        setCreateBatchRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setCreateBatchRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  } else if (type === 9) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    cancelOrderMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = cancelOrderInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(cancelOrder(params))
                      .then((res) => {
                        setCancelOrderRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setCancelOrderRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  } else if (type === 10) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    let params = {};
                    getOrdersMsg.forEach((msg, index) => {
                      if (msg) {
                        let name = getOrdersInfo.input_fields[index].name;
                        params[name] = msg;
                      }
                    })
                    dispatch(getOrders(params))
                      .then((res) => {
                        setGetOrdersRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setGetOrdersRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                      })
                  } else if (type === 11) {
                    if (!token) {
                      dispatch(showAlertSnackbar({ type: "error", message: t('fetch_token_first') }));
                      setLoading(false);
                      return;
                    }
                    // let formData = new FormData();
                    let jsonRaw = '';
                    updateOrderBagNoMsg.forEach((msg, index) => {
                      if (msg) {
                        // let name = updateOrderBagNoInfo.input_fields[index].name;
                        // formData.append(name, msg);
                        jsonRaw = msg;
                      }
                    })
                    // dispatch(updateOrderBagNo(formData))
                    dispatch(updateOrderBagNo(jsonRaw))
                      .then((res) => {
                        setUpdateOrderBagNoRes(modifyResStr(res));
                      })
                      .catch((err) => {
                        setUpdateOrderBagNoRes(modifyResStr(err));
                      })
                      .finally(() => {
                        setLoading(false);
                        setSelectedFile(null);
                      })
                  }
                }}
              >{t('send')}</Button>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.detailGroup}>
            {
              (type === 2 || type === 4 || type === 5 || type === 6 || type === 7 || type === 8 || type === 9 || type === 10 || type === 11) &&
              <div style={{ marginBottom: 21 }}>
                <div className={classes.parameters}>
                  {t('authorization')}
                </div>
                <div className={classes.parameterContent}>
                  <div className={classes.key}>
                    Token Type: bearer
                    <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }}>*</span>
                  </div>
                  <div className={classes.token}>
                    {token}
                  </div>
                </div>
                <div className={classes.separateLine}></div>
              </div>
            }
            <div>
              <div className={classes.parameters}>
                {t('parameters')}
              </div>
              <div className={classes.parameterContent}>
                <div className={classes.key}>
                  <div style={{ width: '24%' }}>
                    Key
                  </div>
                  <div style={{ width: '74%' }}>
                    Value
                  </div>
                </div>
                {
                  type === 1 &&
                  retrieveTokenInfo.input_fields &&
                  retrieveTokenInfo.input_fields.map((field, index) => {
                    return (
                      <div className={classes.contentRequest}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                          <div style={{ width: '24%' }} className={classes.keyTitle}>
                            <span>
                              {field.name}
                            </span>
                            <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                          </div>
                          <div style={{ width: '82%' }}>
                            <TextFieldStyled
                              fullWidth
                              value={retrieveTokenMsg[index]}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                          <div style={{ width: '24%' }}></div>
                          <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                            <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                            <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  type === 2 &&
                  createOrderInfo.input_fields &&
                  createOrderInfo.input_fields.map((field, index) => {
                    if (index === 0) {
                      return (
                        <div className={classes.contentRequest}>
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                            <div style={{ width: '24%' }} className={classes.keyTitle}>
                              <span>
                                {field.name}
                              </span>
                              <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                            </div>
                            <div style={{ width: '82%' }}>
                              <TextFieldStyled
                                fullWidth
                                value={
                                  createOrderMsg[index]
                                }
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                            <div style={{ width: '24%' }}></div>
                            <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                              <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                              <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className={classes.contentRequest}>
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                            <div style={{ width: '24%' }} className={classes.keyTitle}>
                              <span>
                                {field.name}
                              </span>
                              <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                            </div>
                            <div style={{ width: '82%' }}>
                              <TextFieldStyled
                                fullWidth
                                value={
                                  createOrderMsg[index]
                                }
                                onChange={(e) => handleParams(e.target.value, index, createOrderMsg)}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                            <div style={{ width: '24%' }}></div>
                            <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                              <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                              <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                }
                {
                  type === 3 &&
                  trackingUniuniInfo.input_fields &&
                  trackingUniuniInfo.input_fields.length > 0 &&
                  <div>
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>key</span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={key}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{trackingUniuniInfo.input_fields[0].type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{trackingUniuniInfo.input_fields[0].description}</span></div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            id
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={orderId}
                            onChange={(e) => {
                              setOrderId(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{trackingUniuniInfo.input_fields[1].type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{trackingUniuniInfo.input_fields[1].description}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  type === 4 &&
                  printLabelInfo.input_fields &&
                  printLabelInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              printLabelMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, printLabelMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 5 &&
                  pushTrackingInfo.input_fields &&
                  pushTrackingInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              pushTrackingMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, pushTrackingMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 6 &&
                  estimateShippingInfo.input_fields &&
                  estimateShippingInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              estimateShippingMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, estimateShippingMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 7 &&
                  checkServiceAvailabilityInfo.input_fields &&
                  checkServiceAvailabilityInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              checkServiceAvailabilityMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, checkServiceAvailabilityMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 8 &&
                  createBatchInfo.input_fields &&
                  createBatchInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              createBatchMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, createBatchMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 9 &&
                  cancelOrderInfo.input_fields &&
                  cancelOrderInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              cancelOrderMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, cancelOrderMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 10 &&
                  getOrdersInfo.input_fields &&
                  getOrdersInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          <TextFieldStyled
                            fullWidth
                            value={
                              getOrdersMsg[index]
                            }
                            onChange={(e) => handleParams(e.target.value, index, getOrdersMsg)}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  type === 11 &&
                  updateOrderBagNoInfo.input_fields &&
                  updateOrderBagNoInfo.input_fields.map((field, index) => (
                    <div className={classes.contentRequest}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                        <div style={{ width: '24%' }} className={classes.keyTitle}>
                          <span>
                            {field.name}
                          </span>
                          <span style={{ verticalAlign: 'top', color: 'red', marginLeft: 4 }} hidden={!field.required}>*</span>
                        </div>
                        <div style={{ width: '82%' }}>
                          {
                            field.type === 'file' ?
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="button-file">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    style={{ width: 200 }}
                                  >
                                    {t('select_file')}
                                  </Button>
                                </label>
                                <div className="file-name" style={{ marginLeft: 10 }}>
                                  {selectedFile ? selectedFile.name : null}
                                </div>
                              </div> :
                              <TextFieldStyled
                                fullWidth
                                value={
                                  updateOrderBagNoMsg[index]
                                }
                                multiline
                                rows={3}
                                onChange={(e) => handleParams(e.target.value, index, updateOrderBagNoMsg)}
                              />
                          }
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginTop: 5, width: '100%' }}>
                        <div style={{ width: '24%' }}></div>
                        <div style={{ backgroundColor: '#fbe9e7', display: 'flex', width: '82%', paddingTop: 5, paddingBottom: 5, borderRadius: 4, paddingLeft: 2 }}>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Type:</span><span>{field.type}</span></div>
                          <div style={{ marginRight: 10 }}><span style={{ marginRight: 5, fontWeight: 600 }}>Description:</span><span>{field.description}</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={classes.separateLine}></div>
            <div className={classes.contentReponse}>
              <div className={classes.titleResponse}>Response</div>
              <div style={{ maxWidth: 800, whiteSpace: 'pre-line', wordWrap: 'break-word', wordBreak: 'break-all', overflow: 'hidden', marginTop: 12 }}>
                <td dangerouslySetInnerHTML={{ __html: res }} />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <FullscreenLoading open={loading} />
    </div>
  );
}
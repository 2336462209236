

export function changeLanguagePref(i18nInstance, language){
    i18nInstance.changeLanguage(language)
    window.localStorage.setItem("language", language)
}

export function getLanguagePref(){
    return window.localStorage.getItem("language")
}

export function getLanguageString(language){
    switch(language){
        case "en":
            return "简体中文"
        case "cn":
            return "English"
        default: 
            return language
    }
}
import { useDispatch } from 'react-redux';
import { Card, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import { DataGrid } from '@mui/x-data-grid';
import { getWarehouseCapacities } from "../../actions/deliveryCapacityActions";
import { getPartnerThresholdByWarehouse, updatePartnerThresholdByWarehouse } from "../../actions/deliveryCapacityActions";
import { top4PartnerIds } from './DeliveryCapacityDisplay';
import FullscreenLoading from "../../components/FullscreenLoading";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const ALERT = {
  SUCCESS: "FETCH THRESHOLD DATA SUCCESS",
  FAILED: "FETCH THRESHOLD DATA FAILED",
  UPDATE_SUCCESS: "UPDATE DATA SUCCESS",
  UPDATE_FAILED: "UPDATE DATA FAILED",
};

function findChangedField(newRow, oldRow) {
  return Object.keys(newRow).find(field => newRow[field] !== oldRow[field])
}

function formatFieldValue(value, capacity) {
  return isNaN(value) ? 'N/A' : `${value}% (${(Number(value) / 100 * capacity).toLocaleString()})`
}

export default function DeliveryThresholdUpdate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(0);
  const [capacities, setCapacities] = useState({});
  const [errPopup, setErrorPopup] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [fullscreenLoading, setFullScreenLoading] = useState(false);
  const [confirmArguments, setConfirmArguments] = useState(null);
  const [changed, setChanged] = useState(false);

  const getWarehousePairs = useCallback(async () => {
    try {
      const res = await dispatch(getWarehouseCapacities());
      const columns = res.map(warehouse => (
        {
          field: warehouse.name,
          headerName: warehouse.name,
          minWidth: 120,
          valueFormatter: (value) => formatFieldValue(value, warehouse.capacity),
          editable: true
        }));
      setColumns([
        {
          field: 'partner_id',
          headerName: 'Partner ID',
          minWidth: 80,
          cellClassName: 'partner_column_style',
        },
        {
          field: 'partner_name',
          headerName: 'Partner',
          minWidth: 240,
          cellClassName: 'partner_column_style',
        },
      ].concat(columns));

      const capacities = res.reduce((accumulator, warehouse) => {
        accumulator[warehouse.name] = warehouse.capacity
        return accumulator
      }, {})
      setCapacities(capacities)
    } catch (e) {
      dispatch(showAlertSnackbar({ message: "Fetch warehouses failed: " + e.message, type: 'error' }));
    }
  }, [dispatch])

  async function updateThreshold() {
    setFullScreenLoading(true);
    try {
      const params = {
        week: week,
        year: year,
        data: rows
      };
      await dispatch(updatePartnerThresholdByWarehouse(params));

      setChanged(false)
      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.UPDATE_FAILED + ": " + e.message, type: 'error' }));
      setErrMsg(e.message);
      setErrorPopup(false);
    } finally {
      setFullScreenLoading(false);
      await fetchRowData();
    }
  }

  const fetchRowData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getPartnerThresholdByWarehouse(year, week));

      const rows = Array.from(res);
      rows.sort((a, b) => a.partner_id - b.partner_id);

      // Put top 4 partners in the beginning
      for (let partnerId of top4PartnerIds.toReversed()) {
        const index = rows.findIndex(row => row.partner_id === partnerId)
        if (index !== -1) {
          const row = rows.splice(index, 1)[0]
          rows.unshift(row)
        }
      }

      setRows(rows);

      dispatch(showAlertSnackbar({ message: ALERT.SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.FAILED + ": " + e.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, year, week])

  useEffect(() => {
    const date = dayjs();
    setYear(date.format('YYYY'));
    setWeek(date.isoWeek());
  }, [])

  useEffect(() => {
    if (year && week) {
      getWarehousePairs();
      fetchRowData();
    }
  }, [year, week, getWarehousePairs, fetchRowData])

  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      return new Promise((resolve, reject) => {
        const changedField = findChangedField(newRow, oldRow)
        const changedValue = newRow[changedField]
        if (changedValue && (changedValue === '' || !isNaN(changedValue))) {
          setConfirmArguments({ resolve, reject, newRow, oldRow });
        }
        else {
          resolve(oldRow)
        }
      })
    },
    []
  )

  const renderConfirmDialog = () => {
    if (!confirmArguments) return null;
    const { newRow, oldRow, resolve } = confirmArguments;
    const changedField = findChangedField(newRow, oldRow);
    const capacity = capacities[changedField]

    function no() {
      resolve(oldRow)
      setConfirmArguments(null)
    }
    function yes() {
      const updatedRows = rows.map((row) => {
        if (row.partner_id === newRow.partner_id) {
          row[changedField] = newRow[changedField];
          return row;
        }
        return row;
      });
      setRows(updatedRows);

      resolve(newRow)
      setConfirmArguments(null)
      setChanged(true)
    }

    return (
      <Dialog open onClose={no}>
        <DialogTitle>{newRow.partner_name}</DialogTitle>
        <DialogContent dividers>
          <b>{changedField}: </b>
          Change from <span style={{ color: 'red' }}>{formatFieldValue(oldRow[changedField], capacity)}</span> to <b style={{ color: 'green' }}>{formatFieldValue(newRow[changedField], capacity)}</b>
        </DialogContent>
        <DialogActions>
          <Button onClick={no}>
            No
          </Button>
          <Button onClick={yes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Update Partner Threshold
      </Typography>

      <Card variant='outlined'>
        <Box display='flex' alignItems='center' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Week: {week}, {year}
          </Typography>
          <Button
            onClick={() => {
              fetchRowData();
              setChanged(false);
            }}
            color='error'
            disabled={!changed}
            sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={updateThreshold}
            variant='contained'
            disabled={!changed}>
            Update
          </Button>
        </Box>

        {renderConfirmDialog()}

        <DataGrid
          rows={rows}
          getRowId={(row) => row.partner_id}
          columns={columns}
          loading={loading}
          disableColumnMenu
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          disableRowSelectionOnClick
          getCellClassName={(params) => {
            if (params.isEditable && isNaN(params.value)) {
              return 'notSet';
            }
          }}
          sx={{
            border: 'none',
            borderRadius: 0,
            height: 'calc(100vh - 240px)',
            '& .partner_column_style': {
              fontWeight: 600,
            },
            '& .notSet': {
              color: 'lightgray'
            }
          }}
        />
      </Card>

      <Dialog maxWidth='sm' fullWidth open={!errPopup} onClose={() => setErrorPopup(false)}>
        <DialogTitle><b>Update Threshold Error</b></DialogTitle>
        <DialogContent dividers>
          <p>{"Thresholds not Updated: " + errMsg}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setErrorPopup(true) }}>Ok</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={fullscreenLoading} />
    </div>
  );
}
import { useState, useEffect, useRef } from 'react';
import { Card, Box, Stack, IconButton, TextField, Divider, Dialog, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { searchInvoiceList, getInvoiceDetails } from '../../actions/billingManangementActions';
import { useDispatch } from 'react-redux';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import SearchIcon from '@mui/icons-material/Search';
import InvoiceDetail from './InvoiceDetail';
import CreditMemoDetail from './CreditMemoDetail';
import FullscreenLoading from "../../components/FullscreenLoading";

export default function InvoiceManagement() {
  const dispatch = useDispatch();
  const [invoiceNum, setInvoiceNum] = useState("");
  const [invoiceTo, setInvoiceTo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dialog, setDialogOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [fullscreenLoad, setFullScreenLoad] = useState(false);
  const hasBeenRendered = useRef(false);

  const invoiceListColumns = [
    {
      field: 'cr_num',
      headerName: "Invoice Number",
      width: 260,
    },
    {
      field: 'invoice_num',
      headerName: "Master Invoice Number",
      width: 260,
    },
    {
      field: 'create_at',
      headerName: "Invoice Creation Date",
      width: 260,
    },
    {
      field: 'total_amount',
      headerName: "Amount",
      width: 260,
      valueGetter: (value) => {
        return "$" + value;
      }
    },
    {
      field: 'slip_type_description',
      headerName: "Invoice Type",
      width: 260,
    }
  ]

  function getInvoiceList() {
    setLoading(true);
    const params = {
      invoice_num: invoiceNum,
    };

    dispatch(searchInvoiceList(params))
      .then((res) => {
        setDisplayInfo(true);
        setInvoiceList(res.slip_list);
        setInvoiceTo(res.invoice_to);
        setTotalAmount("$" + res.total_amount);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setDisplayInfo(false);
        setInvoiceList([]);
        setInvoiceTo("");
        setTotalAmount("");
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })
  }

  useEffect(() => {
    if (hasBeenRendered.current) {
      dispatch(getInvoiceDetails(rowData))
        .then((res) => {
          setDetails(res);
          setFullScreenLoad(false);
          setDialogOpen(true);
        })
        .catch((err) => {
          setFullScreenLoad(false);
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        })
    }
    hasBeenRendered.current = true;
  }, [rowData, dispatch]);

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Invoice Management
      </Typography>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <TextField
            label="Invoice Number"
            variant="outlined"
            placeholder="Enter your invoice number here"
            value={invoiceNum}
            onChange={(e) => setInvoiceNum(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: '360px' }}
          />
          <IconButton onClick={() => getInvoiceList()}>
            <SearchIcon />
          </IconButton>
          {displayInfo && (<>
            <Divider orientation="vertical" flexItem />
            <Box>
              <Typography marginBottom={1}>
                <b>Invoice Combined Information</b>
              </Typography>
              <Typography display='inline-block' marginRight={4}>
                <Typography color='text.secondary' component='span'>Invoice To: </Typography>
                {invoiceTo}
              </Typography>
              <Typography display='inline-block'>
                <Typography color='text.secondary' component='span'>Total Amount: </Typography>
                {totalAmount}
              </Typography>
            </Box>
          </>)}
        </Stack>
      </Card>

      <Card variant='outlined'>
        <Box display='flex' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Invoice
          </Typography>
        </Box>

        <DataGrid
          autoHeight
          loading={loading}
          rows={loading ? [] : invoiceList}
          columns={invoiceListColumns}
          checkboxSelection={false}
          onRowClick={({ row }) => {
            setFullScreenLoad(true);
            const rowData = {};
            invoiceListColumns.forEach(column => {
              rowData[column.field] = row[column.field];
            });
            setRowData(rowData);
          }}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>

      <Dialog maxWidth={"lg"} fullWidth open={dialog}>
        {
          details?.type &&
          <>
            {
              details['type'] === 'Invoice' ?
                <InvoiceDetail
                  setDialogOpen={setDialogOpen}
                  refreshDialog={setRowData}
                  setDetails={setDetails}
                  refresh={getInvoiceList}
                  data={details}
                />
                :
                <CreditMemoDetail
                  setDialogOpen={setDialogOpen}
                  data={details}
                />
            }
          </>
        }
      </Dialog>

      <FullscreenLoading open={fullscreenLoad || !details} />
    </div>
  );
}
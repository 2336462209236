import { Button, Checkbox, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePaginationActions from "../../components/TablePaginationActions";
import DriverEdit from "./DriverEdit";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fetchDriverList, getT4aTotal } from "../../actions/t4aActions";
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { getUserPreference } from "../../utilities/common";

const provinceList = [
  {
    name: 'All',
    code: 'ALL'
  },
  {
    name: 'AB',
    code: 'AB'
  },
  {
    name: 'BC',
    code: 'BC'
  },
  {
    name: 'ON',
    code: 'ON'
  },
  {
    name: 'QC',
    code: 'QC'
  }
]
const companyList = [
  {
    name: 'All',
    code: 'ALL'
  },
  {
    name: 'Uni BC',
    code: '1'
  },
  {
    name: 'Uni ON',
    code: '2'
  },
  {
    name: 'Uni QC',
    code: '3'
  }
]
const statusList = [
  {
    name: 'All',
    code: 'ALL'
  },
  {
    name: 'Sent',
    code: '1'
  },
  {
    name: 'Unsent',
    code: '0'
  }
]

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontWeight: 'bold',
    padding: '4px !important',
  },
  bodyCell: {
    padding: '4px !important'
  },
  checkboxStyle: {
    '&:hover': {
      backgroundColor: '#ffe0b2 !important',
      cursor: 'pointer'
    }
  },
  checkboxStyleChecked: {
    '&:hover': {
      backgroundColor: '#ffe0b2 !important',
      cursor: 'pointer'
    },
    backgroundColor: '#ffb74d !important'
  },
}))

export default function DriverTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type, input, handleSelectedRows } = props;

  const driverListAll = useSelector(state => state.t4a.fetchDriverList);
  const totalNum = useSelector(state => state.t4a.getT4aTotal);
  const driverSearch = useSelector(state => state.t4a.searchDriver);

  const [driverOpen, setDriverOpen] = useState(false);
  const [driverInfo, setDriverInfo] = useState({});
  const [driverList, setDriverList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pageTotal, setPageTotal] = useState(0);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [province, setProvince] = useState('ALL');
  const [company, setCompany] = useState('ALL');
  const [state, setState] = useState('ALL');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const toggleCheckboxValueAll = () => {
    const all = isCheckedAll;
    setIsCheckedAll(!all);
    setIsChecked(isChecked.map((v, i) => (v = !all)))
  }
  const toggleCheckboxValue = (index) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  }

  function handleDriverOpen(driver) {
    setDriverOpen(true);
    setDriverInfo(driver);
  }
  function handleDriverClose() {
    setDriverOpen(false);
  }
  function downloadPDF(id) {
    const axios = require('axios');
    const baseURL = process.env.REACT_APP_SJ_SERVER_URL;
    const getPDF = () => {
      return axios.get(`${baseURL}/account/drivert4apdf?driver_id=${id}`,
        {
          headers: {
            'Authorization': `Bearer ${getUserPreference().authToken}`,
          },
          responseType: 'arraybuffer'
        }).then((res) => {
          var response = {};
          try {
            response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(res.data)));
          } catch (e) {
          }
          if (response.status === "FAIL") {
            dispatch(showAlertSnackbar({ message: response.ret_msg, type: 'error' }))
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]
              , { type: "application/pdf" }))
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 't4A_' + id + '.pdf');
            document.body.appendChild(link);
            link.click();
          }
        });
    }
    getPDF();
  }

  useLayoutEffect(() => {
    dispatch(fetchDriverList(page + 1, rowsPerPage, province, company, state));
  }, [dispatch, page, rowsPerPage, province, company, state])
  useEffect(() => {
    setPage(0);
  }, [type, input])
  useEffect(() => {
    if (driverList && totalNum) {
      if (driverList.length > 0) {
        setIsChecked(driverList.slice().fill(false));
        setIsCheckedAll(false);
        if (!input && totalNum[0]) {
          setPageTotal(totalNum[0].total);
        }
      }
    }
  }, [driverList, totalNum, input])
  useEffect(() => {
    if (isChecked) {
      if (isChecked.length > 0) {
        let all = isCheckedAll;
        for (let i = 0; i < isChecked.length; i++) {
          all = isChecked[i];
          if (!all) {
            break;
          }
        }
        setIsCheckedAll(all);
      }
    }
  }, [isChecked, isCheckedAll])
  useEffect(() => {
    if (!input) {
      if (driverListAll) {
        setDriverList(driverListAll)
      }
    }
  }, [input, driverListAll])
  useEffect(() => {
    if (input) {
      if (driverSearch) {
        if (driverSearch.length > 0) {
          setDriverList(driverSearch);
          setPageTotal(driverSearch.length);
        }
      }
    }
  }, [input, driverSearch])
  useEffect(() => {
    if (window.localStorage.getItem('auth_token')) {
      dispatch(fetchDriverList(1, 20))
      dispatch(getT4aTotal())
    }
  }, [dispatch])
  useLayoutEffect(() => {
    if (province && company && state) {
      dispatch(fetchDriverList(page + 1, rowsPerPage, province, company, state))
    }
  }, [province, company, state, page, rowsPerPage, dispatch])
  useEffect(() => {
    if (isChecked && driverList) {
      let checked = [];
      isChecked.forEach((value, index) => {
        if (value) {
          checked.push(driverList[index].id)
        }
      })
      handleSelectedRows(checked);
    }
  }, [isChecked, driverList, handleSelectedRows])

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#ebebeb" }}>
              <TableCell align="left" className={classes.headerTitle}>
                <Checkbox
                  onClick={toggleCheckboxValueAll}
                  checked={isCheckedAll}
                  sx={{ color: "#262626" }}
                />
              </TableCell>
              <TableCell align="center" className={classes.headerTitle}>Driver ID</TableCell>
              <TableCell align="center" className={classes.headerTitle}>Last Name</TableCell>
              <TableCell align="center" className={classes.headerTitle}>First Name</TableCell>
              <TableCell align="center" className={classes.headerTitle}>SIN</TableCell>
              <TableCell align="center" className={classes.headerTitle}>
                <span style={{ marginRight: 10 }}>
                  Province:
                </span>
                <Select
                  disableUnderline={true}
                  defaultValue={'ALL'}
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  size='small'
                >
                  {
                    provinceList.map((province) => (
                      <MenuItem value={province.code}>
                        {province.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </TableCell>
              <TableCell align="center" className={classes.headerTitle}>
                <span style={{ marginRight: 10 }}>
                  Company:
                </span>
                <Select
                  disableUnderline={true}
                  defaultValue={'ALL'}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  size='small'>
                  {
                    companyList.map((company) => (
                      <MenuItem value={company.code}>
                        {company.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </TableCell>
              <TableCell align="center" className={classes.headerTitle}>Amount</TableCell>
              <TableCell align="center" className={classes.headerTitle}>
                <span style={{ marginRight: 10 }}>
                  T4A:
                </span>
                <Select
                  disableUnderline={true}
                  defaultValue={'ALL'}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  size='small'>
                  {
                    statusList.map((status) => (
                      <MenuItem value={status.code}>
                        {status.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </TableCell>
              <TableCell align="center" className={classes.headerTitle}>Confirm</TableCell>
              <TableCell align="center" className={classes.headerTitle}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              driverList.length > 0 &&
              driverList.map((driver, index) => (
                <TableRow
                  key={driver.id}
                  className={isChecked[index] ? classes.checkboxStyleChecked : classes.checkboxStyle}
                  hover
                  onClick={() => handleDriverOpen(driver)}
                  style={index % 2 ? { background: "#fff3e0" } : { background: "white" }}>
                  <TableCell align="left" className={classes.bodyCell}>
                    <Checkbox
                      style={{
                        color: "#262626"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCheckboxValue(index)
                      }}
                      checked={isChecked[index] === true}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.driver_id}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.last_name}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.first_name}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.sin}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.province}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.company}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>{driver.amount}</TableCell>
                  <TableCell align="center" className={classes.bodyCell}>
                    {
                      driver.message_sent ? 'Sent' : 'Unsent'
                    }
                  </TableCell>
                  <TableCell align="center" className={classes.bodyCell}>
                    {
                      driver.confirmed === 0 ? null : <CheckCircleIcon style={{ color: '#bbdefb' }} />
                    }
                  </TableCell>
                  <TableCell align="center" className={classes.bodyCell}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadPDF(driver.driver_id)
                      }}>Download</Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow style={{ align: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[20, 50]}
                colSpan={10}
                count={pageTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DriverEdit
        open={driverOpen}
        driverInfo={driverInfo}
        handleClose={handleDriverClose}
        provinceList={provinceList}
        companyList={companyList}
      />
    </div>
  )
}
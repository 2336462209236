import { useDispatch } from 'react-redux';
import { Button, FormControl, MenuItem, Select, TextField, Typography, Card, Stack, Box } from '@mui/material';
import { useState } from 'react';
import moment from "moment";
import { createPrealertOrder, getFailedStatistic, handleRowValues } from "../../actions/prealertStatisticActions";
import { handleOpenBusinessFormDialog, handleOpenPrealertFormDialog, showAlertSnackbar } from "../../reducers/sliceReducer";
import { DataGrid } from "@mui/x-data-grid";
import CreateEditDialog from "./CreateEditDialog";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import * as XLSX from 'xlsx';
import CreateBusinessDialog from "./CreateBusinessDialog";
import { createPartnerOrder } from "../../actions/sandboxActions";
import FullscreenLoading from "../../components/FullscreenLoading";

function PrealertSearchKeyword() {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(moment().utc().subtract(1, 'days').format('YYYY-MM-DDTHH:mm'));
  const [dateTo, setDateTo] = useState(moment().utc().format('YYYY-MM-DDTHH:mm'));
  const [keyword, setKeyword] = useState();
  const [type, setType] = useState('partner_id');
  const [err, setErr] = useState('All');
  const [rows, setRows] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [failedData, setFailedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const typeList = [
    {
      name: "Partner ID",
      type: "partner_id"
    },
    {
      name: "Tracking Number",
      type: "tno"
    },
    {
      name: "MAWB",
      type: "mawb"
    },
    {
      name: "Reference Number",
      type: "reference"
    },
  ]

  const errList = [
    { name: 'All' },
    { name: 'Validation error' },
    { name: 'Tracking number exists' },
    { name: 'Postal code NIS' },
    { name: 'Po Box not supported' },
    { name: 'IAN duplicate' },
    { name: 'Possible DB error' },
    { name: 'Invalid postal code' },
    { name: 'Invalid address' },
    { name: 'Country not supported' },
    { name: 'Cannot find customer' },
    { name: 'Create order error' }
  ];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      width: 180,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 250,
    },
    {
      field: 'mawb',
      headerName: 'MAWB',
      width: 150,
    },
    {
      field: 'tno',
      headerName: 'Tracking Number',
      width: 200,
    },
    {
      field: 'reference',
      headerName: 'Reference',
      width: 200,
    },
    {
      field: 'error_message',
      headerName: 'Error Message',
      width: 350,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      width: 190,
    },
  ];

  const handleKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row) => {
      const createdAt = new Date(row.created_at * 1000).toISOString().replace('T', ' ').split('.')[0];

      return {
        id: row.id,
        partner_name: row.partner_name,
        type: row.type,
        mawb: row.mawb,
        tno: row.tno,
        reference: row.reference,
        data: row.data,
        error_message: row.error_message,
        created_at: createdAt,
      };
    });

    setRows(updatedRows);
  };

  async function handleSearch() {
    setLoading(true);
    const params = {
      start_time: moment(dateFrom + "Z").valueOf() / 1000,
      end_time: moment(dateTo + "Z").valueOf() / 1000,
      [type]: keyword ? keyword : null,
      error_message: err
    }
    try {
      let response;
      response = await dispatch(getFailedStatistic(params)).catch(e => {
        throw e.message;
      })
      for (let i = 0; i < response.length; i++) {
        response[i]["data"] = JSON.stringify(response[i]["data"]);
      }
      setFailedData(response);
      handleUpdateRow(response);
      dispatch(showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: e, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  const findPartnerId = (data, id) => {
    const failedItem = data.find(item => item.id === id);
    return failedItem ? failedItem.partner_id : 0;
  };

  const handleRowClick = (selectedRow) => {
    if ('parcel_info' in JSON.parse(selectedRow.row?.data)) {
      dispatch(handleRowValues(selectedRow.row));
      dispatch(handleOpenPrealertFormDialog({ open: true }));
    } else {
      const partnerId = findPartnerId(failedData, selectedRow.row['id']);

      const params = {
        ...selectedRow.row,
        partner_id: partnerId,
      };

      dispatch(handleRowValues(params));
      dispatch(handleOpenBusinessFormDialog({ open: true }));
    }
  };

  const handleSubmit = async (paramsForm) => {
    try {
      const b2b = !paramsForm['MAWB'];
      const parcel_info = { parcel_info: [paramsForm] };
      await dispatch(createPrealertOrder(parcel_info, b2b));

      dispatch(showAlertSnackbar({ message: 'edit_success', type: 'success' }));
      dispatch(handleOpenPrealertFormDialog({ open: false }));
    } catch (err) {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    }
  };

  const handleBusinessSubmit = async (paramsForm) => {
    try {
      setLoading(true);

      await dispatch(createPartnerOrder(paramsForm));

      dispatch(handleOpenBusinessFormDialog({ open: false }));
      dispatch(showAlertSnackbar({ message: 'edit_success', type: 'success' }));
    } catch (err) {
      dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };


  const handleDownload = () => {
    setLoading(true);

    let filtered = [...failedData];

    if (selectedId.length !== 0) {
      filtered = filtered.filter((row) => selectedId.includes(row.id));
    }
    filtered = filtered.map(({ id, partner_id, partner_name, type, mawb, tno, reference, data, error_message, created_at, ...rest }) => ({
      "Id": id,
      "Partner Id": partner_id,
      "Partner Name": partner_name,
      "Type": type,
      "MAWB": mawb,
      "Tracking Number": tno,
      "Reference": reference,
      "data": data,
      "Error Message": error_message,
      "Created at": new Date(created_at * 1000).toISOString().replace('T', ' ').split('.')[0],
    }));

    const workbook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(filtered);

    workbook.SheetNames.push("report");
    workbook.Sheets["report"] = workSheet;

    setLoading(false);

    XLSX.writeFile(workbook, "failed_reports.xlsx");
  };

  // useEffect(() => {
  //   handleSearch();
  // }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Failed Reports (UTC Time)
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <TextField
            id="prealert_starttime_text"
            type="datetime-local"
            variant="outlined"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
          <TextField
            id="prealert_endtime_text"
            type="datetime-local"
            variant="outlined"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
          />
          <FormControl variant="outlined">
            <Select
              id={'prealert_search_err'}
              value={err}
              onChange={(e) => setErr(e.target.value)}>
              {errList.map((err) => (
                <MenuItem value={err.name} key={err.name}>
                  {err.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <Select
              id={'prealert_search_type'}
              value={type}
              onChange={(e) => setType(e.target.value)}>
              {typeList.map((type) => (
                <MenuItem value={type.type} key={type.type}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id={'prealert_search_keyword'}
            variant='outlined'
            onChange={handleKeyword}
            value={keyword}
          />
          <IconButton
            size={'large'}
            color={'primary'}
            onClick={handleSearch}
            id={'prealert_search_button'}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>
      <Card variant='outlined'>
        <Box display='flex' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Failed Imports
          </Typography>
          <Button
            onClick={handleDownload}
            variant='contained'
            color='secondary'
            size='small'>
            DOWNLOAD
          </Button>
        </Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(ids) => setSelectedId(ids)}
          onRowClick={(selectedRow) => handleRowClick(selectedRow)}
          selectionModel={selectedId}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          pageSizeOptions={[10, 20, 30]}
          paginationModel={{ pageSize: 10 }}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>
      <CreateEditDialog
        onSubmit={handleSubmit}
      />
      <CreateBusinessDialog
        onSubmit={handleBusinessSubmit}
      />
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default PrealertSearchKeyword;
import { useState, useEffect } from "react";
import { Button, Checkbox, Grid, TextField, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=> ({
  header: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  title: {
    textAlign: 'right',
    padding: 10
  },
  content: {
    padding: 10
  }
}))

export default function ServicePointForm({ warehouse, info, onSubmit }) {
  const { t } = useTranslation('servicePointManagement');
  const classes = useStyles();
  const cityList = useSelector(state => state.servicePoint.cityList);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState(0);
  const [serialNo, setSerialNo] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [unit, setUnit] = useState('');
  const [address, setAddress] = useState('');
  // const [postalCode, setPostalCode] = useState('');
  const [phone, setPhone] = useState('');
  const [maxStorage, setMaxStorage] = useState('');
  const [preciseType, setPreciseType] = useState('');
  const [businessHours, setBusinessHours] = useState('');
  const [deviceUsed, setDeviceUsed] = useState('');
  const [remark, setRemark] = useState('');
  const [openChecked, setOpenChecked] = useState(true);

  useEffect(() => {
    const city = warehouse;
    if (city !== null && city !== "" && Array.isArray(cityList)) {
      cityList.forEach((item) => {
        if (item.id + '' === city + '') {
          setDistrictList(item.district);
          setDistrict(item.district[0].id);
        }
      });
    }
  }, [cityList, warehouse])

  useEffect(() => {
    if(info && info[0]) {
      setDistrict(info[0].district);
      setSerialNo(info[0].name);
      setCompanyName(info[0].company);
      setUnit(info[0].unit_number);
      setAddress(info[0].address);
      // setPostalCode(info);
      setPhone(info[0].phone);
      setMaxStorage(info[0].storage_space);
      setPreciseType(info[0].premise_type);
      setBusinessHours(info[0].business_hours);
      setDeviceUsed(info[0].device);
      setRemark(info[0].remark);
      setOpenChecked(info[0].is_active === 0);
    }
  }, [info])

  function handleSubmit() {
    let params = {};
    params.warehouse = window.localStorage.getItem('warehouse');
    params.district = district;
    params.company = companyName;
    params.unit_number = unit;
    params.address = address;
    params.phone = phone;
    params.storage_space = maxStorage;
    params.premise_type = preciseType;
    params.business_hours = businessHours;
    params.device = deviceUsed;
    params.remark = remark;
    params.is_active = openChecked ? 0 : 1;
    onSubmit(serialNo, params)
  }

  return (
    <Card sx={{ padding: 2 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={6}>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('company_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('serial_no')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('company')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 500 }}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('address_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('apt_unit')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 100 }}
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('address')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 500 }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <div>{t('address_format')}</div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>
              {t('district')}
            </Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                id="district"
                select
                label={t('list_district')}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={district}
                onChange={(e) => { setDistrict(parseInt(e.target.value)) }}
                SelectProps={{
                  native: true,
                }}
                style={{ width: 300 }}
                required
              >
                {
                  districtList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.text}
                    </option>
                  ))
                }
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={4} className={classes.title}>Postal Code</Grid>
              <Grid item xs={12} sm={8} className={classes.content}>
                  <TextField
                      variant="outlined"
                      style={{width: 100}}
                      value={postalCode}
                      onChange={(e)=>setPostalCode(e.target.value)}
                      required
                      // validator matchRegexp:^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$ t('wrong_postal_format')
                  />
                  <div>{t('postal_format')}</div>
              </Grid> */}
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('contact_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('phone')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 200 }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('storage_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('max_store')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 100 }}
                value={maxStorage}
                onChange={(e) => setMaxStorage(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('business_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('premise_type')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 300 }}
                value={preciseType}
                onChange={(e) => setPreciseType(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('business_hours')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                style={{ width: 600 }}
                value={businessHours}
                onChange={(e) => setBusinessHours(e.target.value)}
                required
              />
              <div>{t('business_hours_format')}</div>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('device_used')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                variant="outlined"
                value={deviceUsed}
                onChange={(e) => setDeviceUsed(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} className={classes.header}>{t('remark_info')}</Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('remark')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <TextField
                multiline
                maxRows={5}
                variant="outlined"
                style={{ width: 300 }}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.title}>{t('service_point_status')}</Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <span>{t('close_service_point')}</span>
              <Checkbox
                color='secondary'
                checked={!openChecked}
                onChange={(e) => {
                  setOpenChecked(!(e.target.checked));
                }}
              />
              <span style={{ marginLeft: 20 }}>{t('open_service_point')}</span>
              <Checkbox
                color='secondary'
                checked={openChecked}
                onChange={(e) => {
                  setOpenChecked(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Button type="submit" variant='contained' color='primary'>{t('submit')}</Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}
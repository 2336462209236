import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography, Card, Stack } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { getPartners190Stats, getPartners190Warehouse } from "../../actions/prealertStatisticActions";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import FullscreenLoading from "../../components/FullscreenLoading";
import dayjs from "dayjs";

const processData = (res, dates) => {
  const result = [];

  const partnerData = {};

  res.forEach(entry => {
    const { partner_id, partner_name, date, count } = entry;
    if (!partnerData[partner_id]) {
      partnerData[partner_id] = {
        partner_id,
        partner_name,
      };
    }
    partnerData[partner_id][date] = count;
  });

  for (const partnerId in partnerData) {
    const partnerInfo = partnerData[partnerId];
    const partnerEntry = { ...partnerInfo };

    dates.forEach(date => {
      if (partnerInfo[date]) {
        partnerEntry[date] = partnerInfo[date];
      } else {
        partnerEntry[date] = 0;
      }
    });

    result.push(partnerEntry);
  }

  return result;
};

const initialDateFrom = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
const initialDateTo = dayjs().format('YYYY-MM-DD');

function PrealertPartnerVolume() {
  const dispatch = useDispatch();
  const [partner, setPartner] = useState('');
  const [date, setDate] = useState('');
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [detailRow, setDetailRow] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(false);

  const detailCol = [
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex: 1
    },
    {
      field: 'count',
      headerName: 'Order Volume',
      flex: 1
    }
  ];

  const handleUpdateCol = (dates) => {
    const newColumns = [
      {
        field: 'partner_name',
        headerName: 'Partner',
        minWidth: 280,
        cellClassName: 'column_style',
        flex: 1
      },
      ...dates.map(date => ({
        field: date,
        headerName: date,
        minWidth: 200,
        flex: 1
      })),
    ];

    setColumns(newColumns);
  };

  const handleUpdateRow = (res, dates) => {
    const updatedRows = processData(res, dates);
    setRows(updatedRows);
  };

  const handleUpdateDetailRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.warehouse_id,
      warehouse: row.warehouse_name,
      count: row.count.toLocaleString(),
    }));
    setDetailRow(updatedRows);
  };

  const handleSearch = useCallback(async (dateFrom, dateTo) => {
    setLoading(true);
    try {
      const res = await dispatch(getPartners190Stats(dateFrom, dateTo)).catch(e => {
        throw e.message;
      })

      const dates = [];
      let currentDate = dayjs(dateFrom);

      while (currentDate.isBefore(dateTo) || currentDate.isSame(dateTo)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }

      handleUpdateCol(dates);
      handleUpdateRow(res, dates);

      dispatch(showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: e, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading])

  const handleCellClick = async (params) => {
    if (params.field !== 'partner_name') {
      setLoading(true);
      try {
        const { id, field: date, row } = params;

        setPartner(row.partner_name);
        setDate(date);

        const res = await dispatch(getPartners190Warehouse(id, date));

        handleUpdateDetailRow(res);
        setOpenView(true);

        dispatch(showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' }));
      } catch (e) {
        dispatch(showAlertSnackbar({ message: e, type: 'error' }));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!openView) {
      setDetailRow([]);
      setPartner('');
      setDate('');
    }
  }, [openView])

  useEffect(() => {
    handleSearch(initialDateFrom, initialDateTo);
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Partner Incoming Volume (Vancouver time)
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <TextField
            id="prealert_startdate_text"
            type="date"
            variant="outlined"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
          />
          <TextField
            id="prealert_enddate_text"
            type="date"
            variant="outlined"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
            helperText="Select within 7 days"
            sx={{
              '.MuiFormHelperText-root': {
                height: '18px',
                marginTop: '2px',
                marginBottom: '-20px'
              }
            }}
          />
          <IconButton
            sx={{ height: "50px", width: "50px" }}
            size={'large'}
            color={'primary'}
            onClick={() => handleSearch(dateFrom, dateTo)}
            id={'prealert_search_button'}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>
      <Card variant='outlined'>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          getRowId={(row) => row.partner_id}
          onCellClick={handleCellClick}
          disableRowSelectionOnClick
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>
      <Dialog maxWidth='xl' open={openView} onClose={() => setOpenView(false)}>
        <DialogContent>
          <Typography sx={{ color: "#707070", fontSize: '16px', marginTop: '8px' }}>Incoming Volume Details</Typography>
          <Typography sx={{ color: "#577699", fontWeight: 600, fontSize: '24px', marginTop: '8px' }}>{partner}</Typography>
          <Typography sx={{ color: "#000000DE", fontSize: '16px', marginTop: '8px', marginBottom: '24px' }}>{date}</Typography>
          <DataGrid
            autoHeight
            rows={detailRow}
            columns={detailCol}
            components={{
              Footer: () => null
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setOpenView(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default PrealertPartnerVolume;
import { Card, Box, Stack, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderPrintLabel } from '../../actions/storeActions';
import ReactToPrint from 'react-to-print';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import FullscreenLoading from '../../components/FullscreenLoading';
import * as XLSX from 'xlsx';

export default function LocalDeliveryDisplay(props) {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { importRes, page, setPage, rows, setRows } = props;
  const [orderSns, setOrderSns] = useState([]);
  const [orderLabels, setOrderLabels] = useState([]);
  const [labelPopup, setLabelsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [failOpen, setFailOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const localDeliveryColumns = [
    {
      field: 'extra_order_sn',
      headerName: "Tracking Number",
      width: 200,
    },
    {
      field: 'referer',
      headerName: "Batch Number",
      width: 200,
    },
    {
      field: 'internal_account_number',
      headerName: "Reference #",
      width: 200,
    },
    {
      field: 'pathInfo',
      headerName: "Status",
      width: 250,
    },
    {
      field: 'consignee',
      headerName: "Consignee",
      width: 200,
    },
    {
      field: 'address',
      headerName: "Address",
      width: 400,
    },
    {
      field: 'city',
      headerName: "City",
      width: 100,
    },
    {
      field: 'mobile',
      headerName: "Phone #",
      width: 143,
    },
    {
      field: 'goods_type',
      headerName: "Parcel Category",
      width: 100,
    },
    {
      field: 'add_time',
      headerName: "Upload Time",
      width: 192,
    },
  ]

  function createDeliveryOrders() {
    let orders = [];
    let temp = importRes.data ? importRes.data : importRes
    if (temp.length > 0) {
      temp.forEach((row) => {
        const { extra_order_sn, ...rest } = row
        orders.push({ id: extra_order_sn, extra_order_sn, ...rest })
      })
    }
    return orders;
  }

  function handleDownloadResult() {
    setLoading(true);
    let filtered = importRes.data ? importRes.data : importRes;
    if (filtered.length === 0) {
      showAlertSnackbar({ message: 'No Order Information, please refresh and retry', type: 'error' })
    }
    if (orderSns.length !== 0) {
      filtered = filtered.filter((row) => orderSns.includes(row.extra_order_sn));
    }

    filtered = filtered.map(({ internal_account_number, extra_order_sn, pathInfo, referer, add_time, consignee, address, mobile, city, province, goods_type, ...rest }) => ({ "Internal Reference Number": internal_account_number, "Tracking Number": extra_order_sn, "Order Status": pathInfo, "Batch Number": referer, "Create Time": add_time, "Consignee": consignee, "Address": address, "Mobile": mobile, "City": city, "Province": province, "Parcel Category": goods_type }));

    filtered.reverse();
    let workbook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet(filtered);
    workbook.SheetNames.push("orders");
    workbook.Sheets["orders"] = workSheet;
    setLoading(false);
    XLSX.writeFile(workbook, "Orders.xlsx");
  }

  async function fetchOrdersInfo() {
    setLoading(true);
    let ids = [];
    if (orderSns.length === 0) {
      let temp = importRes.data ? importRes.data : importRes;
      if (temp.length > 0) {
        temp.forEach((row) => {
          ids.push(row.extra_order_sn);
        })
      } else {
        showAlertSnackbar({ message: 'No Order Selected', type: 'error' })
      }
    } else {
      ids = orderSns;
    }

    // batch ids into groups of 10
    const batches = []
    while (ids.length > 0) {
      batches.push(ids.splice(0, 10))
    }

    // send requests in batches
    try {
      const orderLabels = []
      for (const batch of batches) {
        const labels = await Promise.all(batch.map(id => dispatch(getOrderPrintLabel(id))))
        const images = labels.map(res => {
          const base64 = res.data[0].labelContent;
          const url = 'data:image/png;base64,' + base64
          return url
        })
        orderLabels.push(...images)
      }
      setOrderLabels(orderLabels)
      setLoading(false);
      setLabelsOpen(true);
    }
    catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      setFailOpen(true);
    }
  }

  return (
    <Card variant='outlined'>
      <Box display='flex' padding={2}>
        <Typography variant='subtitle2' flex={1}>
          Results
        </Typography>
        <Stack direction='row' spacing={2}>
          <Button
            onClick={handleDownloadResult}
            variant='outlined'
            color='secondary'
            size='small'>
            DOWNLOAD
          </Button>
          <Button
            onClick={fetchOrdersInfo}
            variant='contained'
            color='secondary'
            size='small'>
            PRODUCE LABELS
          </Button>
        </Stack>
      </Box>
      {isNaN(rows) ?
        <DataGrid
          autoHeight
          rows={createDeliveryOrders() || []}
          columns={localDeliveryColumns}
          checkboxSelection
          onRowSelectionModelChange={(neworderSns) => {
            setOrderSns(neworderSns);
          }}
          selectionModel={orderSns}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pageSizeOptions={[10, 20, 30]}
          pagination
          sx={{ border: 'none', borderRadius: 0 }}
        />
        :
        <DataGrid
          autoHeight
          rows={createDeliveryOrders() || []}
          columns={localDeliveryColumns}
          checkboxSelection
          onRowSelectionModelChange={(neworderSns) => {
            setOrderSns(neworderSns);
          }}
          selectionModel={orderSns}
          disableSelectionOnClick
          pageSizeOptions={[10, 20, 30]}
          rowCount={importRes.total || 0}
          pagination
          paginationMode="server"
          paginationModel={{
            page: page - 1,
            pageSize: rows
          }}
          onPaginationModelChange={(model) => {
            setPage(model.page + 1)
            setRows(model.pageSize)
          }}
          getCellClassName={(params) => {
            return params.field;
          }}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      }
      <Dialog maxWidth='sm' fullWidth={true} open={labelPopup} onClose={() => setLabelsOpen(false)}>
        <DialogTitle>
          <span>Labels</span>
          <Stack direction='row' spacing={1} sx={{ float: 'right' }}>
            <Button autoFocus onClick={() => setLabelsOpen(false)}>Cancel</Button>
            <ReactToPrint
              pageStyle="@page { size: 4in 6in }"
              trigger={() => <Button variant='contained' disabled={!(orderLabels.length > 0)}>Print</Button>}
              content={() => componentRef.current}
            />
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <div ref={componentRef}>
            {
              orderLabels.length > 0 &&
              orderLabels.map((label) => {
                return (
                  <img
                    width='384px'
                    height='568px'
                    // this ratio is important for browser print to fit image in one page (tested in chrome and edge)
                    src={label}
                    alt=''
                  />
                )
              })
            }
          </div>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth={true} open={failOpen} onClose={() => setFailOpen(false)}>
        <DialogTitle>Failed</DialogTitle>
        <DialogContent dividers>
          <br />
          <Typography>
            {errorMessage}
          </Typography>
          <br />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setFailOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </Card>
  );
}

import { memo } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Typography, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.modal - 1
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute !important',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DialogComponent(props){
    const {title, message, children, open, 
        onSubmit, onClose, onFinish, 
        cancelButtonText, finishButtonText, buttons,
        disableDefaultButtons,
         ...rest} = props
    const {t} = useTranslation('common');
    const classes = useStyles();

    return (
        <Dialog className={classes.root} open={open} onClose={onClose} {...rest}>
            <DialogTitle className={classes.title} disableTypography>
                <Typography>{title}</Typography>
                {props.onClose ? 
                    <IconButton size="small" className={classes.closeButton} onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                    :
                    null
                }
            </DialogTitle>
            <DialogContent>
                {message && message.length > 0 ?
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                    :
                    null
                }
                {children}
            </DialogContent>
            {!disableDefaultButtons || buttons.length > 0 ?
                <DialogActions>
                {
                    buttons && buttons.length > 0 ?
                    buttons.map((button, index) => (
                        <Button key={index} color={button.color} type="submit" onClick={button.action}>
                            {button.text}
                        </Button>
                    ))
                    :
                    <>
                        <Button type="submit" onClick={onClose}>
                            {cancelButtonText || t('cancel')}
                        </Button>
                        <Button color='primary' onClick={handleSubmit}>
                            {finishButtonText || t('submit')}
                        </Button>
                    </>
                }
                </DialogActions>
                :
                null
            }
        </Dialog>
    )

    function handleSubmit(){
        onFinish()
        onClose()
    }
}

DialogComponent.defaultProps = {
    onClose: () => {},
    buttons: [],
    disableDefaultButtons: false
}

DialogComponent.propTypes = {
    onClose: PropTypes.func
}

export default memo(DialogComponent)
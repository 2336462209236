import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Card, Grid, MenuItem, TextField, Button, Typography, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import { shangjiaServerInstance } from '../../utilities/instances';
import { getRecentBatchList, getOrdersByCondition } from '../../actions/storeActions';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ReactToPrint from 'react-to-print';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { useCustomer } from "../../utilities/hooks";
import LocalDeliveryDisplay from './LocalDeliveryDisplay';
import FullscreenLoading from '../../components/FullscreenLoading';

export default function LocalDeliverySearch() {
  const { t } = useTranslation('localDelivery');
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { customerData } = useCustomer();
  const [batchList, setBatchList] = useState([]);
  const [batch, setBatch] = useState('');
  const [templateDialog, setTemplateOpen] = useState(false);
  const [uploadDialog, setUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [failOpen, setFailOpen] = useState(false);
  const [batchTyped, setBatchFromTextField] = useState('');
  const [trackingNo, setTrackingNo] = useState('');
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 12096e5).toLocaleDateString());
  const [toDate, setToDate] = useState(new Date().toLocaleDateString());
  const [goodsType, setgoodsType] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [orders, setOrders] = useState([]);

  const store = useSelector(state => state.store);

  function handleBatch(event) {
    if (event.target.value === t('create_batch')) {
      setBatch('');
    } else {
      setBatch(event.target.value);
    }
  };

  function handleView() {
    setLoading(true);
    if (!customerData) {
      setLoading(false);
      dispatch(showAlertSnackbar({ message: 'Please Select a store', type: 'error' }));
    } else {
      dispatch(getOrdersByCondition(customerData.id, '', batchTyped, trackingNo, fromDate, toDate, goodsType, rows, page))
        .then((res) => {
          setLoading(false);
          setOrders(res);
        })
    }
  }

  function handleUpload() {
    setUploadOpen(false);
    setLoading(true);
    let formData = new FormData();
    if (batch === t('create_batch') || batch === '') {
      formData.append('add_batch', 1);
    } else {
      formData.append('add_batch', 0);
      formData.append('add_batch_no', batch);
    }
    formData.append('local_import', 3);
    formData.append('orders', selectedFile);
    formData.append('is_alert', 0);
    formData.append('customer_id', customerData.id);
    formData.append('partner_id', customerData.partner_id);
    formData.append('partner_shop_id', customerData.id);
    formData.append('partner_shop_id', customerData.id);
    formData.append('partner_shop_name', customerData.alias);
    formData.append('start_phone', customerData.phone);
    formData.append('start_address', customerData.address);
    formData.append('start_consignee', customerData.receipt_name);
    formData.append('pay_method', 8);
    formData.append('type', 2);
    shangjiaServerInstance.post(
      `/business/batchbusinessimport`,
      formData,
      { timeout: 60000 * 20 },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then((res) => {
        const orders = res.data.data
        const errors = orders
          .map((order, index) => ({ number: index + 1, message: order.ret_msg }))
          .filter(order => order.message)
          .map(order => `Package #${order.number} ${order.message}`)
        if (errors.length > 0) {
          setErrorMessage(errors)
          setFailOpen(true);
        }
        setSelectedFile(null);
        setBatchFromTextField('');
        setTrackingNo('');
        dispatch(getOrdersByCondition(customerData.id, '', '', '', fromDate, toDate, '', rows, page)).then((res) => {
          setOrders(res);
        })
        dispatch(getRecentBatchList('', customerData.id, 7))
          .catch(() => {
            setBatchList([t('create_batch')]);
            setBatch(t('create_batch'));
          })
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
        setFailOpen(true);
      })
  }

  useEffect(() => {
    if (customerData) {
      if (customerData.id) {
        setLoading(true);
        dispatch(getRecentBatchList('', customerData.id, 7))
          .catch(() => {
            setBatchList([t('create_batch')]);
            setBatch(t('create_batch'));
          })
        dispatch(getOrdersByCondition(customerData.id, '', '', '', fromDate, toDate, '', rows, page))
          .then((res) => {
            setLoading(false);
            setOrders(res);
          })
      }
    } else {
      setBatchList([t('create_batch')]);
      setBatch(t('create_batch'));
    }
  }, [customerData, fromDate, toDate, rows, page, dispatch, t])

  useEffect(() => {
    if (store.getRecentBatchList) {
      let batchListCurr = [t('create_batch')];
      store.getRecentBatchList.forEach((batch) => {
        batchListCurr.push(batch.master_air_waybill);
      })
      setBatchList(batchListCurr);
    } else {
      setBatchList([t('create_batch')]);
      setBatch(t('create_batch'));
    }
  }, [store.getRecentBatchList, t]);

  return (
    <div>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={2}>
        <Typography variant='subtitle1'>
          Local Delivery
        </Typography>
        <Stack direction='row' spacing={2}>
          <Button
            startIcon={<InsertDriveFileOutlinedIcon />}
            onClick={() => setTemplateOpen(true)}
            variant='outlined'>
            TEMPLATE
          </Button>
          <Button
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={() => setUploadOpen(true)}
            variant='contained'>
            BATCH UPLOAD
          </Button>
        </Stack>

        <Dialog open={templateDialog} onClose={() => setTemplateOpen(false)}>
          <DialogTitle>Template</DialogTitle>
          <DialogContent>
            <Typography>
              The template is for placing batch orders. Please download and fill out the excel template. Then Use the BATCH UPLOAD functions to upload the excel file and place orders.
            </Typography>
            <br />
            <span style={{ backgroundColor: '#FFEE58', padding: '5px 5px 0px 5px' }}>{t('yellow')}</span> {t('yellow_required')}*
            <br />
            <br />
            <span style={{ backgroundColor: '#29B6F6', padding: '5px 5px 0px 5px' }}>{t('blue')}</span> {t('blue_optional')}
            <br />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button autoFocus onClick={() => setTemplateOpen(false)}>Cancel</Button>
            <Button
              href={process.env.REACT_APP_COUNTRY === 'US' ? "/documents/upload_template_US.xlsx" : "/documents/upload_template.xlsx"}
              download
              variant='contained'>
              {'Download'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={uploadDialog} onClose={() => setUploadOpen(false)}>
          <DialogTitle>Batch Upload</DialogTitle>
          <DialogContent dividers sx={{ paddingTop: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  id="batch-upload-stores"
                  label="Store"
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant="outlined"
                  value={customerData.alias}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Batch Number"
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant="outlined"
                  value={batch ? batch : t('create_batch')}
                  onChange={handleBatch}
                  helperText="Select from the last 7 days of batches"
                  fullWidth>
                  {batchList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="File"
                  type="file"
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant="outlined"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  helperText="Choose an Excel file to upload"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button autoFocus onClick={() => setUploadOpen(false)}>Cancel</Button>
            <Button
              variant='contained'
              onClick={handleUpload}
              disabled={!customerData}>
              UPLOAD
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <TextField
            label="Batch Number"
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant="outlined"
            value={batchTyped}
            onChange={(e) => setBatchFromTextField(e.target.value)}
          />
          <TextField
            label="Tracking Number"
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            value={trackingNo}
            onChange={(e) => setTrackingNo(e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Uploading Time From"
            type="date"
            slotProps={{ inputLabel: { shrink: true } }}
            variant="outlined"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <TextField
            label="Uploading Time To"
            type="date"
            slotProps={{ inputLabel: { shrink: true } }}
            variant="outlined"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <TextField
            label="Parcel Category"
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant="outlined"
            value={goodsType}
            onChange={(e) => setgoodsType(e.target.value)}
          />
          <IconButton
            size='large'
            color='primary'
            onClick={handleView}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Dialog maxWidth='sm' fullWidth={true} open={failOpen} onClose={() => setFailOpen(false)}>
        <DialogTitle>Error feedback</DialogTitle>
        <DialogContent dividers>
          <Typography ref={componentRef}>
            {Array.isArray(errorMessage) ? errorMessage.map(msg => <p>{msg}</p>) : <p>{errorMessage}</p>}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <ReactToPrint
            pageStyle="@page { size: 8.3in 11.7in }"
            trigger={() => <Button>Download</Button>}
            content={() => componentRef.current}
          />
          <Button autoFocus onClick={() => setFailOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <LocalDeliveryDisplay importRes={orders} page={page} setPage={setPage} rows={rows} setRows={setRows} />

      <FullscreenLoading open={loading} />
    </div >
  );
}
// User login
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT';

// Get branchlist
export const GET_BRANCH_LIST = 'GET_BRANCH_LIST';
export const GET_BRANCH_LIST_SUCCESS = 'GET_BRANCH_LIST_SUCCESS';
export const GET_BRANCH_LIST_FAIL = 'GET_BRANCH_LIST_FAIL';

// get S3 file
export const GET_S3_FILE = 'GET_S3_FILE'
export const GET_S3_FILE_SUCCESS = 'GET_S3_FILE_SUCCESS'
export const GET_S3_FILE_FAIL = 'GET_S3_FILE_FAIL'

// Store
export const GET_RECENT_BATCHLIST = 'GET_RECENT_BATCHLIST';
export const GET_RECENT_BATCHLIST_SUCCESS = 'GET_RECENT_BATCHLIST_SUCCESS';
export const GET_RECENT_BATCHLIST_FAIL = 'GET_RECENT_BATCHLIST_FAIL';

export const GET_ORDERS_BY_BATCH_INTURN = 'GET_ORDERS_BY_BATCH_INTURN';
export const GET_ORDERS_BY_BATCH_INTURN_SUCCESS = 'GET_ORDERS_BY_BATCH_INTURN_SUCCESS';
export const GET_ORDERS_BY_BATCH_INTURN_FAIL = 'GET_ORDERS_BY_BATCH_INTURN_FAIL';

export const GET_ORDERS_BY_CONDITON = 'GET_ORDERS_BY_CONDITON';
export const GET_ORDERS_BY_CONDITON_SUCCESS = 'GET_ORDERS_BY_CONDITON_SUCCESS';
export const GET_ORDERS_BY_CONDITON_FAIL = 'GET_ORDERS_BY_CONDITON_FAIL';

export const GET_ORDER_PRINT_LABELS = 'GET_ORDER_PRINT_LABELS';
export const GET_ORDER_PRINT_LABELS_SUCCESS = 'GET_ORDER_PRINT_LABELS_SUCCESS';
export const GET_ORDER_PRINT_LABELS_FAIL = 'GET_ORDER_PRINT_LABELS_FAIL';

// Service Point
export const GET_SERVICE_POINT_PICKUP_RATIO = "GET_SERVICE_POINT_PICKUP_RATIO"
export const GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS = "GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS"
export const GET_SERVICE_POINT_PICKUP_RATIO_FAIL = "GET_SERVICE_POINT_PICKUP_RATIO_FAIL"

export const BASIC_INFO_LOAD = "BASIC_INFO_LOAD"
export const BASIC_INFO_LOAD_SUCCESS = "BASIC_INFO_LOAD_SUCCESS"
export const BASIC_INFO_LOAD_FAIL = "BASIC_INFO_LOAD_FAIL"

export const SEARCH_SERVICE_POINT = "SEARCH_SERVICE_POINT"
export const SEARCH_SERVICE_POINT_SUCCESS = "SEARCH_SERVICE_POINT_SUCCESS"
export const SEARCH_SERVICE_POINT_FAIL = "SEARCH_SERVICE_POINT_FAIL"

export const OPEN_CLOSE_SERVICE_POINT = "OPEN_CLOSE_SERVICE_POINT"
export const OPEN_CLOSE_SERVICE_POINT_SUCCESS = "OPEN_CLOSE_SERVICE_POINT_SUCCESS"
export const OPEN_CLOSE_SERVICE_POINT_FAIL = "OPEN_CLOSE_SERVICE_POINT_FAIL"

export const EDIT_SERVICE_POINT = "EDIT_SERVICE_POINT"
export const EDIT_SERVICE_POINT_SUCCESS = "EDIT_SERVICE_POINT_SUCCESS"
export const EDIT_SERVICE_POINT_FAIL = "EDIT_SERVICE_POINT_FAIL"

export const ADD_SERVICE_POINT = "ADD_SERVICE_POINT"
export const ADD_SERVICE_POINT_SUCCESS = "ADD_SERVICE_POINT_SUCCESS"
export const ADD_SERVICE_POINT_FAIL = "ADD_SERVICE_POINT_FAIL"

export const SERVICE_POINT_USER_LOGIN = "SERVICE_POINT_USER_LOGIN"
export const SERVICE_POINT_USER_LOGIN_SUCCESS = "SERVICE_POINT_USER_LOGIN_SUCCESS"
export const SERVICE_POINT_USER_LOGIN_FAIL = "SERVICE_POINT_USER_LOGIN_FAIL"

export const GET_DRIVER_DROP_OFF_HISTORY = "GET_DRIVER_DROP_OFF_HISTORY"
export const GET_DRIVER_DROP_OFF_HISTORY_SUCCESS = "GET_DRIVER_DROP_OFF_HISTORY_SUCCESS"
export const GET_DRIVER_DROP_OFF_HISTORY_FAIL = "GET_DRIVER_DROP_OFF_HISTORY_FAIL"

export const HANDLE_SERVICE_POINT = "HANDLE_SERVICE_POINT"

// Sandbox test
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN"
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS"
export const CUSTOMER_LOGIN_FAIL = "CUSTOMER_LOGIN_FAIL"

export const GET_API_DESCRIPTION = "GET_API_DESCRIPTION"
export const GET_API_DESCRIPTION_SUCCESS = "GET_API_DESCRIPTION_SUCCESS"
export const GET_API_DESCRIPTION_FAIL = "GET_API_DESCRIPTION_FAIL"

export const GET_CUSTOMER_TOKEN = "GET_CUSTOMER_TOKEN"
export const GET_CUSTOMER_TOKEN_SUCCESS = "GET_CUSTOMER_TOKEN_SUCCESS"
export const GET_CUSTOMER_TOKEN_FAIL = "GET_CUSTOMER_TOKEN_FAIL"

export const CREATE_BUSINESS_ORDER = "CREATE_BUSINESS_ORDER"
export const CREATE_BUSINESS_ORDER_SUCCESS = "CREATE_BUSINESS_ORDER_SUCCESS"
export const CREATE_BUSINESS_ORDER_FAIL = "CREATE_BUSINESS_ORDER_FAIL"

export const CREATE_PARTNER_ORDER = "CREATE_PARTNER_ORDER"
export const CREATE_PARTNER_ORDER_SUCCESS = "CREATE_PARTNER_ORDER_SUCCESS"
export const CREATE_PARTNER_ORDER_FAIL = "CREATE_PARTNER_ORDER_FAIL"

export const TRACKING_UNIUNI = "TRACKING_UNIUNI"
export const TRACKING_UNIUNI_SUCCESS = "TRACKING_UNIUNI_SUCCESS"
export const TRACKING_UNIUNI_FAIL = "TRACKING_UNIUNI_FAIL"

export const FETCH_DRIVER_LIST = "FETCH_DRIVER_LIST"
export const FETCH_DRIVER_LIST_SUCCESS = "FETCH_DRIVER_LIST_SUCCESS"
export const FETCH_DRIVER_LIST_FAIL = "FETCH_DRIVER_LIST_FAIL"

export const SEARCH_DRIVER = "SEARCH_DRIVER"
export const SEARCH_DRIVER_SUCCESS = "SEARCH_DRIVER_SUCCESS"
export const SEARCH_DRIVER_FAIL = "SEARCH_DRIVER_FAIL"

export const EDIT_DRIVER = "EDIT_DRIVER"
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS"
export const EDIT_DRIVER_FAIL = "EDIT_DRIVER_FAIL"

export const GENERATE_SEND_T4A = "GENERATE_SEND_T4A"
export const GENERATE_SEND_T4A_SUCCESS = "GENERATE_SEND_T4A_SUCCESS"
export const GENERATE_SEND_T4A_FAIL = "GENERATE_SEND_T4A_FAIL"

export const DOWNLOAD_PDF = "DOWNLOAD_PDF"
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS"
export const DOWNLOAD_PDF_FAIL = "DOWNLOAD_PDF_FAIL"

export const GET_T4A_TOTAL = "GET_T4A_TOTAL"
export const GET_T4A_TOTAL_SUCCESS = "GET_T4A_TOTAL_SUCCESS"
export const GET_T4A_TOTAL_FAIL = "GET_T4A_TOTAL_FAIL"

export const DELETE_DRIVER = "DELETE_DRIVER"
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS"
export const DELETE_DRIVER_FAIL = "DELETE_DRIVER_FAIL"

export const DOWNLOAD_XML = "DOWNLOAD_XML"
export const DOWNLOAD_XML_SUCCESS = "DOWNLOAD_XML_SUCCESS"
export const DOWNLOAD_XML_FAIL = "DOWNLOAD_XML_FAIL"

export const PRINT_LABEL = "PRINT_LABEL"
export const PRINT_LABEL_SUCCESS = "PRINT_LABEL_SUCCESS"
export const PRINT_LABEL_FAIL = "PRINT_LABEL_FAIL"

export const PUSH_TRACKING = "PUSH_TRACKING"
export const PUSH_TRACKING_SUCCESS = "PUSH_TRACKING_SUCCESS"
export const PUSH_TRACKING_FAIL = "PUSH_TRACKING_FAIL"

export const ESTIMATE_SHIPPING = "ESTIMATE_SHIPPING"
export const ESTIMATE_SHIPPING_SUCCESS = "ESTIMATE_SHIPPING_SUCCESS"
export const ESTIMATE_SHIPPING_FAIL = "ESTIMATE_SHIPPING_FAIL"

export const CHECK_SERVICE_AVAILABILITY = "CHECK_SERVICE_AVAILABILITY"
export const CHECK_SERVICE_AVAILABILITY_SUCCESS = "CHECK_SERVICE_AVAILABILITY_SUCCESS"
export const CHECK_SERVICE_AVAILABILITY_FAIL = "CHECK_SERVICE_AVAILABILITY_FAIL"

export const CREATE_BATCH = "CREATE_BATCH"
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS"
export const CREATE_BATCH_FAIL = "CREATE_BATCH_FAIL"

export const CANCEL_ORDER = "CANCEL_ORDER"
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS"
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL"

export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

export const UPDATE_ORDER_BAG_NO_EXCEL = "UPDATE_ORDER_BAG_NO_EXCEL"
export const UPDATE_ORDER_BAG_NO_EXCEL_SUCCESS = "UPDATE_ORDER_BAG_NO_EXCEL_SUCCESS"
export const UPDATE_ORDER_BAG_NO_EXCEL_FAIL = "UPDATE_ORDER_BAG_NO_EXCEL_FAIL"

// Pre-alert Statistic
export const GET_MAWB_STATISTIC = "GET_MAWB_STATISTIC"
export const GET_MAWB_STATISTIC_SUCCESS = "GET_MAWB_STATISTIC_SUCCESS"
export const GET_MAWB_STATISTIC_FAIL = "GET_MAWB_STATISTIC_FAIL"

export const GET_BAG_STATISTIC = "GET_BAG_STATISTIC"
export const GET_BAG_STATISTIC_SUCCESS = "GET_BAG_STATISTIC_SUCCESS"
export const GET_BAG_STATISTIC_FAIL = "GET_BAG_STATISTIC_FAIL"

export const GET_FAILED_STATISTIC = "GET_FAILED_STATISTIC"
export const GET_FAILED_STATISTIC_SUCCESS = "GET_FAILED_STATISTIC_SUCCESS"
export const GET_FAILED_STATISTIC_FAIL = "GET_FAILED_STATISTIC_FAIL"

export const GET_WRONG_AREA_STATISTIC = "GET_WRONG_AREA_STATISTIC"
export const GET_WRONG_AREA_SUCCESS = "GET_WRONG_AREA_SUCCESS"
export const GET_WRONG_AREA_FAIL = "GET_WRONG_AREA_FAIL"

export const EDIT_ORDER = "EDIT_ORDER"
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS"
export const EDIT_ORDER_FAIL = "EDIT_ORDER_FAIL"

export const DOWNLOAD_WRONG_AREA_STATISTIC = "DOWNLOAD_WRONG_AREA_STATISTIC"
export const DOWNLOAD_WRONG_AREA_SUCCESS = "DOWNLOAD_WRONG_AREA_SUCCESS"
export const DOWNLOAD_WRONG_AREA_FAIL = "DOWNLOAD_WRONG_AREA_FAIL"

export const PRE_ALERT = {
  GET_PARTNERS_190_STATS: 'GET_PARTNERS_190_STATS',
  GET_PARTNERS_190_STATS_SUCCESS: 'GET_PARTNERS_190_STATS',
  GET_PARTNERS_190_STATS_FAIL: 'GET_PARTNERS_190_STATS',

  GET_PARTNERS_190_WAREHOUSE: 'GET_PARTNERS_190_WAREHOUSE',
  GET_PARTNERS_190_WAREHOUSE_SUCCESS: 'GET_PARTNERS_190_WAREHOUSE_SUCCESS',
  GET_PARTNERS_190_WAREHOUSE_FAIL: 'GET_PARTNERS_190_WAREHOUSE_FAIL',
}

export const GET_INVOICE = "GET_INVOICE"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL"

export const HANDLE_ROW_VALUES = 'HANDLE_ROW_VALUES'

export const GET_ORDERS_BY_CUSTOMER = 'GET_ORDERS_BY_CUSTOMER';
export const GET_ORDERS_BY_CUSTOMER_SUCCESS = 'GET_ORDERS_BY_CUSTOMER_SUCCESS';
export const GET_ORDERS_BY_CUSTOMER_FAIL = 'GET_ORDERS_BY_CUSTOMER_FAIL';

export const CREATE_ACCOUNT = {
  CREATE_PARTNER: 'CREATE_PARTNER',
  CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
  CREATE_PARTNER_FAIL: 'CREATE_PARTNER_FAIL',

  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAIL: 'CREATE_CUSTOMER_FAIL',

  CREATE_USER_ACCOUNT: 'CREATE_USER_ACCOUNT',
  CREATE_USER_ACCOUNT_SUCCESS: 'CREATE_USER_ACCOUNT_SUCCESS',
  CREATE_USER_ACCOUNT_FAIL: 'CREATE_USER_ACCOUNT_FAIL',

  EDIT_USER_ACCOUNT: 'EDIT_USER_ACCOUNT',
  EDIT_USER_ACCOUNT_SUCCESS: 'EDIT_USER_ACCOUNT_SUCCESS',
  EDIT_USER_ACCOUNT_FAIL: 'EDIT_USER_ACCOUNT_FAIL',

  GET_PARTNER: 'GET_PARTNER',
  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  GET_PARTNER_FAIL: 'CREATE_PARTNER_FAIL',

  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAIL: 'GET_CUSTOMER_FAIL',

  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAIL: 'UPDATE_CUSTOMER_FAIL',

  UPDATE_PARTNER: 'UPDATE_PARTNER',
  UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
  UPDATE_PARTNER_FAIL: 'UPDATE_PARTNER_FAIL',

  GET_ECOM_MERCHANT_NAMES: 'GET_ECOM_MERCHANT_NAMES',
  GET_ECOM_MERCHANT_NAMES_SUCCESS: 'GET_ECOM_MERCHANT_NAMES_SUCCESS',
  GET_ECOM_MERCHANT_NAMES_FAIL: 'GET_ECOM_MERCHANT_NAMES_FAIL',
}

export const USER_MANAGEMENT = {
  GET_ABILITY_LIST: "GET_ABILITY_LIST",
  GET_ABILITY_LIST_SUCCESS: "GET_ABILITY_LIST_SUCCESS",
  GET_ABILITY_LIST_FAIL: "GET_ABILITY_LIST_FAIL",

  GET_ABILITY_LIST_BY_USER: "GET_ABILITY_LIST_BY_USER",
  GET_ABILITY_LIST_BY_USER_SUCCESS: "GET_ABILITY_LIST_BY_USER_SUCCESS",
  GET_ABILITY_LIST_BY_USER_FAIL: "GET_ABILITY_LIST_BY_USER_FAIL",

  GET_ROLE_LIST: "GET_ROLE_LIST",
  GET_ROLE_LIST_SUCCESS: "GET_ROLE_LIST_SUCCESS",
  GET_ROLE_LIST_FAIL: "GET_ROLE_LIST_FAIL",

  GET_ROLE_LIST_BY_USER: "GET_ROLE_LIST_BY_USER",
  GET_ROLE_LIST_BY_USER_SUCCESS: "GET_ROLE_LIST_BY_USER_SUCCESS",
  GET_ROLE_LIST_BY_USER_FAIL: "GET_ROLE_LIST_BY_USER_FAIL",

  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAIL: "GET_USER_LIST_FAIL",

  GET_USER_LIST_BY_ROLE: "GET_USER_LIST_BY_ROLE",
  GET_USER_LIST_BY_ROLE_SUCCESS: "GET_USER_LIST_BY_ROLE_SUCCESS",
  GET_USER_LIST_BY_ROLE_FAIL: "GET_USER_LIST_BY_ROLE_FAIL",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  CREATE_NEW_ROLE: "CREATE_NEW_ROLE",
  CREATE_NEW_ROLE_SUCCESS: "CREATE_NEW_ROLE_SUCCESS",
  CREATE_NEW_ROLE_FAIL: "CREATE_NEW_ROLE_FAIL",

  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAIL: "DELETE_ROLE_FAIL",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",

  UPDATE_USER_INFORMATION: "UPDATE_USER_INFORMATION",
  UPDATE_USER_INFORMATION_SUCCESS: "UPDATE_USER_INFORMATION_SUCCESS",
  UPDATE_USER_INFORMATION_FAIL: "UPDATE_USER_INFORMATION_FAIL",

  UPDATE_USER_ROLES: "UPDATE_USER_ROLES",
  UPDATE_USER_ROLES_SUCCESS: "UPDATE_USER_ROLES_SUCCESS",
  UPDATE_USER_ROLES_FAIL: "UPDATE_USER_ROLES_FAIL",

  UPDATE_ROLE_INFORMATION: "UPDATE_ROLE_INFORMATION",
  UPDATE_ROLE_INFORMATION_SUCCESS: "UPDATE_ROLE_INFORMATION_SUCCESS",
  UPDATE_ROLE_INFORMATION_FAIL: "UPDATE_ROLE_INFORMATION_FAIL",

  UPDATE_ROLE_ABILITIES: "UPDATE_ROLE_ABILITIES",
  UPDATE_ROLE_ABILITIES_SUCCESS: "UPDATE_ROLE_ABILITIES_SUCCESS",
  UPDATE_ROLE_ABILITIES_FAIL: "UPDATE_ROLE_ABILITIES_FAIL",
}

export const DELIVERY_CAPACITIES = {
  FETCH_WAREHOUSE_CAPACITIES: "FETCH_WAREHOUSE_CAPACITIES",
  FETCH_WAREHOUSE_CAPACITIES_SUCCESS: "FETCH_WAREHOUSE_CAPACITIES_SUCCESS",
  FETCH_WAREHOUSE_CAPACITIES_FAIL: "FETCH_WAREHOUSE_CAPACITIES_FAIL",

  FETCH_PARTNER_CAPACITIES: "FETCH_PARTNER_CAPACITIES",
  FETCH_PARTNER_CAPACITIES_SUCCESS: "FETCH_PARTNER_CAPACITIES_SUCCESS",
  FETCH_PARTNER_CAPACITIES_FAIL: "FETCH_PARTNER_CAPACITIES_FAIL",

  UPDATE_WAREHOUSE_CAPACITIES: "UPDATE_WAREHOUSE_CAPACITIES",
  UPDATE_WAREHOUSE_CAPACITIES_SUCCESS: "UPDATE_WAREHOUSE_CAPACITIES_SUCCESS",
  UPDATE_WAREHOUSE_CAPACITIES_FAIL: "UPDATE_WAREHOUSE_CAPACITIES_FAIL",

  FETCH_PARTNER_WAREHOUSE_THRESHOLD: "FETCH_PARTNER_WAREHOUSE_THRESHOLD",
  FETCH_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS: "FETCH_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS",
  FETCH_PARTNER_WAREHOUSE_THRESHOLD_FAIL: "FETCH_PARTNER_WAREHOUSE_THRESHOLD_FAIL",

  UPDATE_PARTNER_WAREHOUSE_THRESHOLD: "UPDATE_PARTNER_WAREHOUSE_THRESHOLD",
  UPDATE_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS: "UPDATE_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS",
  UPDATE_PARTNER_WAREHOUSE_THRESHOLD_FAIL: "UPDATE_PARTNER_WAREHOUSE_THRESHOLD_FAIL",
}

export const FAULTY_PACKAGE = {
  GET_DROPDOWN_DATA: "GET_DROPDOWN_DATA",
  GET_DROPDOWN_SUCCESS: "GET_DROPDOWN_DATA_SUCCESS",
  GET_DROPDOWN_FAIL: "GET_DROPDOWN_DATA_FAIL",

  SEARCH_FP_RECORDS: "SEARCH_FP_RECORDS",
  SEARCH_FP_RECORDS_SUCCESS: "SEARCH_FP_RECORDS_SUCCESS",
  SEARCH_FP_RECORDS_FAIL: "SEARCH_FP_RECORDS_FAIL",

  GET_PACKAGE_INFO: "GET_PACKAGE_INFO",
  GET_PACKAGE_INFO_SUCCESS: "GET_PACKAGE_INFO_SUCCESS",
  GET_PACKAGE_INFO_FAIL: "GET_PACKAGE_INFO_FAIL",

  CREATE_NEW_RECORD: "CREATE_NEW_RECORD",
  CREATE_NEW_RECORD_SUCCESS: "CREATE_NEW_RECORD_SUCCESS",
  CREATE_NEW_RECORD_FAIL: "CREATE_NEW_RECORD_FAIL",

  UPDATE_RECORD_INFORMATION: "UPDATE_RECORD_INFORMATION",
  UPDATE_RECORD_INFORMATION_SUCCESS: "UPDATE_RECORD_INFORMATION_SUCCESS",
  UPDATE_RECORD_INFORMATION_FAIL: "UPDATE_RECORD_INFORMATION_FAIL",

  DELETE_RECORD: "DELETE_RECORD",
  DELETE_RECORD_SUCCESS: "DELETE_RECORD_SUCCESS",
  DELETE_RECORD_FAIL: "DELETE_RECORD_FAIL",

  SAVE_IMAGES: "SAVE_IMAGES",
  SAVE_IMAGES_SUCCESS: "SAVE_IMAGES_SUCCESS",
  SAVE_IMAGES_FAIL: "SAVE_IMAGES_FAIL",

  GET_IMAGES: "GET_IMAGES",
  GET_IMAGES_SUCCESS: "GET_IMAGES_SUCCESS",
  GET_IMAGES_FAIL: "GET_IMAGES_FAIL",

  SAVE_FILES: "SAVE_FILES",
  SAVE_FILES_SUCCESS: "SAVE_FILES_SUCCESS",
  SAVE_FILES_FAIL: "SAVE_FILES_FAIL",

  GET_FILES: "GET_FILES",
  GET_FILES_SUCCESS: "GET_FILES_SUCCESS",
  GET_FILES_FAIL: "GET_FILES_FAIL",

  GET_STATUS: "GET_STATUS",
  GET_STATUS_SUCCESS: "GET_STATUS_SUCCESS",
  GET_STATUS_FAIL: "GET_STATUS_FAIL",
}

export const GV_ORDER_MANAGEMENT = {
  ADD_MISSING_ORDER: "ADD_MISSING_ORDER",
  ADD_MISSING_ORDER_SUCCESS: "ADD_MISSING_ORDER_SUCCESS",
  ADD_MISSING_ORDER_FAIL: "ADD_MISSING_ORDER_FAIL",
}

export const BILLING_MANAGEMENT = {
  GENERATE_INVOICE_NUMBER: "GENERATE_INVOICE_NUMBER",
  GENERATE_INVOICE_NUMBER_SUCCESS: "GENERATE_INVOICE_NUMBER_SUCCESS",
  GENERATE_INVOICE_NUMBER_FAIL: "GENERATE_INVOICE_NUMBER_FAIL",

  SEARCH_SETTLEMENT_PROCEDURES: "SEARCH_SETTLEMENT_PROCEDURES",
  SEARCH_SETTLEMENT_PROCEDURES_SUCCESS: "SEARCH_SETTLEMENT_PROCEDURES_SUCCESS",
  SEARCH_SETTLEMENT_PROCEDURES_FAIL: "SEARCH_SETTLEMENT_PROCEDURES_FAIL",

  GET_SETTLEMENT_CUSTOMERS: "GET_SETTLEMENT_CUSTOMERS",
  GET_SETTLEMENT_CUSTOMERS_SUCCESS: "GET_SETTLEMENT_CUSTOMERS_SUCCESS",
  GET_SETTLEMENT_CUSTOMERS_FAIL: "GET_SETTLEMENT_CUSTOMERS_FAIL",
  GET_SETTLEMENT_STATUS: "GET_SETTLEMENT_STATUS",
  GET_SETTLEMENT_STATUS_SUCCESS: "GET_SETTLEMENT_STATUS_SUCCESS",
  GET_SETTLEMENT_STATUS_FAIL: "GET_SETTLEMENT_STATUS_FAIL",

  DOWNLOAD_PREAUDIT_FILE: "DOWNLOAD_PREAUDIT_FILE",
  DOWNLOAD_PREAUDIT_FILE_SUCCESS: "DOWNLOAD_PREAUDIT_FILE_SUCCESS",
  DOWNLOAD_PREAUDIT_FILE_FAIL: "DOWNLOAD_PREAUDIT_FILE_FAIL",

  DOWNLOAD_PRICE_CARD_FILE: "DOWNLOAD_PRICE_CARD_FILE",
  DOWNLOAD_PRICE_CARD_FILE_SUCCESS: "DOWNLOAD_PRICE_CARD_FILE_SUCCESS",
  DOWNLOAD_PRICE_CARD_FILE_FAIL: "DOWNLOAD_PRICE_CARD_FILE_FAIL",

  DOWNLOAD_ADJUST_FILE: "DOWNLOAD_ADJUST_FILE",
  DOWNLOAD_ADJUST_FILE_SUCCESS: "DOWNLOAD_ADJUST_FILE_SUCCESS",
  DOWNLOAD_ADJUST_FILE_FAIL: "DOWNLOAD_ADJUST_FILE_FAIL",

  DELETE_ADJUST_FILE: "DELETE_ADJUST_FILE",
  DELETE_ADJUST_FILE_SUCCESS: "DELETE_ADJUST_FILE_SUCCESS",
  DELETE_ADJUST_FILE_FAIL: "DELETE_ADJUST_FILE_FAIL",

  DOWNLOAD_ADJUST_TEMPLATE: "DOWNLOAD_ADJUST_TEMPLATE",
  DOWNLOAD_ADJUST_TEMPLATE_SUCCESS: "DOWNLOAD_ADJUST_TEMPLATE_SUCCESS",
  DOWNLOAD_ADJUST_TEMPLATE_FAIL: "DOWNLOAD_ADJUST_TEMPLATE_FAIL",

  UPLOAD_ADJUST_FILE: "UPLOAD_ADJUST_FILE",
  UPLOAD_ADJUST_FILE_SUCCESS: "UPLOAD_ADJUST_FILE_SUCCESS",
  UPLOAD_ADJUST_FILE_FAIL: "UPLOAD_ADJUST_FILE_FAIL",

  QC_APPROVAL: "QC_APPROVAL",
  QC_APPROVAL_SUCCESS: "QC_APPROVAL_SUCCESS",
  QC_APPROVAL_FAIL: "QC_APPROVAL_FAIL",

  DOWNLOAD_QCPOSTAUDIT_FILE: "DOWNLOAD_QCPOSTAUDIT_FILE",
  DOWNLOAD_QCPOSTAUDIT_FILE_SUCCESS: "DOWNLOAD_QCPOSTAUDIT_FILE_SUCCESS",
  DOWNLOAD_QCPOSTAUDIT_FILE_FAIL: "DOWNLOAD_QCPOSTAUDIT_FILE_FAIL",

  INVOICE_PREVIEW: "INVOICE_PREVIEW",
  INVOICE_PREVIEW_SUCCESS: "INVOICE_PREVIEW_SUCCESS",
  INVOICE_PREVIEW_FAIL: "INVOICE_PREVIEW_FAIL",

  RESET_SETTLEMENT_STATUS: 'RESET_SETTLEMENT_STATUS',
  RESET_SETTLEMENT_STATUS_SUCCESS: 'RESET_SETTLEMENT_STATUS_SUCCESS',
  RESET_SETTLEMENT_STATUS_FAIL: 'RESET_SETTLEMENT_STATUS_FAIL',

  SEARCH_INVOICE_LIST: 'SEARCH_INVOICE_LIST',
  SEARCH_INVOICE_LIST_SUCCESS: 'SEARCH_INVOICE_LIST_SUCCESS',
  SEARCH_INVOICE_LIST_FAIL: 'SEARCH_INVOICE_LIST_FAIL',

  UPLOAD_PICKUP_TRANSSHIPMENT_FILE: 'UPLOAD_PICKUP_TRANSSHIPMENT_FILE',
  UPLOAD_PICKUP_TRANSSHIPMENT_FILE_SUCCESS: 'UPLOAD_PICKUP_TRANSSHIPMENT_FILE_SUCCESS',
  UPLOAD_PICKUP_TRANSSHIPMENT_FILE_FAIL: 'UPLOAD_PICKUP_TRANSSHIPMENT_FILE_FAIL',

  UPLOAD_CLAIM_PACKAGES: 'UPLOAD_CLAIM_PACKAGES',
  UPLOAD_CLAIM_PACKAGES_SUCCESS: 'UPLOAD_CLAIM_PACKAGES_SUCCESS',
  UPLOAD_CLAIM_PACKAGES_FAIL: 'UPLOAD_CLAIM_PACKAGES_FAIL',

  GET_INVOICE_DETAIL: 'GET_INVOICE_DETAIL',
  GET_INVOICE_DETAIL_SUCCESS: 'GET_INVOICE_DETAIL_SUCCESS',
  GET_INVOICE_DETAIL_FAIL: 'GET_INVOICE_DETAIL_FAIL',

  DOWNLOAD_INVOICE_FILE: "DOWNLOAD_INVOICE_FILE",
  DOWNLOAD_INVOICE_FILE_SUCCESS: "DOWNLOAD_INVOICE_FILE_SUCCESS",
  DOWNLOAD_INVOICE_FILE_FAIL: "DOWNLOAD_INVOICE_FILE_FAIL",

  DOWNLOAD_CREDIT_MEMO_TEMPLATE: "DOWNLOAD_CREDIT_MEMO_TEMPLATE",
  DOWNLOAD_CREDIT_MEMO_TEMPLATE_SUCCESS: "DOWNLOAD_CREDIT_MEMO_TEMPLATE_SUCCESS",
  DOWNLOAD_CREDIT_MEMO_TEMPLATE_FAIL: "DOWNLOAD_CREDIT_MEMO_TEMPLATE_FAIL",

  UPLOAD_CREDIT_MEMO_FILE: "UPLOAD_CREDIT_MEMO_FILE",
  UPLOAD_CREDIT_MEMO_FILE_SUCCESS: "UPLOAD_CREDIT_MEMO_FILE_SUCCESS",
  UPLOAD_CREDIT_MEMO_FILE_FAIL: "UPLOAD_CREDIT_MEMO_FILE_FAIL",
}

export const ADDRESS_MANAGEMENT = {
  UPLOAD_BLOCKED_ADDRESS: 'UPLOAD_BLOCKED_ADDRESS',
  UPLOAD_BLOCKED_ADDRESS_SUCCESS: 'UPLOAD_BLOCKED_ADDRESS_SUCCESS',
  UPLOAD_BLOCKED_ADDRESS_FAIL: 'UPLOAD_BLOCKED_ADDRESS_FAIL',

  DOWNLOAD_BLOCKED_ADDRESS: 'DOWNLOAD_BLOCKED_ADDRESS',
  DOWNLOAD_BLOCKED_ADDRESS_SUCCESS: 'DOWNLOAD_BLOCKED_ADDRESS_SUCCESS',
  DOWNLOAD_BLOCKED_ADDRESS_FAIL: 'DOWNLOAD_BLOCKED_ADDRESS_FAIL',

  UPLOAD_FACTORY_ADDRESS: 'UPLOAD_FACTORY_ADDRESS',
  UPLOAD_FACTORY_ADDRESS_SUCCESS: 'UPLOAD_FACTORY_ADDRESS_SUCCESS',
  UPLOAD_FACTORY_ADDRESS_FAIL: 'UPLOAD_FACTORY_ADDRESS_FAIL',

  GET_BLOCKED_ADDRESS: 'GET_BLOCKED_ADDRESS',
  GET_BLOCKED_ADDRESS_SUCCESS: 'GET_BLOCKED_ADDRESS_SUCCESS',
  GET_BLOCKED_ADDRESS_FAIL: 'GET_BLOCKED_ADDRESS_FAIL',

  GET_FACTORY_ADDRESS: 'GET_FACTORY_ADDRESS',
  GET_FACTORY_ADDRESS_SUCCESS: 'GET_FACTORY_ADDRESS_SUCCESS',
  GET_FACTORY_ADDRESS_FAIL: 'GET_FACTORY_ADDRESS_FAIL',

  UPDATE_BLOCKED_ADDRESS: 'UPDATE_BLOCKED_ADDRESS',
  UPDATE_BLOCKED_ADDRESS_SUCCESS: 'UPDATE_BLOCKED_ADDRESS_SUCCESS',
  UPDATE_BLOCKED_ADDRESS_FAIL: 'UPDATE_BLOCKED_ADDRESS_FAIL',

  UPDATE_FACTORY_ADDRESS: 'UPDATE_FACTORY_ADDRESS',
  UPDATE_FACTORY_ADDRESS_SUCCESS: 'UPDATE_FACTORY_ADDRESS_SUCCESS',
  UPDATE_FACTORY_ADDRESS_FAIL: 'UPDATE_FACTORY_ADDRESS_FAIL',

  DELETE_ADDRESS: 'DELETE_ADDRESS',
  DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAIL: 'DELETE_ADDRESS_FAIL',
}

export const RATE_CARDS_MANAGEMENT = {
  GET_PARTNERS_RATE_CARDS: 'GET_PARTNERS_RATE_CARDS',
  GET_PARTNERS_RATE_CARDS_SUCCESS: 'GET_PARTNERS_RATE_CARDS_SUCCESS',
  GET_PARTNERS_RATE_CARDS_FAIL: 'GET_PARTNERS_RATE_CARDS_FAIL',

  CREATE_PARTNER_RATE_CARD: 'CREATE_PARTNER_RATE_CARD',
  CREATE_PARTNER_RATE_CARD_SUCCESS: 'CREATE_PARTNER_RATE_CARD_SUCCESS',
  CREATE_PARTNER_RATE_CARD_FAIL: 'CREATE_PARTNER_RATE_CARD_FAIL',

  DELETE_PARTNER_RATE_CARD: 'DELETE_PARTNER_RATE_CARD',
  DELETE_PARTNER_RATE_CARD_SUCCESS: 'DELETE_PARTNER_RATE_CARD_SUCCESS',
  DELETE_PARTNER_RATE_CARD_FAIL: 'DELETE_PARTNER_RATE_CARD_FAIL',

  UPDATE_PARTNER_KA: 'UPDATE_PARTNER_KA',
  UPDATE_PARTNER_KA_SUCCESS: 'UPDATE_PARTNER_KA_SUCCESS',
  UPDATE_PARTNER_KA_FAIL: 'UPDATE_PARTNER_KA_FAIL',
}

export const FLEET_SETTLEMENT = {
  GET_DRIVER_SUBSIDY_TOTAL: 'GET_DRIVER_SUBSIDY_TOTAL',
  GET_DRIVER_SUBSIDY_TOTAL_SUCCESS: 'GET_DRIVER_SUBSIDY_TOTAL_SUCCESS',
  GET_DRIVER_SUBSIDY_TOTAL_FAIL: 'GET_DRIVER_SUBSIDY_TOTAL_FAIL',

  UPLOAD_DRIVER_SUBSIDY_FILE: 'UPLOAD_DRIVER_SUBSIDY_FILE',
  UPLOAD_DRIVER_SUBSIDY_FILE_SUCCESS: 'UPLOAD_DRIVER_SUBSIDY_FILE_SUCCESS',
  UPLOAD_DRIVER_SUBSIDY_FILE_FAIL: 'UPLOAD_DRIVER_SUBSIDY_FILE_FAIL',

  DELETE_DRIVER_SUBSIDY_FILE: 'DELETE_DRIVER_SUBSIDY_FILE',
  DELETE_DRIVER_SUBSIDY_FILE_SUCCESS: 'DELETE_DRIVER_SUBSIDY_FILE_SUCCESS',
  DELETE_DRIVER_SUBSIDY_FILE_FAIL: 'DELETE_DRIVER_SUBSIDY_FILE_FAIL',
}
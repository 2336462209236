import { 
	USER_LOGIN, 
	USER_LOGIN_FAIL, 
	USER_LOGIN_SUCCESS, 
	USER_LOGOUT,
	SERVICE_POINT_USER_LOGIN,
	SERVICE_POINT_USER_LOGIN_SUCCESS,
	SERVICE_POINT_USER_LOGIN_FAIL,
	CUSTOMER_LOGIN, 
    CUSTOMER_LOGIN_SUCCESS, 
    CUSTOMER_LOGIN_FAIL
} from "../actions/types"
import { setUserPreference } from "../utilities/common"

const initialState = {
	loading: false,
	error: false,
	isLoggedIn: false,
    userLogin: {},
	servicePointLoginLoading: false,
	servicePointLoginError: false,
	servicePointIsLoggedIn: false,
	servicePointLogin: {},
	customerLoginLoading: false,
	customerLoginError: false,
	customerLoginIsLoggedIn: false,
	customerLogin: {}
}

function userReducer(state = initialState, action) {
	switch(action.type){
		case SERVICE_POINT_USER_LOGIN:
			return {
				...state,
				servicePointLoginLoading: true,
				servicePointLoginError: false
			}
		case SERVICE_POINT_USER_LOGIN_SUCCESS:
			return {
				...state,
				servicePointLoginLoading: false,
				servicePointIsLoggedIn: true,
				servicePointLogin: action.payload
			}
		case SERVICE_POINT_USER_LOGIN_FAIL:
			return {
				...state,
				servicePointLoginLoading: false,
				servicePointLoginError: action.payload
			}
		case USER_LOGIN:
			return {
				...state,
				loading: true,
				error: false
			}
		case USER_LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				isLoggedIn: true,
				userLogin: action.payload
			}
		case USER_LOGIN_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload
			}
		case USER_LOGOUT: 
			setUserPreference(null)
			return {
				...state,
				isLoggedIn: false
			}
		case CUSTOMER_LOGIN:
			return {
				...state,
				customerLoginLoading: true,
				customerLoginError: false
			}
		case CUSTOMER_LOGIN_SUCCESS:
			return {
				...state,
				customerLoginLoading: false,
				customerLoginIsLoggedIn: true,
				customerLogin: action.payload
			}
		case CUSTOMER_LOGIN_FAIL:
			return {
				...state,
				customerLoginLoading: false,
				customerLoginError: action.payload
			}
		default: {
			return state 
		}
	}
}

export default userReducer
import { Button, Card, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Typography, Box } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { generateInvoiceNumber } from '../../actions/billingManangementActions';
import { getCustomers } from '../../actions/storeActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FullscreenLoading from "../../components/FullscreenLoading";

const DEFAULT_OPTION = { id: 0, alias: 'Other' };
const filterOptions = (options, state) => {
  const results = createFilterOptions()(options, state);
  results.unshift(DEFAULT_OPTION);
  return results;
};

export default function InvoiceNumberGenerator() {
  const dispatch = useDispatch();
  const { t } = useTranslation('billingManagement');
  const [invoiceTo, setInvoiceTo] = useState(null);
  const [description, setDescription] = useState("");
  const [generateInvoiceResult, setgenerateInvoiceResult] = useState("");
  const [generateResultDialog, setGenerateResultDialog] = useState(false);
  const [customers, setCustomersList] = useState([]);
  const [otherCustomer, setOtherCustomer] = useState("");
  const [loading, setLoading] = useState(false)

  async function generateInvoice() {
    setLoading(true);
    var data = {
      operator: window.localStorage.getItem('username'),
      description: description
    };

    if (invoiceTo && invoiceTo.id !== 0) {
      data.customer_id = invoiceTo.id;
    } else {
      data.custom_company = otherCustomer;
    }

    await dispatch(generateInvoiceNumber(data))
      .then((res) => {
        setgenerateInvoiceResult(res);
        setGenerateResultDialog(true);
      })
      .catch((err) => {
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
      })

    setLoading(false);
  }

  function copyInvoiceNumber() {
    navigator.clipboard.writeText(generateInvoiceResult);
    dispatch(showAlertSnackbar({ message: "Copied to Clipboard!", type: 'success' }));
  }

  useEffect(() => {
    dispatch(getCustomers())
      .then((res) => {
        setCustomersList(res);
      })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'Get Customer failed, please reload page', type: 'error' }));
      })
  }, [dispatch]);

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        {t('invoice_number_generator')}
      </Typography>

      <Card variant='outlined' sx={{ padding: 4, maxWidth: 'sm', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant='h5' marginBottom={3}>
          {t('generate_invoice_number')}
        </Typography>
        <Typography marginBottom={3}>
          {t('operator')}:
          <Typography component='span' color='text.secondary' marginLeft={1}>
            {window.localStorage.getItem('username')}
          </Typography>
        </Typography>
        <Typography marginBottom={3}>
          {t('generate_inovice_prompt')}
        </Typography>

        <Box display='flex' flexDirection='row' width='100%' marginBottom={3}>
          <Autocomplete
            value={invoiceTo}
            onChange={(event, value) => {
              setInvoiceTo(value);
              setOtherCustomer("");
            }}
            variant="outlined"
            getOptionSelected={(option, value) => option.id === value.id}
            filterOptions={filterOptions}
            options={customers}
            getOptionLabel={(option) => (option ? option.id + ":" + option.alias : '')}
            renderInput={(params) => (
              <TextField {...params} label={t('invoice_to')} variant="outlined" InputLabelProps={{ shrink: true }} required />
            )}
            limitTags={1}
            sx={{ width: '50%', marginRight: 2 }}
          />
          {
            (invoiceTo && !invoiceTo.id !== 0) &&
            <TextField
              hidden
              required
              label={t('other_customer')}
              value={otherCustomer}
              onChange={(e) => setOtherCustomer(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
            />
          }
        </Box>
        <TextField
          multiline
          fullWidth
          minRows={6}
          placeholder={t('description_prompt')}
          label={t('description')}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: 3 }}
        />
        <Button variant="contained" disabled={invoiceTo === null || (invoiceTo?.id === 0 && !otherCustomer)} onClick={() => generateInvoice()}>
          {t('generate')}
        </Button>
      </Card>

      <Dialog maxWidth='sm' fullWidth={true} open={generateResultDialog} onClose={() => setGenerateResultDialog(false)}>
        <DialogTitle>
          {t('generate_result')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography marginBottom={2}>
            {t('operator')}:
            <Typography component='span' color='text.secondary' marginLeft={1}>
              {window.localStorage.getItem('username')}
            </Typography>
          </Typography>

          <Typography marginBottom={2}>
            {t('invoice_to')}:
            <Typography component='span' color='text.secondary' marginLeft={1}>
              {(invoiceTo && invoiceTo.id !== 0) ? invoiceTo?.id + "." + invoiceTo?.alias : otherCustomer}
            </Typography>
          </Typography>

          <Typography marginBottom={2}>
            {t('description')}:
            <Typography component='span' color='text.secondary' marginLeft={1}>
              {description}
            </Typography>
          </Typography>

          <Box
            onClick={() => copyInvoiceNumber()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#EDF6FF',
              padding: 2,
              cursor: 'pointer'
            }}>
            <Typography><b>{t('invoice_number')}: </b></Typography>
            <Typography marginX={2}>{generateInvoiceResult}</Typography>
            <ContentCopyIcon color='info' fontSize='small' />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setGenerateResultDialog(false)}>{t('ok')}</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </div>

  );
}
export const execute = (dispatch) => async (
  call,
  { type, success, fail } = {},
  {
    doIfSuccess = () => {},
    doIfFail = () => {}
  } = {},
) => {
  try {
    if (type) dispatch({ type: type });
    const { data } = await call;

    if (success) dispatch({ type: success, payload: data.data });
    doIfSuccess(data);

    return data.data;
  } catch (error) {
    if (fail) dispatch({ type: fail, error });
    doIfFail(error);

    throw error;
  }
};
import { IconButton, TextField, Typography, Card, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function PostalOutletOrderSearch(props) {
  const { trackingNo, setTrackingNo, fromDate, setFromDate, toDate, setToDate, handleSearch } = props;

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Orders
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <TextField
            label="Tracking Number"
            id="postaloutlet_orders_search_tno_input"
            InputLabelProps={{ shrink: true }}
            value={trackingNo}
            onChange={(e) => setTrackingNo(e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Order Created From"
            type="date"
            id="postaloutlet_orders_search_fromtime_input"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <TextField
            label="Order Created To"
            type="date"
            id="postaloutlet_orders_search_endtime_input"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <IconButton onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>
    </div >
  );
}
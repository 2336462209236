import React from 'react';
import dayjs from 'dayjs';
import { Container, Grid, Typography, Paper } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar';

const WelcomePage = () => {
  const today = dayjs()

  return (
    <Container display='flex' sx={{ overflow: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item xs={6} sx={{ minWidth: 700 }}>
          <Paper
            elevation={0}
            sx={{
              padding: 3,
              marginBottom: 2,
              backgroundImage: `url('/images/main_hp2.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
            <Typography variant='subtitle1'>
              Good Day!
            </Typography>
            <Typography variant='h5'>
              Start your day feeling calm and in control.
            </Typography>
            <Typography variant={'body1'} style={{ margin: '18px 0' }}>
              Get ready to streamline your shipping processes and gain full control over your shipments.
              Stay informed, stay organized, and enjoy the efficiency and convenience of our comprehensive shipping services.
            </Typography>
          </Paper>
          <Paper elevation={0} sx={{ padding: 2, pointerEvents: 'none', cursor: 'default' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} sx={{ paddingTop: 2, borderRight: '1px solid #ccc' }}>
                  <DateCalendar
                    defaultValue={today}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingTop: 3 }}>
                  <MonthCalendar
                    defaultValue={today}
                    sx={{
                      '.MuiPickersMonth-monthButton': {
                        fontSize: '18px',
                        fontWeight: 400
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <img src="/images/main_hp1.png" alt="Welcome Pic" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WelcomePage;

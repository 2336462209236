import { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem } from "@mui/material";
import { getBranchList } from "../../actions/commonActions";
import { useDispatch } from 'react-redux';

export default function ServicePointWarehouse({ onChangeWarehouse }) {
  const [warehouse, setWarehouse] = useState(window.localStorage.getItem('warehouse'));
  const [warehouseList, setWarehouseList] = useState([]);
  const dispatch = useDispatch()

  const changeWarehouse = (e) => {
    const id = e.target.value;
    setWarehouse(id);
    window.localStorage.setItem('warehouse', id);
    onChangeWarehouse(id)
  }

  useEffect(() => {
    dispatch(getBranchList())
      .then(res => setWarehouseList(res))
  }, [dispatch])

  return (
    <FormControl variant="outlined" sx={{ width: '200px' }}>
      <Select
        value={warehouse}
        onChange={changeWarehouse}
        size='small'>
        {warehouseList.map((value) => (
          <MenuItem value={value.id} key={value.id}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
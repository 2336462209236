import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import QuickQuote from './QuickQuote';
import NewOrder from './NewOrder';

export default function PostalOutletPlaceOrder() {
  const { t } = useTranslation('postalOutlet');

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        {t('place_order')}
      </Typography>
      <Box display='flex'>
        <QuickQuote/>
        <NewOrder/>
      </Box>
    </div >
  );
}
import { ErrorBoundary as Component } from 'react-error-boundary'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

function Fallback({ error }) {
	return (
		<Alert severity='error'>
			<AlertTitle sx={{ fontWeight: 600 }}>Sorry, something went wrong</AlertTitle>
			{error.message}
		</Alert>
	)
}

export default function ErrorBoundary(props) {
	return (
		<Component FallbackComponent={Fallback}>
			{props.children}
		</Component>
	)
}
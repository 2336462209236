import { Button, Typography, Card, Box } from '@mui/material';
import { useState } from 'react';
import PrealertStatisticSearchDate from "./PrealertStatisticSearchDate";
import * as XLSX from 'xlsx';

function PrealertReport() {
  const [mawbData, setMawbData] = useState([]);
  const [bagData, setBagData] = useState([]);
  const [loading, setLoading] = useState(false);

  function downloadMAWB() {
    const workbook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(mawbData);
    workbook.SheetNames.push("mawb_statistics");
    workbook.Sheets["mawb_statistics"] = workSheet;
    XLSX.writeFile(workbook, "mawb_statistics.xlsx");
  }

  function downloadBag() {
    const workbook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(bagData);
    workbook.SheetNames.push("bag_statistics");
    workbook.Sheets["bag_statistics"] = workSheet;
    XLSX.writeFile(workbook, "bag_statistics.xlsx");
  }

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Pre-alert Report
      </Typography>
      <Card variant='outlined' sx={{ padding: 3 }}>
        <Typography variant='subtitle2'>
          MAWB Statistics
        </Typography>
        <Box display='flex' marginY={3}>
          <PrealertStatisticSearchDate type='MAWB' setData={setMawbData} setLoading={setLoading}/>
          <Button
            color="primary"
            variant="contained"
            id={"prealertreport_mawb_button"}
            onClick={downloadMAWB}
            sx={{ marginLeft: 2 }}
            disabled={loading || mawbData.length === 0}>
            {'Download'}
          </Button>
        </Box>
        <Typography variant='subtitle2'>
          Bag Statistics
        </Typography>
        <Box display='flex' marginY={3}>
          <PrealertStatisticSearchDate type='BAG' setData={setBagData} setLoading={setLoading}/>
          <Button
            color="primary"
            variant="contained"
            id={"prealertreport_bag_button"}
            onClick={downloadBag}
            sx={{ marginLeft: 2 }}
            disabled={loading || bagData.length === 0}>
            {'Download'}
          </Button>
        </Box>
      </Card>
    </div>
  );
}

export default PrealertReport;
import { CREATE_ACCOUNT as type } from "../actions/types"

const initialState = {
  loading: false,
  error: false,
  customers: [],
  customerError: false
}

function managementReducer(state=initialState, action){
  switch(action.type){
    case type.GET_CUSTOMER:
      return {
        ...state,
        loading: true
      }
    case type.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload
      }
    case type.GET_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        customerError: action.payload
      }
    default:
      return state;
  }
}

export default managementReducer
import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from './pages/Login/Login';
import Auth from './Auth';
import Home from './pages/Home/Home';
import LocalDelivery from './pages/LocalDelivery/LocalDelivery';
import ServicePointManagement from './pages/ServicePoint/ServicePointManagement';
import ServicePointAdd from './pages/ServicePoint/ServicePointAdd';
import ServicePointEdit from './pages/ServicePoint/ServicePointEdit';
import ServicePointPackagesStatistics from './pages/ServicePoint/ServicePointPackagesStatistics';
import Sandbox from './pages/Sandbox/Sandbox';
import T4A from './pages/T4A/T4A';
import PrealertReport from './pages/PrealertStatistic/PrealertReport';
import PrealertSearchKeyword from './pages/PrealertStatistic/PrealertSearchKeyword';
import PrealertWrongAreaKeyword from './pages/PrealertStatistic/PrealertWrongAreaKeyword';
import PrealertPartnerVolume from './pages/PrealertStatistic/PrealertPartnerVolume';
import PostalOutletPlaceOrder from './pages/PostalOutlet/PostalOutletPlaceOrder';
import PostalOutletOrders from './pages/PostalOutlet/PostalOutletOrders';
import PartnerManagement from './pages/Organization/PartnerManagement';
import CustomerManagement from './pages/Organization/CustomerManagement';
import UserManagement from './pages/UserManagement/UserManagement';
import DeliveryCapacityDisplay from './pages/DeliveryCapacity/DeliveryCapacityDisplay';
import DeliveryCapacityUpdate from './pages/DeliveryCapacity/DeliveryCapacityUpdate';
import DeliveryThresholdUpdate from './pages/DeliveryCapacity/DeliveryThresholdUpdate';
import FaultyPackage from './pages/FaultyPackage/FaultyPackage';
import GVFaultyPackage from './pages/GVFaultyPackage/GVFaultyPackage';
import OrderReplenishment from './pages/GVOrderManagement/OrderReplenishment';
import BillingManagement from './pages/BillingAndInvoice/BillingManagement';
import BillingSupplementary from './pages/BillingAndInvoice/BillingSupplementary';
import InvoiceManagement from './pages/BillingAndInvoice/InvoiceManagement';
import InvoiceNumberGenerator from './pages/BillingAndInvoice/InvoiceNumberGenerator';
import RateCardsManagement from './pages/BillingAndInvoice/RateCardsManagement';
import BlockedAddress from './pages/AddressManagement/BlockedAddress';
import PaymentAdjustments from './pages/FleetSettlement/PaymentAdjustments';

export const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/',
    exact: true,
    render: () => {
      return <Redirect to={'/login'}></Redirect>
    }
  },
  {
    component: Auth,
    routes: [
      {
        path: '/home',
        component: Home
      },
      {
        path: '/local-delivery',
        component: LocalDelivery
      },
      {
        path: '/sandbox',
        component: Sandbox
      },
      {
        path: '/t4a',
        component: T4A
      },
      {
        path: '/service-point-list',
        component: ServicePointManagement
      },
      {
        path: '/service-point-add',
        component: ServicePointAdd
      },
      {
        path: '/service-point-edit',
        component: ServicePointEdit
      },
      {
        path: '/service-point-package-statistics',
        component: ServicePointPackagesStatistics
      },
      {
        path: '/prealert-reports',
        component: PrealertReport
      },
      {
        path: '/prealert-failed-imports',
        component: PrealertSearchKeyword
      },
      {
        path: '/prealert-wrong-dest-airport',
        component: PrealertWrongAreaKeyword
      },
      {
        path: '/prealert-partner-incoming-volume',
        component: PrealertPartnerVolume
      },
      {
        path: '/postal-outlet-place-order',
        component: PostalOutletPlaceOrder
      },
      {
        path: '/postal-outlet-orders',
        component: PostalOutletOrders
      },
      {
        path: '/organization-partner',
        component: PartnerManagement
      },
      {
        path: '/organization-customer',
        component: CustomerManagement
      },
      {
        path: '/user-management',
        component: UserManagement
      },
      {
        path: '/faulty-package',
        component: FaultyPackage
      },
      {
        path: '/gv-faulty-package',
        component: GVFaultyPackage
      },
      {
        path: '/gv-order-replenishment',
        component: OrderReplenishment
      },
      {
        path: '/delivery-capacity-overview',
        component: DeliveryCapacityDisplay
      },
      {
        path: '/delivery-capacity-update-capacity',
        component: DeliveryCapacityUpdate
      },
      {
        path: '/delivery-capacity-update-threshold',
        component: DeliveryThresholdUpdate
      },
      {
        path: '/billing-management',
        component: BillingManagement
      },
      {
        path: '/billing-supplementary',
        component: BillingSupplementary
      },
      {
        path: '/billing-invoice-management',
        component: InvoiceManagement
      },
      {
        path: '/billing-invoice-number-generator',
        component: InvoiceNumberGenerator
      },
      {
        path: '/billing-rate-cards-management',
        component: RateCardsManagement
      },
      {
        path: '/address-blocked',
        component: BlockedAddress
      },
      {
        path: '/fleet-settlement-payment-adjustments',
        component: PaymentAdjustments
      }
    ]
  }
]
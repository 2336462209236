import { useAlert } from "../utilities/hooks";
import Dialog from "./Dialog";

/*
    A context based dialog to display messages to users
    USAGE: 
        1. Add useAlert hook to any desired page
        2. Add title, message and additional params to customize the dialog via using addAlert()
        3. [OPTIONAL] use addActionButton to add additional buttons with or without functions
        4. Use showAlert() to display the dialog
    LIMITATIONS:
        Only one dialog can appear at a time
*/

function Alert() {
    const context = useAlert();

    return (
        <Dialog
            title={context.title}
            message={context.message}
            open={context.open}
            onClose={()=>context.hideAlert()}
            buttons={context.buttons}
            fullWidth
        >
        </Dialog>
    )
}

export default Alert;
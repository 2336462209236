import { shangjiaServerInstance } from "../utilities/instances";
import { GET_BRANCH_LIST, GET_BRANCH_LIST_SUCCESS, GET_BRANCH_LIST_FAIL, GET_S3_FILE, GET_S3_FILE_SUCCESS, GET_S3_FILE_FAIL } from "../actions/types"
import { getUserPreference } from "../utilities/common";
import axios from "axios";

export function getBranchList() {
  return function (dispatch) {
    dispatch({ type: GET_BRANCH_LIST })
    return shangjiaServerInstance.get("/business/getbranchlist")
      .then(({ data }) => {
        dispatch({
          type: GET_BRANCH_LIST_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_BRANCH_LIST_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getS3File(path) {
  return function (dispatch) {
    dispatch({ type: GET_S3_FILE })
    return axios.post(`${process.env.REACT_APP_SJ_SERVER_URL}/orders/downloadprms3file`, { file_path: path }, {
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(({ data }) => {
        dispatch({
          type: GET_S3_FILE_SUCCESS,
          payload: data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: GET_S3_FILE_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
import Button from '@mui/material/Button'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

export default function DownloadButton({ onClick, href, download, disabled, children }) {
  return (
    <Button
      size='small'
      endIcon={<SaveAltIcon />}
      style={{
        textAlign: 'center',
        fontSize: '1rem',
        padding: 0,
        lineHeight: 1.5,
        textTransform: 'none',
        color: '#2196F3'
      }}
      onClick={onClick}
      href={href}
      download={download}
      disabled={disabled}>
      {children}
    </Button>
  )
}
import {
    GET_API_DESCRIPTION,
    GET_API_DESCRIPTION_SUCCESS,
    GET_API_DESCRIPTION_FAIL,
    GET_CUSTOMER_TOKEN,
    GET_CUSTOMER_TOKEN_SUCCESS,
    GET_CUSTOMER_TOKEN_FAIL,
    CREATE_BUSINESS_ORDER,
    CREATE_BUSINESS_ORDER_SUCCESS,
    CREATE_BUSINESS_ORDER_FAIL,
    TRACKING_UNIUNI,
    TRACKING_UNIUNI_SUCCESS,
    TRACKING_UNIUNI_FAIL
} from "../actions/types"

const initialState = {
	getApiDescriptionLoading: false,
	getApiDescriptionError: false,
	getApiDescription: {},
    getCustomerTokenLoading: false,
	getCustomerTokenError: false,
	getCustomerToken: [],
    createBusinessOrderLoading: false,
	createBusinessOrderError: false,
	createBusinessOrder: [],
    trackingUniuniLoading: false,
	trackingUniuniError: false,
	trackingUniuni: [],
}

function sandboxReducer(state=initialState, action){
    switch(action.type){
        case GET_API_DESCRIPTION:
			return {
				...state,
				getApiDescriptionLoading: true,
				getApiDescriptionError: false
			}
		case GET_API_DESCRIPTION_SUCCESS:
			return {
				...state,
				getApiDescriptionLoading: false,
				getApiDescription: action.payload
			}
		case GET_API_DESCRIPTION_FAIL:
			return {
				...state,
				getApiDescriptionLoading: false,
				getApiDescriptionError: action.payload
			}
        case GET_CUSTOMER_TOKEN:
			return {
				...state,
				getCustomerTokenLoading: true,
				getCustomerTokenError: false
			}
		case GET_CUSTOMER_TOKEN_SUCCESS:
			return {
				...state,
				getCustomerTokenLoading: false,
				getCustomerToken: action.payload
			}
		case GET_CUSTOMER_TOKEN_FAIL:
			return {
				...state,
				getCustomerTokenLoading: false,
				getCustomerTokenError: action.payload
			}
        case CREATE_BUSINESS_ORDER:
            return {
                ...state,
                createBusinessOrderLoading: true,
                createBusinessOrderError: false
            }
        case CREATE_BUSINESS_ORDER_SUCCESS:
            return {
                ...state,
                createBusinessOrderLoading: false,
                createBusinessOrder: action.payload
            }
        case CREATE_BUSINESS_ORDER_FAIL:
            return {
                ...state,
                createBusinessOrderLoading: false,
                createBusinessOrderError: action.payload
            }
        case TRACKING_UNIUNI:
            return {
                ...state,
                trackingUniuniLoading: true,
                trackingUniuniError: false
            }
        case TRACKING_UNIUNI_SUCCESS:
            return {
                ...state,
                trackingUniuniLoading: false,
                trackingUniuni: action.payload
            }
        case TRACKING_UNIUNI_FAIL:
            return {
                ...state,
                trackingUniuniLoading: false,
                trackingUniuniError: action.payload
            }
        default: 
            return state;
    }
}

export default sandboxReducer
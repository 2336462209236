import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, Card, Box, Stack, FormControlLabel, Radio, Button, Typography, RadioGroup, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { checkServiceAvailability, estimateShipping } from '../../actions/sandboxActions';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { showAlertSnackbar } from "../../reducers/sliceReducer";

export default function QuickQuote() {
  const dispatch = useDispatch();
  const { t } = useTranslation('postalOutlet');
  const [price, setPrice] = useState("$");
  const [eta, setEta] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weightUnit, toggleWeightUnit] = useState('lb');
  const [dimUnit, toggleDimUnit] = useState('in');
  const [unitType, setUnitType] = useState('imperial');
  const [postalCodeError, setError] = useState(false);
  const [postalCodeValidated, setValidated] = useState(false);
  const [errorMessage, setErroMessage] = useState("");
  const [submitEnabled, setEnabled] = useState(false);

  const clearForm = (e) => {
    e.preventDefault();
    setpostalCode("");
    setWeight('');
    setLength("");
    setWidth("");
    setHeight("");
    setErroMessage("");
    setError(false);
    setEnabled(false);
    setValidated(false);
    setPrice("$");
    setEta("");
  }

  useEffect(() => {
    let enable = false;
    if (postalCodeValidated && !postalCodeError && weight && length && height && width) {
      if (!isNaN(weight) && !isNaN(length) && !isNaN(height) && !isNaN(width))
        enable = true;
    }
    setEnabled(enable);
  }, [postalCodeValidated, postalCodeError, weight, length, height, width]);

  const handleUnits = (e) => {
    if (e.target.value === 'imperial') {
      setUnitType(e.target.value);
      toggleWeightUnit('lb');
      toggleDimUnit('in');
    }
    if (e.target.value === 'metric') {
      setUnitType(e.target.value);
      toggleWeightUnit('kg');
      toggleDimUnit('cm');
    }
  }

  function validatePostalCode() {
    dispatch(checkServiceAvailability({ "postal_code": postalCode }))
      .then((res) => {
        setValidated(true);
        setError(false);
        setErroMessage("");
      })
      .catch((err) => {
        setValidated(true);
        setError(true);
        setErroMessage("This area is not in our service");
      })
  }

  function getPrice() {
    let params = {
      "start_postal_code": window.localStorage.getItem("start_postal_code"),
      "postal_code": postalCode,
    };
    if (dimUnit === 'in') {
      params["length"] = length * 2.54;
      params["width"] = width * 2.54;
      params["height"] = height * 2.54;
      params["weight"] = weight / 2.205;
    } else {
      params["length"] = length;
      params["width"] = width;
      params["height"] = height;
      params["weight"] = weight;
    }
    dispatch(estimateShipping(params))
      .then((res) => {
        setPrice("$" + res.data.totalAfterTax);
        setEta("ETA In " + res.data.eta + " days");
      })
      .catch((err) => {
        setPrice("$");
        setEta("");
        dispatch(showAlertSnackbar({ type: "error", message: err.ret_msg }));
      })
  }

  return (
    <Card variant='outlined' sx={{ width: '20%', marginRight: 2 }}>
      <Box height='64px' display='flex' alignItems='center' padding={2}>
        <Typography variant='subtitle2'>
          {t('quick_quote')}
        </Typography>
      </Box>
      <Box sx={{ padding: 1, backgroundColor: "#fcf4ed" }}>
        <Typography variant='h3' color='#ff9d00' textAlign='center'>
          {price}
        </Typography>
        <Typography color='text.secondary' textAlign='center'>
          {eta}
        </Typography>
      </Box>
      <Box textAlign='center'>
        <Typography variant='caption' color='text.disabled' lineHeight='10px'>
          {t('quote_message')}
        </Typography>
      </Box>
      <Stack padding={2} spacing={2}>
        <TextField
          error={postalCodeError}
          helperText={errorMessage}
          label={t('postal_code')}
          variant="outlined"
          value={postalCode}
          onChange={(e) => { setpostalCode(e.target.value); setError(false); setErroMessage(""); setValidated(false); setPrice("$"); setEta(""); }}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton onClick={validatePostalCode} edge="end">
                  {postalCodeValidated ?
                    (postalCodeError ? <CancelIcon color='error' /> :
                      <CheckCircleIcon color='success' />)
                    : <SearchIcon />}
                </IconButton>
              </InputAdornment>
          }}
        />
        <TextField
          label={t('parcel_weight') + "*"}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {weightUnit}
              </InputAdornment>
          }}
          variant="outlined"
          value={weight}
          onChange={(e) => { setWeight(e.target.value); setPrice("$"); setEta(""); }}
          error={weight !== "" && isNaN(weight)}
        />
        <TextField
          label={t('parcel_length') + "*"}
          variant="outlined"
          value={length}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {dimUnit}
              </InputAdornment>
          }}
          onChange={(e) => { setLength(e.target.value); setPrice("$"); setEta(""); }}
          error={length !== "" && isNaN(length)}
        />
        <TextField
          label={t('parcel_width') + "*"}
          variant="outlined"
          value={width}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {dimUnit}
              </InputAdornment>
          }}
          onChange={(e) => { setWidth(e.target.value); setPrice("$"); setEta(""); }}
          error={width !== "" && isNaN(width)}
        />
        <TextField
          label={t('parcel_height') + "*"}
          variant="outlined"
          value={height}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {dimUnit}
              </InputAdornment>
          }}
          onChange={(e) => { setHeight(e.target.value); setPrice("$"); setEta(""); }}
          error={height !== "" && isNaN(height)}
        />
      </Stack>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Typography marginRight={2}>{t('unit')}</Typography>
        <RadioGroup value={unitType} onChange={handleUnits} >
          <FormControlLabel
            value="imperial"
            control={<Radio />}
            label={<Typography variant='body2'>{t('imperial_units')}</Typography>}
          />
          <FormControlLabel
            value="metric"
            control={<Radio />}
            label={<Typography variant='body2'>{t('metric_system')}</Typography>}
          />
        </RadioGroup>
      </Box>
      <Box display='flex' justifyContent='space-evenly' margin={2}>
        <Button onClick={clearForm} >{t('clear')}</Button>
        <Button variant='contained' disabled={!submitEnabled} onClick={getPrice}>{t('quote')}</Button>
      </Box>
    </Card>
  );
}
import { Button, DialogTitle, DialogActions, Box, Grid, Typography, DialogContent, Divider } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { downloadInvoiceFiles } from '../../actions/billingManangementActions';
import { DataGrid } from '@mui/x-data-grid';
import FullscreenLoading from "../../components/FullscreenLoading";
import DownloadButton from '../../components/DownloadButton';

function GridItem({ title, value }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function getAction(fileType, filename, creditNum) {
    if (fileType === 'invoice') {
      return downloadInvoiceFiles({ file_name: filename, cr_num: creditNum });
    }
  }

  async function handleDownload(filename, creditNum, fileType) {
    try {
      setLoading(true);
      const action = getAction(fileType, filename, creditNum);
      await dispatch(action);
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={1} marginBottom={2}>
      <Grid item xs={5}>
        <Typography variant='body2' color='text.secondary'>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7} >
        {typeof value === 'string' ? (
          value
        ) : (
          value.text ? (
            <DownloadButton
              onClick={() => handleDownload(value.text, value.creditNum, value.type)}
              disabled={loading}>
              {value.text}
            </DownloadButton>
          ) : null
        )}
      </Grid>
    </Grid>
  );
}

const creditMemoDetailColumns = [
  {
    field: 'description',
    headerName: "Category",
    width: 180,
    sortable: false,
  },
  {
    field: 'ant_parcel_no',
    headerName: "Tracking No.",
    width: 280,
    sortable: false,
  },
  {
    field: 'fee_adjustment',
    headerName: "Adjusted Amount",
    width: 180,
    sortable: false,
  },
  {
    field: 'remark',
    headerName: "Comments",
    width: 300,
    sortable: false,
  }
]

export default function CreditMemoDetail(props) {
  const { setDialogOpen, data } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let creditMemoInfoL = [
    { title: 'Invoice Number:', value: data['creditMemoInfo']['cr_num'] ?? '' },
    { title: 'Invoice Creation Date:', value: data['creditMemoInfo']['create_at'] ?? '' },
    { title: 'Operator:', value: data['creditMemoInfo']['operator_name'] ?? '' },
  ];
  let creditMemoInfoR = [
    { title: 'Master Invoice Number:', value: data['creditMemoInfo']['invoice_num'] ?? '' },
    { title: 'Amount:', value: "$ " + data['creditMemoInfo']['total_adjusted_amount'] ?? '' },
    { title: 'Credit Memo:', value: { text: data['creditMemoInfo']['cr_invoice_file'], type: 'invoice', creditNum: data['creditMemoInfo']['cr_num'] } },
  ];

  async function downloadDetails(filename, creditNum) {
    try {
      setLoading(true);
      await dispatch(downloadInvoiceFiles({ file_name: filename, cr_num: creditNum }))
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DialogTitle>
        Credit Memo
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box padding={3}>
          <Typography marginBottom={2}>Credit Memo Information</Typography>
          <Grid container spacing={0}>
            {creditMemoInfoL.map((item, index) => (
              <GridItem key={index} title={item.title} value={item.value} />
            ))}
          </Grid>
          <Grid container spacing={0}>
            {creditMemoInfoR.map((item, index) => (
              <GridItem key={index} title={item.title} value={item.value} />
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box padding={3}>
          <Typography marginBottom={2}>Credit Memo Details</Typography>
          <Box marginBottom={2}>
            {data['creditMemoInfo']['cr_detail_file'] &&
              <DownloadButton onClick={() => downloadDetails(data['creditMemoInfo']['cr_detail_file'], data['creditMemoInfo']['cr_num'])}>
                {data['creditMemoInfo']['cr_detail_file']}
              </DownloadButton>
            }
          </Box>
          <DataGrid
            autoHeight
            rows={data['details'] ?? []}
            columns={creditMemoDetailColumns}
            checkboxSelection={false}
            getRowId={(row) => row.id}
            disableColumnMenu={true}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button autoFocus onClick={() => { setDialogOpen(false) }}>
          CLOSE
        </Button>
      </DialogActions>
      <FullscreenLoading open={loading} />
    </>
  );
}
import { useState, useCallback, useRef, createContext } from 'react';
import { useTranslation } from 'react-i18next';

export const alertType = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info'
}

const defaultValue = {
  type: null,
  title: null,
  message: null,
  open: false,
  buttons: [{
    text: "OK",
    action: () => { },
    color: 'default'
  }],
  showAlert: () => { },
  hideAlert: () => { }
}

export const AlertContext = createContext(defaultValue)

export const AlertContextProvider = ({ children }) => {
  const { t } = useTranslation('common')
  const [alert, setAlert] = useState(defaultValue)
  const alertObject = useRef(defaultValue)

  const hideAlert = () => {
    setAlert({
      ...alertObject.current,
      open: false
    }, setAlert(defaultValue))
    alertObject.current = defaultValue
  }

  const showAlert = () => {
    setAlert({
      ...alertObject.current,
      open: true
    })
  }

  /**
   * Add alert via a fullscreen dialog
   * @param {String} title Title of the alert 
   * @param {String} message Message of the alert
   * @param {String} [type] Type of alert (see alertType). Default: error
   * @param {String} [defaultButtonText] Text displays on the default button. Default: ok
   * @param {Function} [defaultButtonAction] Function when default button is clicked. Default: hide alert
   * @param {String} [defaultButtonColor] Color of the default button text. Default: default
  */
  const addAlertDialog = (title, message, type = alertType.ERROR,
    defaultButtonText = t('ok'), defaultButtonAction = hideAlert, defaultButtonColor = 'default') => {
    alertObject.current = {
      ...alertObject.current,
      title,
      message,
      type,
      buttons: [{
        text: defaultButtonText,
        action: defaultButtonAction,
        color: defaultButtonColor
      }]
    }
  }

  /**
   * Add additional buttons to the alert dialog
   * @param {String} text Text displays on the default button.
   * @param {Function} [action] Function when default button is clicked. Default: nothing
   * @param {String} [color] Color of the button text. Default: default
  */
  const addActionButton = (text, action = () => { }, color = 'default') => {
    let buttons = alertObject.current.buttons
    buttons.push({ text, action, color })
    alertObject.current = {
      ...alertObject.current,
      buttons
    }
  }

  const contextValue = {
    ...alert,
    addAlertDialog: useCallback(addAlertDialog, [t]),
    addActionButton: useCallback(addActionButton, []),
    showAlert: useCallback(showAlert, []),
    hideAlert: useCallback(hideAlert, [])
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  )
}
import { useDispatch } from 'react-redux';
import { Typography, TextField, Button, MenuItem, DialogTitle, DialogContent, DialogActions, Dialog, Alert, Stack, Card, Box } from '@mui/material';
import React, { useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import { downloadBlockedAddress, getBlockedAddress, uploadBlockedAddress, deleteMultipleRecords } from "../../actions/addressManagementActions";
import DragDropFileUpload from "../../components/DragDropFileUpload";
import DocumentDetailDisplay from "../../components/DocumentDetailDisplay";
import { exportHelper } from "../../utilities/common";

const ALERT = {
  SEARCH_SUCCESS: "FETCH DATA SUCCESS",
  SEARCH_FAILED: "FETCH DATA FAILED",
  UPLOAD_SUCCESS: "UPLOAD SUCCESS",
  UPLOAD_FAILED: "UPLOAD FAILED",
  DOWNLOAD_SUCCESS: "DOWNLOAD SUCCESS",
  DOWNLOAD_FAILED: "DOWNLOAD FAILED",
  DELETE_SUCCESS: "DELETE SUCCESS ",
  DELETE_FAILED: "DELETE FAILED",
};

// const BLOCKED_ADDRESS = {
//   101: 'usps',
//   102: 'unreachable',
//   103: 'mountain',
//   104: 'military',
//   105: '3rd Party Post Office',
// };
//
// const INIT = {
//   address: '',
//   zipcode: '',
//   type: '',
//   notes: '',
// };

function BlockedAddress() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [type, setType] = useState(0);
  const [file, setFile] = useState('');
  const [uploadFailedMsg, setUploadFailedMsg] = useState([]);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadFailedDialogOpen, setUploadFailedDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const typeList = [
    {
      name: "All",
      type: 0
    },
    {
      name: "US Post Office",
      type: 101
    },
    {
      name: "Unreachable",
      type: 102
    },
    {
      name: "Mountain Area",
      type: 103
    },
    {
      name: "Military Area",
      type: 104
    },
    {
      name: "3rd Party Post Office",
      type: 105
    }
  ]

  const columns = [
    {
      field: 'address',
      headerName: 'Address',
      flex: 1
    },
    {
      field: 'zipcode',
      headerName: 'Zip Code',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1
    },
  ];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row) => ({
      id: row.id,
      address: row.address,
      zipcode: row.zipcode,
      type: row.type,
      notes: row.notes,
    }));
    setRows(updatedRows);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const typeToUse = type === 0 ? '' : type;
      let res = await dispatch(getBlockedAddress(address, zipcode, typeToUse));
      handleUpdateRow(res);
      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      dispatch(showAlertSnackbar({ message: 'Please select a file.', type: 'error' }));
      return;
    }
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append('file', file);
      await dispatch(uploadBlockedAddress(formData));
      dispatch(showAlertSnackbar({ message: ALERT.UPLOAD_SUCCESS, type: 'success' }));
    } catch (e) {
      const errorMessage = Array.isArray(e.message?.errors)
        ? e.message.errors
        : [e.message?.errors || 'An unexpected error occurred'];

      setUploadFailedMsg(errorMessage);
      setUploadFailedDialogOpen(true);
      dispatch(showAlertSnackbar({ message: ALERT.UPLOAD_FAILED, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (selectedRows.length === 0) {
      dispatch(showAlertSnackbar({ message: 'Please select rows to be downloaded.', type: 'error' }));
      return;
    }
    setLoading(true);
    try {
      const params = {
        id: JSON.stringify(selectedRows)
      };
      const res = await downloadBlockedAddress(params);
      exportHelper(res, 'blocked_addresses.xlsx');
      dispatch(showAlertSnackbar({ message: ALERT.DOWNLOAD_SUCCESS, type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: ALERT.DOWNLOAD_FAILED, type: 'error' }));
    } finally {
      setSelectedRows([]);
      setLoading(false);
    }
  };

  const deleteMultiple = async () => {
    if (selectedRows.length === 0) {
      dispatch(showAlertSnackbar({ message: 'Please select rows to be deleted.', type: 'error' }));
      return;
    }
    if (window.confirm('Are you sure to delete the selected address?')) {
      setLoading(true);
      try {
        const params = {
          id: JSON.stringify(selectedRows)
        };
        dispatch(deleteMultipleRecords(params));
        dispatch(showAlertSnackbar({ message: ALERT.DELETE_SUCCESS, type: 'success' }));
        dispatch(handleSearch);
      } catch (e) {
        dispatch(showAlertSnackbar({ message: ALERT.DELETE_FAILED, type: 'error' }));
      } finally {
        setSelectedRows([]);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={2}>
        <Typography variant='subtitle1'>
          Blocked Address
        </Typography>

        <Button
          startIcon={<AddIcon />}
          onClick={() => setUploadDialogOpen(true)}
          variant='contained'>
          New Addresses
        </Button>

        <Dialog
          open={uploadDialogOpen}
          onClose={() => {
            setUploadDialogOpen(false);
            setFile('');
          }}
          maxWidth='sm'
          fullWidth>
          <DialogTitle>Upload New Addresses</DialogTitle>
          <DialogContent dividers>
            <DragDropFileUpload fileType={".xlsx"} onFileUpload={setFile} file={file} />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button
              autoFocus
              onClick={() => {
                setUploadDialogOpen(false);
                setFile('');
              }}>
              BACK
            </Button>
            <Button
              variant='contained'
              onClick={handleUpload}>
              UPLOAD
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={uploadFailedDialogOpen}
          onClose={() => {
            setUploadFailedDialogOpen(false);
            setUploadFailedMsg('');
          }}
          maxWidth='sm'
          fullWidth>
          <DialogTitle>Upload Failed</DialogTitle>
          <DialogContent dividers>
            <Alert severity='error' sx={{ marginBottom: 2 }}>
              Please correct the rows listed below and upload the adjustment file again
            </Alert>
            <DocumentDetailDisplay file={file} state={'Complete'} />
            <Typography component='ul' color='error' marginTop={2}>
              {Array.isArray(uploadFailedMsg) && uploadFailedMsg.map((msg, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>
                  {msg}
                </li>
              ))}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button
              autoFocus
              onClick={() => {
                setUploadFailedDialogOpen(false);
                setUploadFailedMsg('');
              }}>
              BACK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} sx={{ padding: 3 }}>
          <TextField
            id='address_text'
            label='Address'
            variant='outlined'
            onChange={e => setAddress(e.target.value)}
            onKeyDown={e => handleKeyPress(e)}
            value={address}
          />
          <TextField
            id='zipcode_text'
            label='Zip Code'
            variant='outlined'
            onChange={e => setZipcode(e.target.value)}
            onKeyDown={e => handleKeyPress(e)}
            value={zipcode}
          />
          <TextField
            select
            id='type_select'
            label="Type"
            value={type}
            onChange={e => setType(e.target.value)}>
            {typeList.map((type) => (
              <MenuItem value={type.type} key={type.type}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
          <IconButton
            id='search_button'
            size='large'
            onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Card variant='outlined'>
        <Box display='flex' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Address Library
          </Typography>
          <Stack direction='row' spacing={1}>
            <Button
              onClick={handleDownload}
              disabled={selectedRows.length === 0}
              variant='outlined'
              color='secondary'
              size='small'>
              DOWNLOAD SELECTED
            </Button>
            <Button
              onClick={deleteMultiple}
              disabled={selectedRows.length === 0}
              variant='outlined'
              color='error'
              size='small'>
              DELETE SELECTED
            </Button>
          </Stack>
        </Box>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          loading={loading}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={setSelectedRows}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>
    </div>
  );
}

export default BlockedAddress;
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Grid, Divider, Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAdjustFile, downloadAdjustFile, downloadPreauditFile, downloadPriceCardFile, searchSettlementProcedures, downloadAdjustTemplate, uploadAdjustFile, downloadQCPostAuditFile, qcApproval, getInvoice, resetSettlementStatus } from '../../actions/billingManangementActions';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import DownloadButton from '../../components/DownloadButton'
import DragDropFileUpload from '../../components/DragDropFileUpload'
import DocumentDetailDisplay from '../../components/DocumentDetailDisplay'
import FullscreenLoading from "../../components/FullscreenLoading";
import DeleteIcon from '@mui/icons-material/Delete';

function GridItem({ title, value, setDeleteAdjustSuccess }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function getAction(fileType, filename, billingNum) {
    if (fileType === 'preaudit')
      return downloadPreauditFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'price_card')
      return downloadPriceCardFile({ file_name: filename });
    if (fileType === 'adjust')
      return downloadAdjustFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'qcpostaudit')
      return downloadQCPostAuditFile({ file_name: filename, billing_num: billingNum });
    if (fileType === 'invoice')
      return getInvoice({ file_name: filename, billing_num: billingNum });
  }

  async function handleDownload(filename, billingNum, fileType) {
    try {
      setLoading(true);
      const action = getAction(fileType, filename, billingNum);
      if (fileType === 'invoice') {

        await dispatch(action)
          .then((res) => {
            const link = document.createElement('a');
            link.href = res;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(res);
            document.body.removeChild(link);

          })
          .catch((err) => {
            showAlertSnackbar({ message: err.message, type: 'error' })
          })
      } else {
        await dispatch(action);
      }
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteAdjustFile(billingNum) {
    try {
      setLoading(true);
      await dispatch(deleteAdjustFile({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'Adjust file deleted', type: 'success' }));
      setDeleteAdjustSuccess(true);
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={0} marginBottom={2}>
      <Grid item xs={5}>
        <Typography variant='body2' color='text.secondary'>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7} >
        {typeof value === 'string' ? (
          value
        ) : (
          value.text ? (
            <div>
              <DownloadButton
                onClick={() => handleDownload(value.text, value.billingNum, value.type)}
                disabled={loading}>
                {value.text}
              </DownloadButton>
              {value.type === 'adjust' && (
                <IconButton
                  onClick={() => handleDeleteAdjustFile(value.billingNum)}
                  sx={{ marginLeft: 2 }}
                  color='error'
                  size="small">
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          ) : null
        )}
      </Grid>
    </Grid>
  );
}

export default function BillingManagementSettlementDetail(props) {
  const dispatch = useDispatch();
  const { rowData, setDialogOpen, dialog, status, search } = props;
  const [loading, setLoading] = useState(false);
  const [preauditFile, setPreauditFile] = useState('');
  const [priceCardFile, setPriceCardFile] = useState('');
  const [preauditAnalysisFile, setPreauditAnalysisFile] = useState('');
  const [qcAdjustFile, setQcAdjustFile] = useState('');
  const [qcPostAuditFile, setQcPostAuditFile] = useState('');
  const [deleteAdjustSuccess, setDeleteAdjustSuccess] = useState(false);
  const [uploadAdjustment, setUploadOpen] = useState(false);
  const [uploadFailed, setUploadFailedOpen] = useState(false);
  const [failedMsg, setFailedMsg] = useState([]);
  const [qcApprovalClick, setQcApprovalClick] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [operator, setOperator] = useState('');
  const [resetOpen, setResetOpen] = useState(false);
  const [resetCompleted, setResetCompleted] = useState(false);

  const billingNum = rowData.billing_num;

  useEffect(() => {
    if (dialog || deleteAdjustSuccess || currentStatus || qcApprovalClick || resetCompleted) {
      setLoading(true);
      dispatch(searchSettlementProcedures({ billing_num: billingNum }))
        .then((res) => {
          if (res && res.length > 0) {
            const settlement = res[0];
            const foundStatus = status.find(item => item.code === settlement['settlement_status_code']);
            setOperator(settlement['operator_username'] ?? '')
            setCurrentStatus(foundStatus ? foundStatus.name : "Unknown");
            setPreauditFile(settlement['preaudit_file'] ?? '');
            setPriceCardFile(settlement['price_card_file'] ?? '');
            setPreauditAnalysisFile(settlement['preaudit_analysis_file'] ?? '');
            setQcAdjustFile(settlement['qc_adjust_file'] ?? '');
            setQcPostAuditFile(settlement['qc_postaudit_file'] ?? '');
          }
        })
        .catch((err) => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }));
        })
        .finally(() => {
          setLoading(false);
          setResetCompleted(false);
        });
    } else {
      setPreauditFile('');
      setPriceCardFile('');
      setPreauditAnalysisFile('');
      setQcAdjustFile('');
      setQcPostAuditFile('');
    }
  }, [dialog, status, billingNum, deleteAdjustSuccess, currentStatus, qcApprovalClick, resetCompleted, dispatch]);

  let basicInfoData = [
    { title: 'Billing ID:', value: rowData['billing_num'] ?? '' },
    { title: 'Billing Periods:', value: rowData['billing_start'] ?? '' },
    { title: 'Invoice Date:', value: rowData['invoice_ts'] ?? '' },
    { title: 'Invoice Number:', value: rowData['invoice_number'] ?? '' },
    { title: 'Operator:', value: operator },
  ];
  const auditContentsData = [
    { title: 'Price Card Version:', value: { text: priceCardFile, type: 'price_card' } },
    { title: 'Statement Quality:', value: { text: preauditAnalysisFile, type: 'preaudit', billingNum: rowData['billing_num'] } },
    { title: 'Adjustment File:', value: { text: qcAdjustFile, type: 'adjust', billingNum: rowData['billing_num'] } },
  ];
  const billedContentsData = [
    { title: 'Generated Invoice:', value: { text: 'Invoice_' + rowData['invoice_number'] + '.pdf', type: 'invoice', billingNum: rowData['billing_num'] } },
  ];

  const isPendingReconciliation = currentStatus === 'Pending for Reconciliation';
  const isBilled = currentStatus === 'Billed';
  const allowRest = currentStatus === 'QC Reconciled' || currentStatus === 'Billed';

  function handleDialogClose() {
    setDialogOpen(false);
    resetDialog();
    search();
  }

  function resetDialog() {
    setPreauditFile('');
    setPriceCardFile('');
    setPreauditAnalysisFile('');
    setQcAdjustFile('');
    setQcPostAuditFile('');
    setDeleteAdjustSuccess(false);
    setCurrentStatus('');
    setQcApprovalClick(false);
  }

  async function downloadTemplate() {
    try {
      setLoading(true);
      await dispatch(downloadAdjustTemplate());
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  async function handleUploadAdjustFile() {
    try {
      setLoading(true);
      setUploadOpen(false);
      setLoading(true);
      let formData = new FormData();
      formData.append('file', qcAdjustFile);
      formData.append('billing_num', billingNum);
      await dispatch(uploadAdjustFile(formData));
      setLoading(false);
      setDialogOpen(true);
    } catch (error) {
      console.log(error)
      setLoading(false);
      setFailedMsg(error.additional);
      setUploadFailedOpen(true);
    }
  }

  async function handleQcReconcile() {
    try {
      await dispatch(qcApproval({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'QC arrpoval start processing.', type: 'success' }));
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setQcApprovalClick(true);
    }
  }

  async function handleResetStatus() {
    try {
      await dispatch(resetSettlementStatus({ billing_num: billingNum }));
      dispatch(showAlertSnackbar({ message: 'Status reset success', type: 'success' }));
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }));
    } finally {
      setLoading(false);
      setResetOpen(false);
      setResetCompleted(true);
    }
  }

  return (
    <div>
      <FullscreenLoading open={loading} />
      <Dialog maxWidth='md' fullWidth open={dialog} onClose={handleDialogClose}>
        <DialogTitle>
          Billing Statement Detail
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <Box paddingY={2} paddingX={3}>
            Billing Status: <Typography component='span' color={isPendingReconciliation ? 'error' : undefined}>{currentStatus}</Typography>
            {allowRest && (
              <Button variant='outlined' size='small' color='error' sx={{ marginLeft: 1 }} onClick={() => { setResetOpen(true); }}>
                RESET STATUS
              </Button>
            )}
            <Dialog open={resetOpen}>
              <DialogTitle>
                Reset Status?
              </DialogTitle>
              <DialogContent dividers>
                <Typography color='error' marginBottom={2}>
                  Only authorized administrators can reset the status.
                </Typography>
                <Typography>
                  Are you sure you want to reset the billing status to "Pending for reconciliation" and delete all related invoice information? The billing statement will remain in its latest version.
                </Typography>
              </DialogContent>
              <DialogActions sx={{ padding: 3 }}>
                <Button autoFocus onClick={() => { setResetOpen(false) }}>
                  Cancel
                </Button>
                <Button variant='contained' onClick={handleResetStatus}>
                  YES
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Divider />
          <Box padding={3}>
            <Typography marginBottom={2}>Basic Information</Typography>
            <div style={{ display: 'none' }}>
              {
                isPendingReconciliation ?
                  basicInfoData.push({ title: 'Billing Statement:', value: { text: preauditFile, type: 'preaudit', billingNum: rowData['billing_num'] } })
                  :
                  basicInfoData.push({ title: 'Billing Statement:', value: { text: qcPostAuditFile, type: 'qcpostaudit', billingNum: rowData['billing_num'] } })

              }
            </div>
            <Grid container spacing={0}>
              {basicInfoData.map((item, index) => (
                <GridItem key={index} title={item.title} value={item.value} />
              ))}
            </Grid>
          </Box>
          {
            isPendingReconciliation &&
            <Box sx={{ backgroundColor: '#EDF6FF' }}>
              <Divider />
              <Box padding={3}>
                <Typography marginBottom={2}>Audit Contents</Typography>
                <Grid container spacing={0}>
                  {auditContentsData.map((item, index) => (
                    <GridItem key={index} title={item.title} value={item.value} setDeleteAdjustSuccess={setDeleteAdjustSuccess} />
                  ))}
                </Grid>
                <Typography variant='body2' marginY={1}>
                  Upload adjustment file if you want to make any changes.
                </Typography>
                <Button variant='outlined' color='secondary' size='small' onClick={() => { setDialogOpen(false); resetDialog(); setUploadOpen(true) }}>
                  UPLOAD ADJUSTMENT FILE
                </Button>
              </Box>
            </Box>
          }
          {
            isBilled &&
            <>
              <Divider />
              <Box padding={3}>
                <Typography marginBottom={2}>Invoice</Typography>
                <Grid container spacing={0}>
                  {billedContentsData.map((item, index) => (
                    <GridItem key={index} title={item.title} value={item.value} />
                  ))}
                </Grid>
              </Box>
            </>
          }
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          {
            isPendingReconciliation ?
              <>
                <Button autoFocus onClick={handleDialogClose}>
                  Cancel
                </Button>
                <Button variant='contained' onClick={handleQcReconcile}>
                  Reconcile
                </Button>
              </>
              :
              <Button autoFocus onClick={handleDialogClose}>
                Close
              </Button>
          }
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={'sm'} fullWidth open={uploadAdjustment}>
        <DialogTitle>
          Upload Adjustment File
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Please utilize the provided template for any required modifications
          </Typography>
          <DownloadButton onClick={() => downloadTemplate()}>
            {'template.xlsx'}
          </DownloadButton>
          <Box marginY={2}>
            <DragDropFileUpload fileType={'.xlsx'} onFileUpload={setQcAdjustFile} file={qcAdjustFile} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button onClick={() => { setUploadOpen(false); setDialogOpen(true) }}>
            Back
          </Button>
          <Button variant='contained' onClick={handleUploadAdjustFile}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={'sm'} fullWidth open={uploadFailed}>
        <DialogTitle>
          Upload Failed
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Please correct the rows listed below and upload the adjustment file again
          </Typography>
          <Box marginY={2}>
            <DocumentDetailDisplay file={qcAdjustFile} state={'Complete'} />
          </Box>
          <Typography component='ul' color='error'>
            {
              failedMsg && Array.isArray(failedMsg) && failedMsg.map((message, index) => {
                return (
                  <li key={index}>{message}</li>
                )
              })
            }
            {
              failedMsg && !Array.isArray(failedMsg) && Object.keys(failedMsg).map((category) => {
                const messages = failedMsg[category]
                return (
                  <li key={category}>
                    {category}:
                    <ul>
                      {messages.map(message => {
                        return (
                          <li key={message.row}>
                            Row {message.row}:
                            <ul>
                              {message.errors.map((error, index) => <li key={index}>{error}</li>)}
                            </ul>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })
            }
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => { setUploadFailedOpen(false); setDialogOpen(true) }}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
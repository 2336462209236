import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertSnackbar } from '../reducers/sliceReducer'

export default function SnackbarComponent(props) {
    const dispatch = useDispatch()
    const { alertObject, openAlertSnackbar } = useSelector(state => state.ui)

    return (
        <Snackbar
            anchorOrigin={alertObject.position}
            autoHideDuration={alertObject.message && alertObject.message.length * 100}
            open={openAlertSnackbar}
            onClose={handleClose}
            {...props.other}>
            <Alert severity={alertObject.type} onClose={handleClose}>
                {alertObject.title && <AlertTitle>{alertObject.title}</AlertTitle>}
                {alertObject.message}
            </Alert>
        </Snackbar>
    )

    function handleClose() {
        dispatch(hideAlertSnackbar())
    }
}
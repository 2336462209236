import { 
    GET_RECENT_BATCHLIST, 
    GET_RECENT_BATCHLIST_SUCCESS, 
    GET_RECENT_BATCHLIST_FAIL,
    GET_ORDERS_BY_BATCH_INTURN,
    GET_ORDERS_BY_BATCH_INTURN_SUCCESS,
    GET_ORDERS_BY_BATCH_INTURN_FAIL,
    GET_ORDER_PRINT_LABELS,
    GET_ORDER_PRINT_LABELS_SUCCESS,
    GET_ORDER_PRINT_LABELS_FAIL
} from "../actions/types"

const initialState = {
    getRecentBatchListLoading: false,
    getRecentBatchList: [],
    getRecentBatchListError: false,
    getOrdersByBatchInturnLoading: false,
    getOrdersByBatchInturn: [],
    getOrdersByBatchInturnError: false,
    getOrderPrintLabelsLoading: false,
    getOrderPrintLabels: [],
    getOrderPrintLabelsError: false,
}

export default function storeReducer(state = initialState, action){
    switch(action.type){
        case GET_RECENT_BATCHLIST:
            return {
                ...state,
                getRecentBatchListLoading: true,
            }
        case GET_RECENT_BATCHLIST_SUCCESS:
            return {
                ...state,
                getRecentBatchList: action.payload,
                getRecentBatchListLoading: false
            }
        case GET_RECENT_BATCHLIST_FAIL:
            return {
                ...state,
                getRecentBatchListLoading: false,
                getRecentBatchListError: action.payload
            }
        case GET_ORDERS_BY_BATCH_INTURN:
            return {
                ...state,
                getOrdersByBatchInturnLoading: true,
            }
        case GET_ORDERS_BY_BATCH_INTURN_SUCCESS:
            return {
                ...state,
                getOrdersByBatchInturn: action.payload,
                getOrdersByBatchInturnLoading: false
            }
        case GET_ORDERS_BY_BATCH_INTURN_FAIL:
            return {
                ...state,
                getOrdersByBatchInturnLoading: false,
                getOrdersByBatchInturnError: action.payload
            }
        case GET_ORDER_PRINT_LABELS:
            return {
                ...state,
                getOrderPrintLabelsLoading: true,
            }
        case GET_ORDER_PRINT_LABELS_SUCCESS:
            return {
                ...state,
                getOrderPrintLabels: action.payload,
                getOrderPrintLabelsLoading: false
            }
        case GET_ORDER_PRINT_LABELS_FAIL:
            return {
                ...state,
                getOrderPrintLabelsLoading: false,
                getOrderPrintLabelsError: action.payload
            }
        default:
            return state;
    }
}
import { Card, Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Typography, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { uploadPickupTranshipementFile, uploadClaimPackages } from '../../actions/billingManangementActions';
import DragDropFileUpload from '../../components/DragDropFileUpload';
import DocumentDetailDisplay from '../../components/DocumentDetailDisplay';
import FullscreenLoading from "../../components/FullscreenLoading";
import DownloadButton from '../../components/DownloadButton';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

const FILE_TYPES = [
  { id: 0, type: 'Transhipment/Pickup' },
  { id: 1, type: 'Claim' },
];

export default function BillingSupplementary(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('billingManagement');
  const startDate = dayjs().day(1).format("YYYY/MM/DD");
  const endDate = dayjs().day(+7).format("YYYY/MM/DD");
  const week = dayjs(startDate).week();
  const [fileType, setFileType] = useState(FILE_TYPES[0].type);
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState('');
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [ptfailedMsg, setPTFailedMsg] = useState([]);
  const [claimFailedMsg, setClaimFailedMsg] = useState([]);
  const [loading, setLoading] = useState(false)

  async function handleUploadFile() {
    try {
      setLoading(true);
      setUpload(false);
      //transhipment/pickup
      if (fileType === FILE_TYPES[0].type) {
        let formData = new FormData();
        formData.append('file', file);
        await dispatch(uploadPickupTranshipementFile(formData));
      }
      else if (fileType === FILE_TYPES[1].type) {
        let formData = new FormData();
        formData.append('claim_packages', file);
        await dispatch(uploadClaimPackages(formData));
      }
      setFile('');
      setUploadSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showAlertSnackbar({ message: fileType + " Upload Failed", type: 'error' }));
      if (fileType === FILE_TYPES[0].type) {
        if (!error.additional) {
          setPTFailedMsg(error.message);
        } else {
          setPTFailedMsg(error.additional);
        }
      } else if (fileType === FILE_TYPES[1].type) {
        setClaimFailedMsg(error.message);
      }
      setUploadFailed(true);
    }
  }

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        {t('billing_supplementary')}
      </Typography>

      <Card variant='outlined' sx={{ padding: 4, maxWidth: 'sm', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant='h5' marginBottom={3}>
          {t('billing_supplementary_title_prompt')}
        </Typography>
        <Typography marginBottom={3}>
          {t('billing_period')}:
          <Typography component='span' color='text.secondary' marginLeft={1}>
            {startDate + " - " + endDate + " Week " + week}
          </Typography>
        </Typography>
        <Typography marginBottom={3}>
          {t('billing_supplementary_prompt_detail')}
          <br />
          {fileType === FILE_TYPES[0].type &&
            <DownloadButton href={'/documents/pickup_transshipment_detail_template.xlsx'} download>
              {'Pickup_transhipment_template'}
            </DownloadButton>
          }
          {fileType === FILE_TYPES[1].type &&
            <DownloadButton href={'/documents/claim_package_template.xlsx'} download>
              {'Claim_template'}
            </DownloadButton>
          }
        </Typography>

        <TextField
          select
          name='file'
          label="file"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={fileType}
          onChange={(e) => setFileType(e.target.value)}>
          {Object.entries(FILE_TYPES).map(([k, v]) => (
            <MenuItem key={v.id} value={v.type}>
              {v.type}
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="contained"
          onClick={() => setUpload(true)}
          sx={{ marginTop: 6 }}>
          {t('upload')}
        </Button>
      </Card>

      <Dialog maxWidth={"sm"} fullWidth open={upload}>
        <DialogTitle>
          {"Upload " + fileType + " File"}
        </DialogTitle>
        <DialogContent dividers>
          <DragDropFileUpload fileType={".xlsx"} onFileUpload={setFile} file={file} />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => { setUpload(false); setFile('') }}>
            close
          </Button>
          <Button variant='contained' onClick={() => handleUploadFile(true)}>
            {t('upload')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"sm"} fullWidth open={uploadFailed}>
        <DialogTitle>
          Upload Failed
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Please correct the rows listed below and upload the adjustment file again
          </Typography>
          <Box marginY={2}>
            <DocumentDetailDisplay file={file} state={'Complete'} />
          </Box>
          {
            ptfailedMsg && (typeof ptfailedMsg === 'string' || ptfailedMsg instanceof String) &&
            <Typography color='error'>
              {ptfailedMsg}
            </Typography>
          }
          {
            ptfailedMsg && !(typeof ptfailedMsg === 'string' || ptfailedMsg instanceof String) &&
            <Typography component='ul' color='error'>
              {Object.keys(ptfailedMsg).map((category) => {
                const messages = ptfailedMsg[category]
                return (
                  <li key={category}>
                    {category}:
                    <ul>
                      {messages.map(message => {
                        return (
                          <li key={message.row}>
                            Row {message.row}:
                            <ul>
                              {message.errors.map((error, index) => <li key={index}>{error}</li>)}
                            </ul>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </Typography>
          }
          {
            claimFailedMsg &&
            <Typography sx={{ paddingY: 1 }}>
              {claimFailedMsg}
            </Typography>
          }
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => { setUploadFailed(false); setUpload(true); setFile(''); setClaimFailedMsg(''); setPTFailedMsg([]); }}>
            Back
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"sm"} fullWidth open={uploadSuccess}>
        <DialogTitle>
          {"Upload " + fileType + " Success"}
        </DialogTitle>
        <DialogContent dividers>
          Your upload has been completed.
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => { setUploadSuccess(false) }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </div>
  );
}
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField, Card, Typography, Stack } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { downloadWrongAreaOrders, getWrongAreaStatistic } from "../../actions/prealertStatisticActions";
import { DataGrid } from "@mui/x-data-grid";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import FullscreenLoading from "../../components/FullscreenLoading";

const initialDate = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
const initialPartner = '75'

function PrealertWrongAreaKeyword() {
  const dispatch = useDispatch();
  const [date, setDate] = useState(initialDate);
  const [partner, setPartner] = useState(initialPartner);
  const [time, setTime] = useState('import');
  const [keyword, setKeyword] = useState();
  const [type, setType] = useState('mawb');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const partnerList = [
    {
      name: "GV",
      type: '75'
    },
    {
      name: "BroadReach",
      type: "158"
    }
  ]

  const timeList = [
    {
      name: "Import Time",
      type: 'import'
    }
  ]

  const typeList = [
    {
      name: "MAWB",
      type: "mawb"
    }
  ]

  const columns = [
    {
      field: 'batch',
      headerName: 'Batch',
      width: 200,
    },
    {
      field: 'wrong_orders_count',
      headerName: 'Count',
      width: 100,
    },
    {
      field: 'wrong_orders',
      headerName: 'Wrong Orders',
      width: 600,
    },
    {
      field: 'err_msg',
      headerName: 'Error Message',
      width: 300,
    }
  ];

  const handleKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleUpdateRow = (res) => {
    const updatedRows = res.map((row, i) => ({
      id: i,
      batch: row.batch,
      wrong_orders: JSON.stringify(row.wrong_orders),
      err_msg: row.err_msg,
    }));
    setRows(updatedRows);
  };

  const handleSearch = useCallback(async (date, partner) => {
    setLoading(true);
    try {
      const res = await dispatch(getWrongAreaStatistic(date, partner)).catch(e => {
        throw e.message;
      })
      handleUpdateRow(res);
      dispatch(showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' }));
    } catch (e) {
      dispatch(showAlertSnackbar({ message: e, type: 'error' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, setLoading])

  async function handleDownload() {
    try {
      setLoading(true);
      let response = await downloadWrongAreaOrders(keyword);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Wrong-Area.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      dispatch(showAlertSnackbar({ message: 'Download Failed', type: 'error' }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSearch(initialDate, initialPartner);
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Wrong Dest. Airport
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack direction='row' alignItems='center' spacing={2} useFlexGap flexWrap='wrap' sx={{ padding: 3 }}>
          <FormControl variant="outlined">
            <InputLabel id="partner-label">Partner</InputLabel>
            <Select
              labelId="partner-label"
              value={partner}
              label={"Partner"}
              onChange={(e) => { setPartner(e.target.value) }}>
              {partnerList.map((partner) => (
                <MenuItem key={partner.id} value={partner.type}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <Select
              value={time}
              onChange={(e) => setTime(e.target.value)}>
              {timeList.map((time) => (
                <MenuItem value={time.type} key={time.type}>
                  {time.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="prealert_date_text"
            type="date"
            variant="outlined"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <IconButton
            id={'prealert_search_button'}
            size={'large'}
            color={'primary'}
            onClick={() => handleSearch(date, partner)}>
            <SearchIcon />
          </IconButton>
          <FormControl variant="outlined">
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}>
              {typeList.map((type) => (
                <MenuItem value={type.type} key={type.type}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id={'prealert_keyword_text'}
            variant='outlined'
            onChange={handleKeyword}
            value={keyword}
          />
          <IconButton
            id={'prealert_download_button'}
            size={'large'}
            color={'primary'}
            onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </Stack>
      </Card>
      <Card variant='outlined'>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          pageSizeOptions={[10, 20, 30]}
          paginationModel={{ pageSize: 10 }}
          sx={{ border: 'none', borderRadius: 0 }}
        />
      </Card>
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default PrealertWrongAreaKeyword;
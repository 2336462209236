import { useDispatch } from 'react-redux';
import { Button, TextField, Box, Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDriverDropOffHistory, handleServicePoint } from '../../actions/servicePointActions';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import moment from 'moment';

function ServicePointSearchDate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation('servicePointStatistics');

    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
    const [failOpen, setFailOpen] = useState(false);
    // const [pointId, setPointId] = useState('');
    const pointId = ''

    const handleFailClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailOpen(false);
    };

    function handleDateTo(event) {
        const dateToTamp = moment(event.target.value).unix();
        const dateFromTamp = moment(dateFrom).unix();
        if (dateToTamp < dateFromTamp) {
            setDateTo(dateFrom);
            setFailOpen(true);
        } else {
            setDateTo(event.target.value);
        }
    }
    async function fetchStatistics() {
        props.handleLoading(true);
        const warehouse = window.localStorage.getItem("warehouse");
        const dateFromNum = moment(dateFrom).startOf('day').valueOf() / 1000;
        const dateToNum = Math.floor(moment(dateTo).endOf('day').valueOf() / 1000);
        const pointIdTrim = pointId.replaceAll(/\s/g, "");
        try {
            await dispatch(handleServicePoint(pointIdTrim));
            await dispatch(getDriverDropOffHistory(dateFromNum, dateToNum, warehouse));
            props.handleLoading(false);
        } catch {
            dispatch(showAlertSnackbar({ message: t('fail_fetch_message'), type: 'error' }));
            props.handleLoading(false);
        }
    }

    return (
        <>
            <Snackbar open={failOpen} autoHideDuration={6000} onClose={handleFailClose}>
                <Alert onClose={handleFailClose} severity="error">
                    End date must be later than start date!
                </Alert>
            </Snackbar>
            <Box display='flex' paddingBottom={2}>
                <TextField
                    id="searchStartDate"
                    type="date"
                    variant="outlined"
                    size="small"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                />
                <Box sx={{ padding: 1 }}>
                    {t('to')}
                </Box>
                <TextField
                    id="searchStartDate"
                    type="date"
                    variant="outlined"
                    size="small"
                    value={dateTo}
                    onChange={handleDateTo}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    onClick={fetchStatistics}
                >
                    {t('search_all')}
                </Button>
            </Box>
        </>
    );
}

export default ServicePointSearchDate;
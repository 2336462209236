import { useDispatch } from 'react-redux';
import { Button, TextField, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchServicePoint } from '../../actions/servicePointActions';

function ServicePointSearchKeyword(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation('servicePointManagement');

    const [successOpen, setSuccessOpen] = useState(false);
    const [failOpen, setFailOpen] = useState(false);
    const [searchType, setSearchType] = useState('id');
    const [keyword, setKeyword] = useState();

    const typeList = [
        {
            id: 0,
            name: t('serial_no'),
            type: 'id'
        }
    ]

    const handleKeyword = (event) => {
        const keyword = event.target.value
        setKeyword(keyword);

        if (props.type === "uiSearch") {
            props.handleKeyword(keyword);
        }
    };
    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };
    const handleFailClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailOpen(false);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleKeywordSearch();
        }
    }

    function handleKeywordSearch() {
        if (props.type === "uiSearch") {
            props.handleKeyword(keyword);
        } else if (props.type === "apiSearch") {
            const warehouse = window.localStorage.getItem("warehouse");
            dispatch(searchServicePoint(warehouse, 1, keyword))
                .then((res) => {
                    props.handleServicePointInfo(res);
                })
        }
    }

    return (
        <>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    {t('success_message')}
                </Alert>
            </Snackbar>
            <Snackbar open={failOpen} autoHideDuration={6000} onClose={handleFailClose}>
                <Alert onClose={handleFailClose} severity="error">
                    {t('fail_message')}
                </Alert>
            </Snackbar>
            <Box display='flex'>
                <TextField
                    fullWidth
                    select
                    label={t('search_type')}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    size='small'
                    value={searchType}
                    onChange={(e) => {
                        setSearchType(e.target.value)
                    }}
                    style={{ maxWidth: 160 }}
                >
                    <option value="">
                        {t('search_type')}
                    </option>
                    {typeList.map((type) => (
                        <option key={type.id} value={type.type}>
                            {type.name}
                        </option>
                    ))}
                </TextField>
                <TextField
                    onChange={handleKeyword}
                    onKeyPress={(e) => handleKeyPress(e)}
                    value={keyword}
                    variant='outlined'
                    size='small'
                    style={{ width: 300, marginLeft: 10 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    onClick={handleKeywordSearch}
                    onKeyPress={handleKeyPress}
                >
                    {t('search')}
                </Button>
            </Box>
        </>
    );
}

export default ServicePointSearchKeyword;
import { execute } from "../utilities/executor";
import { shangjiaServerInstance } from "../utilities/instances";
import { ADDRESS_MANAGEMENT as types } from "./types";
import { getUserPreference } from "../utilities/common";
import axios from "axios";

export const getBlockedAddress = (address = '', zipcode = '', type = '', notes = '') => (d) => execute(d)(
  shangjiaServerInstance.get(`address/getblockedaddress?address=${address}&zipcode=${zipcode}&type=${type}&notes=${notes}`),
  {
    type: types.GET_BLOCKED_ADDRESS,
    success: types.GET_BLOCKED_ADDRESS_SUCCESS,
    fail: types.GET_BLOCKED_ADDRESS_FAIL
  }
);

export const uploadBlockedAddress = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`address/uploadblockedaddress`, params),
  {
    type: types.UPLOAD_BLOCKED_ADDRESS,
    success: types.UPLOAD_BLOCKED_ADDRESS_SUCCESS,
    fail: types.UPLOAD_BLOCKED_ADDRESS_FAIL,
  }
);

export const downloadBlockedAddress = async (params) => {
  try {
    const authToken = getUserPreference().authToken;
    return await axios.post(
      `${process.env.REACT_APP_SJ_SERVER_URL}/address/downloadblockedaddress`,
      params,
      {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      }
    );
  } catch (error) {
    throw error;
  }
};


export const deleteMultipleRecords = (params) => (d) => execute(d)(

  shangjiaServerInstance.post(`address/deleteaddress`, params),
  {
    type: types.UPLOAD_FACTORY_ADDRESS,
    success: types.UPLOAD_FACTORY_ADDRESS_SUCCESS,
    fail: types.UPLOAD_FACTORY_ADDRESS_FAIL,
  }
);

export const getFactoryAddress = (address = '', zipcode = '', type = '', notes = '') => (d) => execute(d)(
  shangjiaServerInstance.get(`address/getfactoryaddress?address=${address}&zipcode=${zipcode}&type=${type}&notes=${notes}`),
  {
    type: types.GET_FACTORY_ADDRESS,
    success: types.GET_FACTORY_ADDRESS_SUCCESS,
    fail: types.GET_FACTORY_ADDRESS_FAIL
  }
);

export const uploadFactoryAddress = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`address/uploadblockedaddress`, params),
  {
    type: types.UPLOAD_FACTORY_ADDRESS,
    success: types.UPLOAD_FACTORY_ADDRESS_SUCCESS,
    fail: types.UPLOAD_FACTORY_ADDRESS_FAIL,
  }
);
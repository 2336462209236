import { shangjiaServerInstance } from '../utilities/instances';
import { RATE_CARDS_MANAGEMENT as types } from './types';
import { execute } from '../utilities/executor';

/**
 * 
 * @param {string} partner_id 
 * @param {string} partner_name 
 * @param {number} rows 
 * @param {number} page 
 * @returns {Array<{
 *      partner_id: string
 *      partner_name: string
 *      ka_name: string
 *      ka_email: string
 *      rate_cards: Array<{
 *          id: number
 *          status: number
 *          service_type: string
 *          zone_version: number
 *          fsa_version: number
 *          price_sheets: Array<{
 *              name: string
 *              path: string
 *          }>
 *          billing_date: string
 *          effective_date: string
 *          expiry_date: string
 *          note: string
 *      }>
 *      upcoming_rate_card?: {
 *          id: number
 *          status: number
 *          service_type: string
 *          zone_version: number
 *          fsa_version: number
 *          price_sheets: Array<{
 *              name: string
 *              path: string
 *          }>
 *          billing_date: string
 *          effective_date: string
 *          expiry_date: string
 *          note: string
 *          sample_bill: {
 *              name: string
 *              path: string
 *          }
 *          faulty_parcels: {
 *              name: string
 *              path: string
 *          }
 *      }
 * }>}
 */
export function getPartnersRateCards(partner_id, partner_name = '', rows = 10, page = 1) {
    return function (dispatch) {
      dispatch({ type: types.GET_PARTNERS_RATE_CARDS })
      return shangjiaServerInstance.get(`partners/ratecards?id=${partner_id}&name=${partner_name}&rows=${rows}&page=${page}`)
        .then(({ data }) => {
          dispatch({
            type: types.GET_PARTNERS_RATE_CARDS_SUCCESS,
            payload: data.data
          })
          return Promise.resolve([data.data, data.paging]);
        }).catch(error => {
          dispatch({
            type: types.GET_PARTNERS_RATE_CARDS_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    }
  }

/**
 * 
 * @param {string} partner_id 
 * @param {{
 *      service_type: string
 *      zone_version: string
 *      fsa_version: string
 *      billing_date: string
 *      effective_date: string
 *      note: string
 *      file: File
 * }} params 
 * @returns
 */
export const createPartnerRateCard = (partner_id, params) => (d) => execute(d)(
    shangjiaServerInstance.post(`partners/ratecards/${partner_id}`, params),
    {
        type: types.CREATE_PARTNER_RATE_CARD,
        success: types.CREATE_PARTNER_RATE_CARD_SUCCESS,
        fail: types.CREATE_PARTNER_RATE_CARD_FAIL
    }
);

/**
 * 
 * @param {string} partner_id 
 * @param {string} rate_card_id 
 * @returns
 */
export const deletePartnerRateCard = (partner_id, rate_card_id) => (d) => execute(d)(
    shangjiaServerInstance.delete(`partners/ratecards?partner_id=${partner_id}&rate_card_id=${rate_card_id}`),
    {
        type: types.DELETE_PARTNER_RATE_CARD,
        success: types.DELETE_PARTNER_RATE_CARD_SUCCESS,
        fail: types.DELETE_PARTNER_RATE_CARD_FAIL
    }
);

/**
 * 
 * @param {string} partner_id 
 * @param {{
 *      name: string
 *      email: string
 * }} params 
 * @returns
 */
export const updatePartnerKA = (partner_id, params) => (d) => execute(d)(
    shangjiaServerInstance.post(`partners/ka?partner_id=${partner_id}`, params),
    {
        type: types.UPDATE_PARTNER_KA,
        success: types.UPDATE_PARTNER_KA_SUCCESS,
        fail: types.UPDATE_PARTNER_KA_FAIL
    }
);
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getApiDescription } from '../../actions/sandboxActions';
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import SandboxProfile from './SandboxProfile';
import SandboxInfo from './SandboxInfo';
import FullscreenLoading from '../../components/FullscreenLoading';

function Sandbox() {
  const dispatch = useDispatch();
  const { t } = useTranslation('sandbox');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    async function loadSandBox() {
      try {
        setLoading(true);
        await dispatch(getApiDescription());
      } catch (e) {
        dispatch(showAlertSnackbar({ title: 'LOAD SANDBOX FAILED' }));
      } finally {
        setLoading(false);
      }
    }
    loadSandBox()
  }, [dispatch])

  return (
    <div>
      <Typography variant='subtitle1'>
        {t('uni_api_sandbox')}
      </Typography>
      <SandboxInfo type='retrieve-token' handleToken={setToken} />
      <SandboxInfo type='check-service-availability' token={token} />
      <SandboxInfo type='create-batch' token={token} />
      <SandboxInfo type='create-order' token={token} />
      <SandboxInfo type='get-orders' token={token} />
      <SandboxInfo type='update-order-bag-no' token={token} />
      <SandboxInfo type='cancel-order' token={token} />
      <SandboxInfo type='order-verification' />
      <SandboxInfo type='print-label' token={token} />
      <SandboxInfo type='push-tracking' token={token} />
      <SandboxInfo type='estimate-shipping' token={token} />
      <Typography variant='subtitle2' marginY={2}>
        Account
      </Typography>
      <SandboxProfile type='account' />
      <Typography variant='subtitle2' marginY={2}>
        Contact Information
      </Typography>
      <SandboxProfile type='contact' />
      <FullscreenLoading open={loading} />
    </div>
  );
}

export default Sandbox;
import { createTheme } from '@mui/material/styles';
import '@mui/x-data-grid/themeAugmentation';

export const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#273340',
            dark: '#3F474F',
            light: '#9CACBD'
        },
        secondary: {
            main: '#01579B',
            dark: '#01579B',
            light: '#03A9F4'
        }
    },
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
        subtitle1: {
            fontSize: '24px',
            fontWeight: 700,
            color: '#577699'
        },
        subtitle2: {
            fontSize: '18px',
            fontWeight: 600,
            color: '#577699'
        }
    },
    mixins: {
        MuiDataGrid: {
          containerBackground: '#EEF6FF'
        }
    }
})

export const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#273340',
            dark: '#3F474F',
            light: '#9CACBD'
        },
        secondary: {
            main: '#01579B',
            dark: '#01579B',
            light: '#03A9F4'
        }
    },
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
        subtitle1: {
            fontSize: '24px',
            fontWeight: 700
        },
        subtitle2: {
            fontSize: '18px',
            fontWeight: 600
        }
    },
    overrides: {
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                }
            }
        }
    }
})
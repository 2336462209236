import { USER_MANAGEMENT as type } from "./types";
import { execute } from "../utilities/executor";
import { shangjiaServerInstance } from "../utilities/instances";

export const getAbilityList = () => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get('/auth/abilities'),
  {
    type: type.GET_ABILITY_LIST,
    success: type.GET_ABILITY_LIST_SUCCESS,
    fail: type.GET_ABILITY_LIST_FAIL,
  }
);

export const getAbilityListByUser = (userId) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get(`auth/permissions?user_id=${userId}`),
  {
    type: type.GET_ABILITY_LIST_BY_USER,
    success: type.GET_ABILITY_LIST_BY_USER_SUCCESS,
    fail: type.GET_ABILITY_LIST_BY_USER_FAIL,
  }
);

export const getRoleList = () => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get('/auth/roles'),
  {
    type: type.GET_ROLE_LIST,
    success: type.GET_ROLE_LIST_SUCCESS,
    fail: type.GET_ROLE_LIST_FAIL,
  }
);

export const getRoleListByUser = (userId) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get(`auth/assigned_roles?entity_id=${userId}`),
  {
    type: type.GET_ROLE_LIST_BY_USER,
    success: type.GET_ROLE_LIST_BY_USER_SUCCESS,
    fail: type.GET_ROLE_LIST_BY_USER_FAIL,
  }
);

export const getUserList = () => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get('/auth/users'),
  {
    type: type.GET_USER_LIST,
    success: type.GET_USER_LIST_SUCCESS,
    fail: type.GET_USER_LIST_FAIL,
  }
);

export const getUserListByRole = (roleId) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.get(`/auth/users?role_id=${roleId}`),
  {
    type: type.GET_USER_LIST_BY_ROLE,
    success: type.GET_USER_LIST_BY_ROLE_SUCCESS,
    fail: type.GET_USER_LIST_BY_ROLE_FAIL,
  }
);

export const createUser = (params) => (d) => execute(d)(
  shangjiaServerInstance.post(`/account/prmaccount`, params),
  {
    type: type.CREATE_USER,
    success: type.CREATE_USER_SUCCESS,
    fail: type.CREATE_USER_FAIL,
  }
)

export const createNewRole = (name) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/roles/create?name=${name}`, {
    title: name,
  }),
  {
    type: type.CREATE_NEW_ROLE,
    success: type.CREATE_NEW_ROLE_SUCCESS,
    fail: type.CREATE_NEW_ROLE_FAIL,
  }
);

export const updateUserInformation = (userId, customer) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/users/update?id=${userId}`, {
    customer: customer,
  }),
  {
    type: type.UPDATE_USER_INFORMATION,
    success: type.UPDATE_USER_INFORMATION_SUCCESS,
    fail: type.UPDATE_USER_INFORMATION_FAIL,
  }
);

export const deleteRole = (roleId) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/roles/delete?id=${roleId}`),
  {
    type: type.DELETE_ROLE,
    success: type.DELETE_ROLE_SUCCESS,
    fail: type.DELETE_ROLE_FAIL,
  }
);

export const deleteUser = (userId) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/users/delete?id=${userId}`),
  {
    type: type.DELETE_USER,
    success: type.DELETE_USER_SUCCESS,
    fail: type.DELETE_USER_FAIL,
  }
);

export const updateUserRoles = (userId, roles) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/assigned_roles/update?entity_id=${userId}`, {
    role_ids: roles,
  }),
  {
    type: type.UPDATE_USER_ROLES,
    success: type.UPDATE_USER_ROLES_SUCCESS,
    fail: type.UPDATE_USER_ROLES_FAIL,
  }
);

export const updateRole = (roleId, name) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/roles/update?id=${roleId}`, {
    name: name,
  }),
  {
    type: type.UPDATE_ROLE_INFORMATION,
    success: type.UPDATE_ROLE_INFORMATION_SUCCESS,
    fail: type.UPDATE_ROLE_INFORMATION_FAIL,
  }
);

export const updateRoleAbilities = (roleId, abilities) => (dispatch) => execute(dispatch)(
  shangjiaServerInstance.post(`auth/permissions/update?role_id=${roleId}`, {
    ability_ids: abilities,
  }),
  {
    type: type.UPDATE_ROLE_ABILITIES,
    success: type.UPDATE_ROLE_ABILITIES_SUCCESS,
    fail: type.UPDATE_ROLE_ABILITIES_FAIL,
  }
);
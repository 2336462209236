/**
 * Important: subitems must have the same id as prefix
 */
export const menuItems = [
  {
    id: 'home',
    title: 'Home',
    access_ability_id: 0
  },
  {
    id: 'local-delivery',
    title: 'Local Delivery',
    access_ability_id: 1
  },
  {
    id: 'sandbox',
    title: 'Sandbox',
    access_ability_id: 2,
  },
  {
    id: 't4a',
    title: 'T4A',
    access_ability_id: 3
  },
  {
    id: 'service-point',
    title: 'Service Point',
    access_ability_id: 4,
    subitems: [
      {
        id: 'service-point-list',
        title: 'Service Point List'
      },
      {
        id: 'service-point-add',
        title: 'Add Service Point'
      },
      {
        id: 'service-point-edit',
        title: 'Edit Service Point'
      },
      {
        id: 'service-point-package-statistics',
        title: 'Package Statistics'
      },
    ]
  },
  {
    id: 'postal-outlet',
    title: 'Postal Outlet',
    access_ability_id: 5,
    subitems: [
      {
        id: 'postal-outlet-place-order',
        title: 'Place Order'
      },
      {
        id: 'postal-outlet-orders',
        title: 'Orders'
      },
    ]
  },
  {
    id: 'prealert',
    title: 'Pre-alert Statistics',
    access_ability_id: 6,
    subitems: [
      {
        id: 'prealert-reports',
        title: 'Pre-alert Reports'
      },
      {
        id: 'prealert-failed-imports',
        title: 'Failed Imports'
      },
      {
        id: 'prealert-wrong-dest-airport',
        title: 'Wrong Dest. Airport'
      },
      {
        id: 'prealert-partner-incoming-volume',
        title: 'Partner Incoming Volume'
      },
    ]
  },
  {
    id: 'organization',
    title: 'Organization',
    access_ability_id: 7,
    subitems: [
      {
        id: 'organization-partner',
        title: 'Partner Management',
      },
      {
        id: 'organization-customer',
        title: 'Customer Management',
      },
    ]
  },
  {
    id: 'user-management',
    title: 'User Management',
    access_ability_id: 8,
  },
  {
    id: 'delivery-capacity',
    title: 'Delivery Capacity',
    access_ability_id: 9,
    subitems: [
      {
        id: 'delivery-capacity-overview',
        title: 'Overview'
      },
      {
        id: 'delivery-capacity-update-capacity',
        title: 'Update Capacity'
      },
      {
        id: 'delivery-capacity-update-threshold',
        title: 'Update Threshold'
      },
    ]
  },
  {
    id: 'faulty-package',
    title: 'Faulty Package',
    access_ability_id: 10,
  },
  {
    id: 'gv-faulty-package',
    title: 'GV Faulty Package',
    access_ability_id: 11,
  },
  {
    id: 'gv-order',
    title: 'GV Order Management',
    access_ability_id: 12,
    subitems: [
      {
        id: 'gv-order-replenishment',
        title: 'Order Replenishment',
      },
    ]
  },
  {
    id: 'billing',
    title: 'Billing and Invoice',
    access_ability_id: 13,
    subitems: [
      {
        id: 'billing-management',
        title: 'Billing Management'
      },
      {
        id: 'billing-supplementary',
        title: 'Billing Supplementary'
      },
      {
        id: 'billing-invoice-management',
        title: 'Invoice Management'
      },
      {
        id: 'billing-invoice-number-generator',
        title: 'Invoice Number Generator'
      },
      {
        id: 'billing-rate-cards-management',
        title: 'Rate Cards Management'
      }
    ]
  },
  {
    id: 'address',
    title: 'Address Management',
    access_ability_id: 14,
    subitems: [
      {
        id: 'address-blocked',
        title: 'Blocked Address'
      },
    ]
  },
  {
    id: "fleet-settlement",
    title: "Fleet Settlement",
    access_ability_id: 15,
    subitems: [
      {
        id: 'fleet-settlement-payment-adjustments',
        title: "Payment Adjustments"
      }
    ]
  }
];
export const MarkerTypes = {
  DRIVER: "driver",
  WAREHOUSE: "warehouse",
  APARTMENT: "apartment",
  NORMAL: "normal",
  OTHER: "other"
}

export const MarkerIconPath = {
  DRIVER: "./images/markers/car.png",
  WAREHOUSE: "./images/markers/warehouse.png",
  DEFAULT: "./images/markers/default.png",
}

export const ErrorTypes = {
  JSON: 0,
  HTML: 1
}

export const MapModes = {
  NONE: "none",
  SELECT_START: "select_start",
  SELECT_END: "select_end"
}

export const FaultyPackageIssueType = {
  "5": "Empty Package",
  "6": "Damaged package, items can be delivered. Please confirm tracking #",
  "7": "Damaged package, items cannot be delivered",
  "8": "Duplicated tracking number",
  "9": "No pre-alert",
  "10": "No shipping label, please confirm",
  "11": "Wrong address or contact info",
  "12": "Others"
}

export const FaultyPackageRecordStatus = {
  "1": "Waiting for Client Review",
  "2": "Waiting for Confirmation",
  "3": "Closed",
  "4": "Completed"
}

export const FaultyPackageClientDecision = {
  "null": "",
  "13": "Report to customer",
  "14": "Redeliver",
  "15": "Return to sender after 30 days storage",
  "16": "Destroy after 30 days",
  "17": "Data resend",
  "18": "Others"
}

export const FaultyPackageActionTaken = {
  "null": "",
  "19": "Sent out for delivery",
  "20": "Store package for 30 days",
  "21": "Return to sender",
  "22": "Follow the protocol",
  "23": "Others"
}

export const FaultyPackagePackageStatus = {
  "0"  : "N/A",
  "190": "190 : ORDER_RECEIVED",
  "191": "191 : PARCEL_ARRIVED",
  "192": "192 : CUSTOM_HOLD",
  "193": "193 : CUSTOM_RELEASE_FROM_HOLD",
  "194": "194 : RETURNED_PARCEL_PICKEDUP",
  "198": "198 : CUSTOM_RELEASE_DIRECT",
  "195": "195 : GATEWAY_TRANSIT_OUT",
  "199": "199 : GATEWAY_TRANSIT",
  "200": "200 : PARCEL_SCANNED",
  "201": "201 : DISPATCHING",
  "202": "202 : IN_TRANSIT",
  "203": "203 : DELIVERED",
  "204": "204 : TRANSSHIPMENT",
  "206": "206 : WRONG_ADDRESS_FROM_TRANSIT",
  "207": "207 : PARCEL_LOST",
  "208": "208 : RETURN_TO_SOURCE",
  "209": "209 : OTHER_EXCEPTION",
  "210": "210 : APT_CALL_NO_RESP",
  "211": "211 : RETURN_OFFICE_FROM_TRANSIT",
  "212": "212 : WRONG_ADDRESS_FROM_RECEIVE",
  "213": "213 : STORAGE_30_DAYS_FROM_OFFICE",
  "214": "214 : STORAGE_30_DAYS_AFTER_SCAN",
  "216": "216 : SELF_PICK_UP",
  "215": "215 : PARCEL_ABANDONED",
  "217": "217 : TRANSSHIPMENT_COMPLETE",
  "218": "218 : SCANNED_PARCEL_MISSING",
  "219": "219 : WRONG_ROUTE_PARCEL",
  "220": "220 : SECOND_DELIVERY",
  "221": "221 : RETURNED_PARCEL_SCANNED",
  "222": "222 : REJECTED_PARCEL_FROM_TRANSIT",
  "223": "223 : CHANGED_ORDER_RESENT",
  "224": "224 : RESENT_ORDER_VOIDED",
  "225": "225 : FORWARDED_3RDPARTY",
  "226": "226 : STORAGE_3RDPARTY_SERVICE_POINT",
  "227": "227 : SERVICE_POINT_TRANSIT_WAREHOUSE",
  "228": "228 : SECOND_DELIVERED",
  "229": "229 : DROP_OFF_SERVICE_POINTS",
  "230": "230 : RETURN TO SENDER WAREHOUSE",
  "231": "231 : FAILED_DELIVERY_RETRY1",
  "232": "232 : FAILED_DELIVERY_RETRY2",
  "255": "255 : Gateway_To_Gateway_Transit"
}

export const FAULTY_PACKAGE_STATUS = {
  "NEW_RECORD_STATUS": 1,
  "CLIENT_REVIEWED": 2,
  "UNI_CONFIRMED": 3
};

export const FAULTY_PACKAGE_RECORD_INFO = {
  "id": "",
  "record_status": "",
  "warehouse": "",
  "tracking_number": "",
  "sender_reference": "",
  "issue_type": "",
  "package_status": "",
  "item_number": "",
  "image_count": "",
  "verified_tracking_number": "",
  "client_decision": "",
  "client_file": "",
  "action_taken": "",
  "uni_notes": "",
  "client_notes": "",
  "closing_notes": "",
  "updated_by": "",
  "created_by": "",
  "deleted_by": "",
  "deleted_at": "",
  "closed_at": "",
  "updated_at": "",
  "created_at": ""
};
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from "react-redux";
import { deleteDriver, editDriver, generateSendT4a } from "../../actions/t4aActions";
import { showAlertSnackbar } from '../../reducers/sliceReducer';

export default function DriverEdit(props) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [driver, setDriver] = useState({});
  const [driverId, setDriverId] = useState();
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [sin, setSin] = useState();
  const [amount, setAmount] = useState();
  const [province, setProvince] = useState();
  const [company, setCompany] = useState();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [id, setId] = useState();

  function handleClose() {
    setOpenModal(false);
    props.handleClose();
  }
  function handleDeleteConfirm() {
    setDeleteConfirmOpen(true);
  }
  function handleDelete() {
    dispatch(deleteDriver(driver.driver_id))
      .then(() => {
        dispatch(showAlertSnackbar({ message: "Deleted successfully!", type: 'success' }));
        setDeleteConfirmOpen(false);
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
  }
  function handleSave() {
    let params = {
      id: id,
      driver_id: driverId,
      last_name: lastName,
      first_name: firstName,
      sin: sin,
      amount: amount,
      province: province,
      company: company
    }
    dispatch(editDriver(id, params))
      .then(() => {
        dispatch(showAlertSnackbar({ message: 'Driver information updated successsfully!', type: 'success' }))
      })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'Failed to update Driver Information', type: 'error' }))
      })
  }
  function handleGenerateSend() {
    dispatch(generateSendT4a(id))
      .then(() => {
        dispatch(showAlertSnackbar({ message: 'Generate and send T4A successfully!', type: 'success' }))
      })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'Failed to generate and send T4A!', type: 'error' }))
      })
  }

  useEffect(() => {
    setOpenModal(props.open)
  }, [props.open])
  useEffect(() => {
    setDriver(props.driverInfo)
  }, [props.driverInfo])
  useEffect(() => {
    if (openModal) {
      if (driver) {
        setId(driver.id);
        setDriverId(driver.driver_id);
        setLastName(driver.last_name);
        setFirstName(driver.first_name);
        setSin(driver.sin);
        setAmount(driver.amount);
        setProvince(driver.province);
        setCompany(driver.company);
      }
    }
  }, [openModal, driver])

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth>
      <DialogTitle display='flex' alignItems='center' justifyContent='space-between'>
        Driver information
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#ebebeb" }}>
                <TableCell align="center">Driver ID</TableCell>
                <TableCell align="center">Last Name</TableCell>
                <TableCell align="center">First Name</TableCell>
                <TableCell align="center">SIN</TableCell>
                <TableCell align="center">Province</TableCell>
                <TableCell align="center">Company</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">T4A</TableCell>
                <TableCell align="center">Confirm</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <TextField
                    variant='outlined'
                    value={driverId}
                    disabled={!enableEdit}
                    onChange={(e) => setDriverId(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant='outlined'
                    value={lastName}
                    disabled={!enableEdit}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant='outlined'
                    value={firstName}
                    disabled={!enableEdit}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    variant='outlined'
                    value={sin}
                    disabled={!enableEdit}
                    onChange={(e) => setSin(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    style={{ width: 80 }}
                    select
                    variant='outlined'
                    defaultValue={driver.province}
                    value={province}
                    disabled={!enableEdit}
                    onChange={(e) => setProvince(e.target.value)}>
                    {
                      props.provinceList.map((province, index) => {
                        if (index !== 0) {
                          return (
                            <MenuItem value={province.code}>{province.name}</MenuItem>
                          )
                        }
                        else return null
                      })
                    }
                  </TextField>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    style={{ width: 110 }}
                    select
                    variant='outlined'
                    defaultValue={driver.company}
                    value={company}
                    disabled={!enableEdit}
                    onChange={(e) => setCompany(e.target.value)}>
                    {
                      props.companyList.map((company, index) => {
                        if (index !== 0) {
                          return (
                            <MenuItem value={company.code}>{company.name}</MenuItem>
                          )
                        }
                        else return null
                      })
                    }
                  </TextField>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    style={{ width: 140 }}
                    variant='outlined'
                    value={amount}
                    disabled={!enableEdit}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">
                  {
                    driver.message_sent === 0 ?
                      <div>Unsent</div> :
                      <div>Sent</div>
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    driver.confirmed === 0 ?
                      <div></div> :
                      <CheckCircleIcon style={{ color: '#bbdefb' }} />
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ padding: 3, justifyContent: 'space-between' }}>
        <Button
          onClick={handleDeleteConfirm}
          startIcon={<DeleteForeverIcon />}
          color='error'>
          Delete this driver
        </Button>
        <div>
          {
            enableEdit &&
            <>
              <Button
                onClick={() => setEnableEdit(false)}
                sx={{ marginRight: 1 }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color='primary'
                onClick={() => {
                  handleSave();
                  setEnableEdit(false);
                }}>
                Save
              </Button>
            </>
          }
          {
            !enableEdit &&
            <>
              <Button
                onClick={() => setEnableEdit(true)}
                startIcon={<EditIcon />}
                sx={{ marginRight: 1 }}>
                Edit
              </Button>
              <Button
                onClick={handleGenerateSend}
                startIcon={<MailOutlineIcon />}
                variant='contained'>
                Generate and send T4A
              </Button>
            </>
          }
        </div>
      </DialogActions>
      <Dialog open={deleteConfirmOpen} maxWidth='sm'>
        <DialogContent>
          <Typography>
            Are you sure to delete this driver record?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>
            Cancel
          </Button>
          <Button
            color='error'
            variant='contained'
            onClick={handleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
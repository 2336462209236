import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, TextField, FormControlLabel, Radio, Button, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Box, RadioGroup, InputAdornment, Checkbox, MenuItem, Grid2 as Grid } from '@mui/material';
import { createBusinessOrder, estimateShipping, getInvoice, printLabel } from '../../actions/postalOutletActions.js';
import { showAlertSnackbar } from "../../reducers/sliceReducer.js";
import { useCustomer } from "../../utilities/hooks.js";
import InfoIcon from '@mui/icons-material/Info';
import FullscreenLoading from '../../components/FullscreenLoading.js'

const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const POSTAL_CODE_PATTERN = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

function validateAll(orderInfo) {
  if (orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight))
    return false;
  if (orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength))
    return false;
  if (orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth))
    return false;
  if (orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight))
    return false;
  if (orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10)
    return false;
  if (orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10)
    return false;
  if (orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail))
    return false;
  if (orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail))
    return false;
  if (orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode))
    return false;
  if (orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode))
    return false;

  return true;
}

export default function NewOrder() {
  const dispatch = useDispatch();
  const { t } = useTranslation('postalOutlet');
  const { customerData } = useCustomer();
  const [weightUnit, toggleWeightUnit] = useState('lb');
  const [dimUnit, toggleDimUnit] = useState('in');
  const [unitType, setUnitType] = useState('imperial');
  const [noProhibited, setNoProhibited] = useState(false);
  const [submitEnabled, setEnabled] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [paymentCollection, setPaymentCollection] = useState(false);
  const [price, setPrice] = useState("");
  const [eta, setEta] = useState("");
  const [loading, setLoading] = useState(false);
  const [labels, setLabelOpen] = useState(false);
  const [labelUrl, setLabelUrl] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [invoice, setInvoiceOpen] = useState(false);
  const [packageInfo, setPackage] = useState({});
  const [invoiceURL, setInvoiceUrl] = useState({});
  const [orderInfo, setOrderInfo] = useState({
    parcelWeight: "",
    parcelLength: "",
    parcelWidth: "",
    parcelHeight: "",
    senderName: "",
    senderPhone: "",
    senderEmail: "",
    senderUnit: "",
    senderAddress: "",
    senderCity: "",
    senderProvince: "",
    senderPostalCode: "",
    consigneeName: "",
    consigneePhone: "",
    consigneeEmail: "",
    consigneeUnit: "",
    consigneeAddr: "",
    consigneeCity: "",
    consigneeProvince: "",
    consigneePostalCode: "",
    consigneeBuzzNo: "",
    Notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderInfo({
      ...orderInfo,
      [name]: value,
    });
  };

  const provinceList = {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NT: "Northwest Territories",
    NS: "Nova Scotia",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
    " ": " ",
  };

  function createOrder() {
    setLoading(true);
    let params = {
      "customer_name": customerData.alias,
      "customer_no": customerData.id,
      "sender": orderInfo.senderName,
      "start_phone": orderInfo.senderPhone,
      "start_email": orderInfo.senderEmail,
      "pickup_unit_no": orderInfo.senderUnit,
      "pickup_address": (orderInfo.senderUnit ? orderInfo.senderUnit + "-" : "") + orderInfo.senderAddress + " " + orderInfo.senderCity + " " + orderInfo.senderProvince + " " + orderInfo.senderPostalCode,
      "receiver": orderInfo.consigneeName,
      "postal_code": orderInfo.consigneePostalCode,
      "delivery_address": orderInfo.consigneeAddr + " " + orderInfo.consigneeCity + " " + orderInfo.consigneeProvince,
      "delivery_unit_no": orderInfo.consigneeUnit,
      "buzz_code": orderInfo.consigneeBuzzNo,
      "receiver_phone": orderInfo.consigneePhone,
      "receiver_email": orderInfo.consigneeEmail,
      "dimension_uom": "CM",
      "weight": orderInfo.parcelWeight,
      "weight_uom": "KGS",
      "driver_notes": orderInfo.Notes,
    }
    if (dimUnit === 'in') {
      params["length"] = orderInfo.parcelLength * 2.54;
      params["width"] = orderInfo.parcelWidth * 2.54;
      params["height"] = orderInfo.parcelHeight * 2.54;
      params["weight"] = orderInfo.parcelWeight / 2.205;
    } else {
      params["length"] = orderInfo.parcelLength;
      params["width"] = orderInfo.parcelWidth;
      params["height"] = orderInfo.parcelHeight;
      params["weight"] = orderInfo.parcelWeight;
    }
    dispatch(createBusinessOrder(params))
      .then((res) => {
        setPackage(res.data.tno);
        clearForm();
        dispatch(printLabel({ packageId: res.data.tno, labelType: 6, labelFormat: 'pdf' }))
          .then((res) => {
            const base64 = res.data[0].labelContent;
            const url = 'data:application/pdf;base64,' + base64
            setLabelUrl(url);
            setLabelOpen(true);
            setPaymentCollection(false);
            setLoading(false);
          }).catch((err) => {
            dispatch(showAlertSnackbar({ type: "error", message: err.message }));
            setPaymentCollection(false);
            setLoading(false);
          })

      })
      .catch((err) => {
        setLoading(false);
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
      })
  }

  function getOrderInvoice() {
    setLoading(true);
    dispatch(getInvoice({ invoiceId: packageInfo, invoiceType: 1, invoiceFormat: 'pdf' }))
      .then((res) => {
        const base64 = res.data[0].invoiceContent;
        const url = 'data:application/pdf;base64,' + base64
        setInvoiceUrl(url);
        setLabelOpen(false);
        setInvoiceOpen(true);
        setLoading(false);
      }).catch((err) => {
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
        setLabelOpen(false);
        setLoading(false);
      })

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let params = {
      "start_postal_code": window.localStorage.getItem("start_postal_code"),
      "postal_code": orderInfo.consigneePostalCode
    };
    if (dimUnit === 'in') {
      params["length"] = orderInfo.parcelLength * 2.54;
      params["width"] = orderInfo.parcelWidth * 2.54;
      params["height"] = orderInfo.parcelHeight * 2.54;
      params["weight"] = orderInfo.parcelWeight / 2.205;
    } else {
      params["length"] = orderInfo.parcelLength;
      params["width"] = orderInfo.parcelWidth;
      params["height"] = orderInfo.parcelHeight;
      params["weight"] = orderInfo.parcelWeight;
    }
    dispatch(estimateShipping(params))
      .then((res) => {
        setPrice("$" + res.data.totalAfterTax);
        setEta("ETA In " + res.data.eta + " days");
        setConfirmOrder(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));

      })

  };

  const handleUnits = (e) => {
    if (e.target.value === 'imperial') {
      setUnitType(e.target.value);
      toggleWeightUnit('lb');
      toggleDimUnit('in');
    }
    if (e.target.value === 'metric') {
      setUnitType(e.target.value);
      toggleWeightUnit('kg');
      toggleDimUnit('cm');
    }
  }

  function clearForm() {
    setNoProhibited(false);
    setOrderInfo({
      parcelWeight: "",
      parcelLength: "",
      parcelWidth: "",
      parcelHeight: "",
      senderName: "",
      senderPhone: "",
      senderEmail: "",
      senderUnit: "",
      senderAddress: "",
      senderCity: "",
      senderProvince: "",
      senderPostalCode: "",
      consigneeName: "",
      consigneePhone: "",
      consigneeEmail: "",
      consigneeUnit: "",
      consigneeAddr: "",
      consigneeCity: "",
      consigneeProvince: "",
      consigneePostalCode: "",
      consigneeBuzzNo: "",
      Notes: "",
    });
  }

  useEffect(() => {
    if (orderInfo.parcelWeight && orderInfo.parcelHeight && orderInfo.parcelLength && orderInfo.parcelWidth
      && orderInfo.senderName && orderInfo.senderEmail && orderInfo.senderAddress && orderInfo.senderCity && orderInfo.senderProvince && orderInfo.senderPostalCode
      && orderInfo.consigneeName && orderInfo.consigneePhone && orderInfo.consigneeAddr && orderInfo.consigneeCity && orderInfo.consigneeProvince && orderInfo.consigneePostalCode && validateAll(orderInfo)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [orderInfo]);

  return (
    <Card variant='outlined' sx={{ width: '80%' }}>
      <form onSubmit={handleSubmit}>
        <Box height='64px' display='flex' alignItems='center' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            {t('new_order')}
          </Typography>
          <Button onClick={clearForm}>{t('clear')}</Button>
          <Button variant='contained' type="submit" disabled={!(submitEnabled && noProhibited)}>{t('place_order').toUpperCase()}</Button>
        </Box>

        <Grid container spacing={2} padding={2}>
          <Grid size={12}>
            <Typography fontWeight='bold'>{t('parcel_info')}</Typography>
          </Grid>
          <Grid size={5}>
            <RadioGroup row value={unitType} onChange={handleUnits} sx={{ alignItems: 'center' }}>
              <Typography variant='body2' marginRight={2}>{t('unit')}</Typography>
              <FormControlLabel value="imperial"
                control={<Radio />}
                label={<Typography variant='body2'>{t('imperial_units')}</Typography>}
              />
              <FormControlLabel value="metric"
                control={<Radio />}
                label={<Typography variant='body2'>{t('metric_system')}</Typography>}
              />
            </RadioGroup>
          </Grid>
          <Grid size={7}>
            <FormControlLabel
              control={<Checkbox checked={noProhibited} onClick={() => setNoProhibited(!noProhibited)} size="small" />}
              label={<Typography variant='body2' color='secondary'>{t('prohibited_items_check')}</Typography>}
            />
            <InfoIcon sx={{ verticalAlign: 'middle', cursor: 'pointer' }} color='secondary' onClick={() => setInfoOpen(true)} fontSize="small" />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('parcel_weight') + "*"}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    {weightUnit}
                  </InputAdornment>
              }}
              variant="outlined"
              id="parcelWeight"
              name="parcelWeight"
              value={orderInfo.parcelWeight}
              onChange={handleInputChange}
              error={orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight)}
              helperText={orderInfo.parcelWeight !== "" && isNaN(orderInfo.parcelWeight) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('parcel_length') + "*"}
              variant="outlined"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    {dimUnit}
                  </InputAdornment>
              }}
              id="parcelLength"
              name="parcelLength"
              value={orderInfo.parcelLength}
              onChange={handleInputChange}
              error={orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength)}
              helperText={orderInfo.parcelLength !== "" && isNaN(orderInfo.parcelLength) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('parcel_width') + "*"}
              variant="outlined"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    {dimUnit}
                  </InputAdornment>
              }}
              id="parcelWidth"
              name="parcelWidth"
              value={orderInfo.parcelWidth}
              onChange={handleInputChange}
              error={orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth)}
              helperText={orderInfo.parcelWidth !== "" && isNaN(orderInfo.parcelWidth) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('parcel_height') + "*"}
              variant="outlined"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    {dimUnit}
                  </InputAdornment>
              }}
              id="parcelHeight"
              name="parcelHeight"
              value={orderInfo.parcelHeight}
              onChange={handleInputChange}
              error={orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight)}
              helperText={orderInfo.parcelHeight !== "" && isNaN(orderInfo.parcelHeight) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>

          <Grid size={12}>
            <Typography fontWeight='bold'>{t('sender_info')}</Typography>
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('name') + "*"}
              variant="outlined"
              id="senderName"
              name="senderName"
              value={orderInfo.senderName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('phone')}
              variant="outlined"
              id="senderPhone"
              name="senderPhone"
              value={orderInfo.senderPhone}
              onChange={handleInputChange}
              error={orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10}
              helperText={orderInfo.senderPhone !== "" && orderInfo.senderPhone.length < 10 ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label={t('email') + "*"}
              variant="outlined"
              id="senderEmail"
              name="senderEmail"
              value={orderInfo.senderEmail}
              onChange={handleInputChange}
              error={orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail)}
              helperText={orderInfo.senderEmail !== "" && !EMAIL_PATTERN.test(orderInfo.senderEmail) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('unitNo')}
              variant="outlined"
              id="senderUnit"
              name="senderUnit"
              value={orderInfo.senderUnit}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={4}>
            <TextField
              style={{ flex: 3.3 }}
              label={t('street_addr') + "*"}
              variant="outlined"
              id="senderAddress"
              name="senderAddress"
              value={orderInfo.senderAddress}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('city') + "*"}
              variant="outlined"
              id="senderCity"
              name="senderCity"
              value={orderInfo.senderCity}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              select
              label={t('province') + "*"}
              variant="outlined"
              id="senderProvince"
              name="senderProvince"
              value={orderInfo.senderProvince ? orderInfo.senderProvince : " "}
              onChange={handleInputChange}
              fullWidth>
              {Object.keys(provinceList).map((province) => {
                return <MenuItem value={province} key={province}>{provinceList[province]}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('postal_code') + "*"}
              variant="outlined"
              id="senderPostalCode"
              name="senderPostalCode"
              value={orderInfo.senderPostalCode}
              onChange={handleInputChange}
              error={orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode)}
              helperText={orderInfo.senderPostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.senderPostalCode) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>

          <Grid size={12}>
            <Typography fontWeight='bold'>{t('consignee_info')}</Typography>
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('name') + "*"}
              variant="outlined"
              id="consigneeName"
              name="consigneeName"
              value={orderInfo.consigneeName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={3}>
            <TextField
              label={t('phone') + "*"}
              variant="outlined"
              id="consigneePhone"
              name="consigneePhone"
              value={orderInfo.consigneePhone}
              onChange={handleInputChange}
              error={orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10}
              helperText={orderInfo.consigneePhone !== "" && orderInfo.consigneePhone.length < 10 ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label={t('email')}
              variant="outlined"
              id="consigneeEmail"
              name="consigneeEmail"
              value={orderInfo.consigneeEmail}
              onChange={handleInputChange}
              error={orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail)}
              helperText={orderInfo.consigneeEmail !== "" && !EMAIL_PATTERN.test(orderInfo.consigneeEmail) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('unitNo')}
              variant="outlined"
              id="consigneeUnit"
              name="consigneeUnit"
              value={orderInfo.consigneeUnit}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={4}>
            <TextField
              label={t('street_addr') + "*"}
              variant="outlined"
              id="consigneeAddr"
              name="consigneeAddr"
              value={orderInfo.consigneeAddr}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('city') + "*"}
              variant="outlined"
              id="consigneeCity"
              name="consigneeCity"
              value={orderInfo.consigneeCity}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              select
              label={t('province') + "*"}
              variant="outlined"
              id="consigneeProvince"
              name="consigneeProvince"
              value={orderInfo.consigneeProvince ? orderInfo.consigneeProvince : " "}
              onChange={handleInputChange}
              fullWidth>
              {Object.keys(provinceList).map((province) => {
                return <MenuItem value={province} key={province}>{provinceList[province]}</MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('postal_code') + "*"}
              variant="outlined"
              id="consigneePostalCode"
              name="consigneePostalCode"
              value={orderInfo.consigneePostalCode}
              onChange={handleInputChange}
              error={orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode)}
              helperText={orderInfo.consigneePostalCode !== "" && !POSTAL_CODE_PATTERN.test(orderInfo.consigneePostalCode) ? "Invalid Format" : ""}
              fullWidth
            />
          </Grid>
          <Grid size={2}>
            <TextField
              label={t('buzz_no')}
              variant="outlined"
              id="consigneeBuzzNo"
              name="consigneeBuzzNo"
              value={orderInfo.consigneeBuzzNo}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid size={10}>
            <TextField
              label={t('notes')}
              variant="outlined"
              id="Notes"
              name="Notes"
              value={orderInfo.Notes}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>

      <Dialog maxWidth='sm' fullWidth={true} open={confirmOrder}>
        <DialogTitle>{t('confirm_order')}</DialogTitle>
        <DialogContent dividers>
          <div style={{ float: "left", width: '60%' }}>
            <h4>{t('parcel_info')}</h4>
            <pre>
              Weight:         {orderInfo.parcelWeight}{weightUnit}<br />
              Height:         {orderInfo.parcelHeight}{dimUnit}<br />
              Width:          {orderInfo.parcelWidth}{dimUnit}<br />
              Length:         {orderInfo.parcelLength}{dimUnit}<br />
            </pre>
            <h4>{t('sender_info')}</h4>
            <pre>
              Name:           {orderInfo.senderName}<br />
              Phone:          {orderInfo.senderPhone}<br />
              E-mail:         {orderInfo.senderEmail}<br />
              Unit:           {orderInfo.senderUnit}<br />
              Address:        {orderInfo.senderAddress}<br />
              City:           {orderInfo.senderCity}<br />
              Province:       {orderInfo.senderProvince}<br />
              Postal Code:    {orderInfo.senderPostalCode}<br />
            </pre>
            <h4>{t('consignee_info')}</h4>
            <pre>
              Name:           {orderInfo.consigneeName}<br />
              Phone:          {orderInfo.consigneePhone}<br />
              E-mail:         {orderInfo.consigneeEmail}<br />
              Unit:           {orderInfo.consigneeUnit}<br />
              Address:        {orderInfo.consigneeAddr}<br />
              City:           {orderInfo.consigneeCity}<br />
              Province:       {orderInfo.consigneeProvince}<br />
              Postal Code:    {orderInfo.consigneePostalCode}<br />
              Buzz#:          {orderInfo.consigneeBuzzNo}<br />
              Notes:          {orderInfo.Notes}<br />
            </pre>
          </div>
          <div style={{ float: "left", width: '40%' }}>
            <h4>{t('freight_fee')}</h4>
            <Typography variant='h3' color='#ff9d00'>{price}</Typography>
            <Typography color='text.secondary'>{eta}</Typography>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setConfirmOrder(false)}>CANCEL</Button>
          <Button variant='contained' autoFocus onClick={() => { setPaymentCollection(true); setConfirmOrder(false); }}>NEXT</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth={true} open={paymentCollection}>
        <DialogTitle>{t('payment_collection')}</DialogTitle>
        <DialogContent dividers>
          <div>
            <b>{t('collect_fee')}</b>
            <Typography variant='h3' color='#ff9d00'>{price}</Typography>
            <Typography>{t('collect_fee_notice')}</Typography>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setPaymentCollection(false)}>Cancel</Button>
          <Button variant='contained' autoFocus onClick={() => createOrder()}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' open={labels}>
        <DialogTitle>{t('print_label')}</DialogTitle>
        <DialogContent dividers>
          <object width="100%" height="500" data={labelUrl}>
            <p>Cannot Render Label, Please click print to view label</p>
          </object>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button variant='contained' autoFocus onClick={() => { getOrderInvoice(); }}>NEXT</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' open={invoice} >
        <DialogTitle>{t('print_invoice')}</DialogTitle>
        <DialogContent dividers>
          <object width="100%" height="500" data={invoiceURL}>
            <p>Cannot Render Invoice, Please click print to view Invoice</p>
          </object>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button variant='contained' autoFocus onClick={() => setInvoiceOpen(false)}>COMPLETE</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth={true} open={infoOpen} onClose={() => setInfoOpen(false)}>
        <DialogTitle>{t('prohibited_items')}</DialogTitle>
        <DialogContent dividers>
          <Typography>{t('prohibited_notes_1')}</Typography>
          <ul>
            {t('prohibited_list', { returnObjects: true }).map((val) => (<li key={val}>{val}</li>))}
          </ul>
          <Typography>{t('prohibited_notes_2')}</Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button onClick={() => setInfoOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </Card>
  );
}
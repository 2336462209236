import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Grid, Card, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { printLabel, getInvoice, cancelOrderByTno } from '../../actions/postalOutletActions';
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import FullscreenLoading from '../../components/FullscreenLoading';

export default function PostalOutletOrderDisplay(props) {
  const { orderList, currentPage, setCurrentPage, perPage, setPerPage, paging, loading, handleSearch } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('postalOutlet');
  const [orderDetails, openOrderDetails] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [loadingBackdrop, setLoading] = useState(false);
  const [labelURL, setLabelURL] = useState({});
  const [invoiceURL, setInvoiceURL] = useState({});
  const [labels, setLabelOpen] = useState(false);
  const [invoice, setInvoiceOpen] = useState(false);
  const [cancelOrderDialog, openCancelOrder] = useState(false);

  const postalOutletColumns = [
    {
      field: 'order_sn',
      headerName: "Invoice No.",
      width: 200,
    },
    {
      field: 'add_time',
      headerName: "Invoice Time",
      width: 200,

    },
    {
      field: 'tno',
      headerName: "Tracking No.",
      width: 200,
    },
    {
      field: 'package_type',
      headerName: "Package Type",
      width: 170,
      valueGetter: () => 'Parcel'
    },
    {
      field: 'city',
      headerName: "Ship To",
      width: 142,
    },
    {
      field: 'package_weight',
      headerName: "Package Weight",
      width: 220,
      valueGetter: (value, row) => `${row.total_weight} ${row.weight_uom}`
    },
    {
      field: 'dimensions',
      headerName: "Dimensions",
      width: 220,
      valueGetter: (value, row) => `${parseFloat(row.length).toFixed(2)}${row.dimension_uom}/${parseFloat(row.width).toFixed(2)}${row.dimension_uom}/${parseFloat(row.height).toFixed(2)}${row.dimension_uom}`
    },
    {
      field: 'total_before_tax',
      headerName: "Freight Fee",
      width: 192,
      valueGetter: (value) => `$${parseFloat(value).toFixed(2)}`
    }, {
      field: 'tax',
      headerName: "Taxes",
      width: 192,
      valueGetter: (value) => `$${parseFloat(value).toFixed(2)}`
    },
  ]

  function createOrderRows() {
    let orders = [];
    if (orderList.length > 0) {
      orderList.forEach((row) => {
        const { order_id, ...rest } = row
        orders.push({ id: order_id, order_id, ...rest })
      })
    }
    return orders;
  }

  function handleRowClick(params) {
    setOrderInfo(params.row);
  }

  function getOrderInvoice() {
    setLoading(true);
    dispatch(getInvoice({ invoiceId: orderInfo.tno, invoiceType: 1, invoiceFormat: 'pdf' }))
      .then((res) => {
        const base64 = res.data[0].invoiceContent;
        const url = 'data:application/pdf;base64,' + base64
        setInvoiceURL(url);
      }).catch((err) => {
        setLoading(false);
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
      })
  }

  function getOrderLabel() {
    setLoading(true);
    dispatch(printLabel({ packageId: orderInfo.tno, labelType: 6, labelFormat: 'pdf' }))
      .then((res) => {
        const base64 = res.data[0].labelContent;
        const url = 'data:application/pdf;base64,' + base64
        setLabelURL(url);
      }).catch((err) => {
        setLoading(false);
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
      })
  }

  function cancelOrder() {
    setLoading(true);
    dispatch(cancelOrderByTno(orderInfo.tno))
      .then((res) => {
        setLoading(false);
        openCancelOrder(false);
        dispatch(showAlertSnackbar({ type: "success", message: res.ret_msg }));
        handleSearch();

      }).catch((err) => {
        setLoading(false);
        dispatch(showAlertSnackbar({ type: "error", message: err.message }));
      })
  }

  function isEmpty(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
  }

  useEffect(() => {
    if (!isEmpty(orderInfo)) {
      openOrderDetails(true);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (!isEmpty(labelURL)) {
      setLoading(false);
      setLabelOpen(true);
    }
  }, [labelURL]);

  useEffect(() => {
    if (!isEmpty(invoiceURL)) {
      setLoading(false);
      setInvoiceOpen(true);
    }
  }, [invoiceURL]);

  return (
    <Card variant='outlined'>
      <Box display='flex' padding={2}>
        <Typography variant='subtitle2'>
          Order List
        </Typography>
      </Box>
      <DataGrid
        autoHeight
        loading={loading}
        rows={loading ? [] : createOrderRows()}
        columns={postalOutletColumns}
        rowCount={paging.totalCount}
        pagination
        paginationMode="server"
        paginationModel={{
          page: currentPage,
          pageSize: perPage
        }}
        onPaginationModelChange={(model) => {
          setCurrentPage(model.page)
          setPerPage(model.pageSize)
        }}
        pageSizeOptions={[10, 20, 30]}
        onRowClick={handleRowClick}
        sx={{ border: 'none', borderRadius: 0 }}
      />
      <Dialog maxWidth='md' fullWidth={true} open={orderDetails} onClose={() => openOrderDetails(false)}>
        <DialogTitle>{t('order_detail')}</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='subtitle2' marginY={1}>{t('order_info')}</Typography>
              <Grid container spacing={0}>
                <Grid container item xs={12} spacing={0}>
                  <Grid item xs={3}><Typography color='text.secondary'>Tracking No:</Typography></Grid>
                  <Grid item xs={3}>{orderInfo.tno}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Invoice No:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.order_sn}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Invoice Date:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.add_time ? (orderInfo.add_time).split(" ", 1) : ""}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Invoice Time:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.add_time ? (orderInfo.add_time).split(" ")[1] : ""}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Freight Fee:</Typography></Grid>
                  <Grid item xs={5}>${orderInfo.shipping_fee}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Taxes:</Typography></Grid>
                  <Grid item xs={5}>${orderInfo.tax}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2' marginY={1}>{t('parcel_info')}</Typography>
              <Grid container spacing={0}>
                <Grid container item xs={12} spacing={0}>
                  <Grid item xs={3}><Typography color='text.secondary'>Weight:</Typography></Grid>
                  <Grid item xs={3}>{orderInfo.total_weight}kg</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Height:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.height}cm</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Width:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.width}cm</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Length:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.length}cm</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant='subtitle2' marginY={1}>{t('sender_info')}</Typography>
              <Grid container spacing={0}>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Name:</Typography></Grid>
                  <Grid item xs={3}>{orderInfo.start_consignee}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Phone:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.start_mobile}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>E-mail:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.start_email}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Address:</Typography></Grid>
                  <Grid item xs={8}>{orderInfo.start_address}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Postal Code:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.start_zipcode}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle2' marginY={1}>{t('consignee_info')}</Typography>
              <Grid container spacing={0}>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Name:</Typography></Grid>
                  <Grid item xs={3}>{orderInfo.consignee}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Phone:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.mobile}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>E-mail:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.email}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Address:</Typography></Grid>
                  <Grid item xs={9}>{orderInfo.address}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Province:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.province_code}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Postal Code:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.zipcode}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Buzz#:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.buzz_code}</Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={3}><Typography color='text.secondary'>Notes:</Typography></Grid>
                  <Grid item xs={5}>{orderInfo.driver_notes}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => openOrderDetails(false)}>BACK</Button>
          <Button variant='contained' color='secondary' autoFocus onClick={() => getOrderLabel()}>PRINT LABEL</Button>
          <Button variant='contained' autoFocus onClick={() => getOrderInvoice()}>PRINT INVOICE</Button>
          <Button variant='contained' color='error' autoFocus onClick={() => { openOrderDetails(false); openCancelOrder(true); }}>CANCEL ORDER</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth open={labels} onClose={() => setLabelOpen(false)}>
        <DialogTitle>{t('print_label')}</DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <object width="100%" height="500" type="application/pdf" data={labelURL}>
            <p>Cannot Render Label, Please click print to view label</p>
          </object>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setLabelOpen(false)}>BACK</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth open={invoice} onClose={() => setInvoiceOpen(false)}>
        <DialogTitle>{t('print_invoice')}</DialogTitle>
        <DialogContent>
          <object width="100%" height="500" type="application/pdf" data={invoiceURL}>
            <p>Cannot Render Invoice, Please click print to view Invoice</p>
          </object>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setInvoiceOpen(false)}>BACK</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='sm' fullWidth open={cancelOrderDialog} onClose={() => openCancelOrder(false)}>
        <DialogTitle>{t('cancel_order')}</DialogTitle>
        <DialogContent>
          {t('cancel_order_info')}
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => openCancelOrder(false)}>CANCEL</Button>
          <Button autoFocus onClick={(e) => cancelOrder()}>YES</Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loadingBackdrop} />
    </Card>
  );
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from './utilities/store';
import "./i18n";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Disable some logs on release
if (process.env.NODE_ENV !== "development"){
  console.log = () => {};
  console.info = () => {};
}
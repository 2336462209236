import { shangjiaServerInstance } from "../utilities/instances";
import {
  GET_SERVICE_POINT_PICKUP_RATIO,
  GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS,
  GET_SERVICE_POINT_PICKUP_RATIO_FAIL,
  BASIC_INFO_LOAD,
  BASIC_INFO_LOAD_SUCCESS,
  BASIC_INFO_LOAD_FAIL,
  SEARCH_SERVICE_POINT,
  SEARCH_SERVICE_POINT_SUCCESS,
  SEARCH_SERVICE_POINT_FAIL,
  OPEN_CLOSE_SERVICE_POINT,
  OPEN_CLOSE_SERVICE_POINT_SUCCESS,
  OPEN_CLOSE_SERVICE_POINT_FAIL,
  EDIT_SERVICE_POINT,
  EDIT_SERVICE_POINT_SUCCESS,
  EDIT_SERVICE_POINT_FAIL,
  ADD_SERVICE_POINT,
  ADD_SERVICE_POINT_SUCCESS,
  ADD_SERVICE_POINT_FAIL,
  GET_DRIVER_DROP_OFF_HISTORY,
  GET_DRIVER_DROP_OFF_HISTORY_SUCCESS,
  GET_DRIVER_DROP_OFF_HISTORY_FAIL,
  HANDLE_SERVICE_POINT
} from "../actions/types"

export function handleServicePoint(servicePoint) {
  return function (dispatch) {
    dispatch({ type: HANDLE_SERVICE_POINT })
    return (
      dispatch({
        type: HANDLE_SERVICE_POINT,
        payload: servicePoint
      })
    )
  }
}

export function getDriverDropOffHistory(startTime, endTime, warehouse, servicePointId) {
  return function (dispatch) {
    dispatch({ type: GET_DRIVER_DROP_OFF_HISTORY })
    return shangjiaServerInstance.get(`/business/getservicepointdropoffstatistic?start_time=${startTime}&end_time=${endTime}&warehouse=${warehouse}`)
      .then(({ data }) => {
        dispatch({
          type: GET_DRIVER_DROP_OFF_HISTORY_SUCCESS,
          payload: data.data,
        })
        return Promise.resolve(data.data);
      }).catch(error => {
        dispatch({
          type: GET_DRIVER_DROP_OFF_HISTORY_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function addServicePoint(name, params) {
  return function (dispatch) {
    dispatch({ type: ADD_SERVICE_POINT })
    return shangjiaServerInstance.post(`/servicepointmanagement/addservicepoint`, {
      name: name,
      code: '',
      ...params
    })
      .then(({ data }) => {
        dispatch({
          type: ADD_SERVICE_POINT_SUCCESS,
          payload: data,
        })
        return Promise.resolve();
      }).catch(error => {
        dispatch({
          type: ADD_SERVICE_POINT_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function editServicePoint(name, params) {
  return function (dispatch) {
    dispatch({ type: EDIT_SERVICE_POINT })
    return shangjiaServerInstance.post(`/servicepointmanagement/editservicepoint`, {
      name: name,
      ...params
    })
      .then(({ data }) => {
        dispatch({
          type: EDIT_SERVICE_POINT_SUCCESS,
          payload: data,
        })
        return Promise.resolve();
      }).catch(error => {
        dispatch({
          type: EDIT_SERVICE_POINT_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function openCloseServicePoint(name, isActive) {
  return function (dispatch) {
    dispatch({ type: OPEN_CLOSE_SERVICE_POINT })
    return shangjiaServerInstance.post(`/servicepointmanagement/opencloseservicepoint`, {
      name: name,
      is_active: isActive
    })
      .then(({ data }) => {
        dispatch({
          type: OPEN_CLOSE_SERVICE_POINT_SUCCESS,
          payload: data,
        })
        return Promise.resolve();
      }).catch(error => {
        dispatch({
          type: OPEN_CLOSE_SERVICE_POINT_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function searchServicePoint(warehouse, isActive, id) {
  return function (dispatch) {
    if (!id) {
      dispatch({ type: SEARCH_SERVICE_POINT })
      return shangjiaServerInstance.post(`servicepointmanagement/searchservicepoint`, {
        warehouse: warehouse,
        is_active: isActive,
      })
        .then(({ data }) => {
          dispatch({
            type: SEARCH_SERVICE_POINT_SUCCESS,
            payload: data.data,
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type: SEARCH_SERVICE_POINT_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    } else {
      dispatch({ type: SEARCH_SERVICE_POINT })
      return shangjiaServerInstance.post(`servicepointmanagement/searchservicepoint`, {
        warehouse: warehouse,
        is_active: 1,
        name: id
      })
        .then(({ data }) => {
          dispatch({
            type: SEARCH_SERVICE_POINT_SUCCESS,
            payload: data,
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type: SEARCH_SERVICE_POINT_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    }
  }
}

export function getBasicInfo() {
  return function (dispatch) {
    dispatch({ type: BASIC_INFO_LOAD })
    return shangjiaServerInstance.get(`business/getbasicinfo`)
      .then(({ data }) => {
        dispatch({
          type: BASIC_INFO_LOAD_SUCCESS,
          payload: data,
        })
        return Promise.resolve();
      }).catch(error => {
        dispatch({
          type: BASIC_INFO_LOAD_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}

export function getServicePointPickupRatio(startTime, endTime, warehouse, id) {
  return function (dispatch) {
    dispatch({ type: GET_SERVICE_POINT_PICKUP_RATIO })
    if (id) {
      return shangjiaServerInstance.get(`business/getservicepointpickupratio?start_time=${startTime}&end_time=${endTime}&service_point_id=${id}&warehouse=${warehouse}`)
        .then(({ data }) => {
          dispatch({
            type: GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS,
            payload: data.data
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type: GET_SERVICE_POINT_PICKUP_RATIO_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    } else {
      return shangjiaServerInstance.get(`business/getservicepointpickupratio?start_time=${startTime}&end_time=${endTime}&warehouse=${warehouse}`)
        .then(({ data }) => {
          dispatch({
            type: GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS,
            payload: data.data
          })
          return Promise.resolve(data.data);
        }).catch(error => {
          dispatch({
            type: GET_SERVICE_POINT_PICKUP_RATIO_FAIL,
            payload: error
          })
          return Promise.reject(error);
        })
    }
  }
}
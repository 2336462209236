import { shangjiaServerInstance } from "../utilities/instances";
import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAIL,
  CREATE_BUSINESS_ORDER,
  CREATE_BUSINESS_ORDER_SUCCESS,
  CREATE_BUSINESS_ORDER_FAIL,
  ESTIMATE_SHIPPING,
  ESTIMATE_SHIPPING_SUCCESS,
  ESTIMATE_SHIPPING_FAIL,
  PRINT_LABEL,
  PRINT_LABEL_SUCCESS,
  PRINT_LABEL_FAIL,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
} from "./types"

export function getInvoice(params) {
  return function (dispatch) {
    dispatch({ type: GET_INVOICE })
    return shangjiaServerInstance.post(`/orders/getinvoice`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: GET_INVOICE_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: GET_INVOICE_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
export function estimateShipping(params) {
  return function (dispatch) {
    dispatch({ type: ESTIMATE_SHIPPING })
    return shangjiaServerInstance.post(`/orders/estimateshipping`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: ESTIMATE_SHIPPING_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: ESTIMATE_SHIPPING_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
export function printLabel(params) {
  return function (dispatch) {
    dispatch({ type: PRINT_LABEL })
    return shangjiaServerInstance.post(`/orders/printlabel`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: PRINT_LABEL_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: PRINT_LABEL_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
export function createBusinessOrder(params) {
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_ORDER })
    return shangjiaServerInstance.post(`/orders/createbusinessorder`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: CREATE_BUSINESS_ORDER_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: CREATE_BUSINESS_ORDER_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
export function cancelOrderByTno(tno) {
  return function (dispatch) {
    dispatch({ type: CANCEL_ORDER })
    return shangjiaServerInstance.post(`/orders/cancelorder?tno=${tno}`)
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data);
      }).catch(error => {
        dispatch({
          type: CANCEL_ORDER_FAIL,
          payload: error
        })
        return Promise.reject(error);
      })
  }
}
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, Stack } from '@mui/material';
import { getMAWBStatistic, getBagStatistic } from '../../actions/prealertStatisticActions';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import moment from 'moment';

function PrealertStatisticSearchDate(props) {
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DDThh:mm'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DDThh:mm'));

  async function handleSearch() {
    props.setLoading(true);
    try {
      let response = [];
      if (props.type === 'MAWB') {
        response = await dispatch(getMAWBStatistic(moment(dateFrom).valueOf() / 1000, moment(dateTo).valueOf() / 1000)).catch(e => {
          throw e.message;
        })
      } else if (props.type === 'BAG') {
        response = await dispatch(getBagStatistic(moment(dateFrom).valueOf() / 1000, moment(dateTo).valueOf() / 1000)).catch(e => {
          throw e.message;
        })
      }
      props.setData(response);
      dispatch(showAlertSnackbar({message: 'Fetch Data Success', type: 'success'}));
    } catch (e) {
      dispatch(showAlertSnackbar({message: e, type: 'error'}));
    } finally {
      props.setLoading(false);
    }
  }

  return (
    <Stack direction='row' spacing={2}>
      <TextField
        id="start-time"
        label="Start Time"
        type="datetime-local"
        value={dateFrom}
        onChange={(e)=>setDateFrom(e.target.value)}
        size='small'
      />
      <TextField
        id="end-time"
        label="End Time"
        type="datetime-local"
        value={dateTo}
        onChange={(e)=>setDateTo(e.target.value)}
        size='small'
      />
      <Button
        onClick={handleSearch}
        variant="contained"
        color="primary">
        {'Fetch'}
      </Button>
    </Stack>
  );
}

export default PrealertStatisticSearchDate;
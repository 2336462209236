import { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { editServicePoint } from "../../actions/servicePointActions";
import { showAlertSnackbar } from "../../reducers/sliceReducer";
import ServicePointWarehouse from './ServicePointWarehouse';
import ServicePointSearchKeyword from './ServicePointSearchKeyword'
import ServicePointForm from "./ServicePointForm";

export default function ServicePointEdit() {
  const [warehouse, setWarehouse] = useState(window.localStorage.getItem('warehouse'));
  const [info, setInfo] = useState()
  const dispatch = useDispatch

  function edit(serialNo, params) {
    dispatch(editServicePoint(serialNo, params))
      .then(() => {
        dispatch(showAlertSnackbar({ message: 'EDITED', type: 'success' }));
      })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'EDIT FAILED', type: 'error' }));
      })
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' paddingBottom={2}>
        <ServicePointSearchKeyword 
          type={"apiSearch"} 
          handleServicePointInfo={setInfo}
        />
        <ServicePointWarehouse
          onChangeWarehouse={setWarehouse}
        />
      </Box>
      <ServicePointForm
        warehouse={warehouse}
        info={info}
        onSubmit={edit}
      />
    </>
  )
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shangjiaServerInstance } from '../../utilities/instances';
import { Dialog, DialogContent, DialogTitle, LinearProgress, MenuItem, Select, Snackbar, Box, Button, Alert, Paper, InputBase, Divider, IconButton, Typography, Stack } from '@mui/material';
import { showAlertSnackbar } from '../../reducers/sliceReducer';
import { fetchDriverList, generateSendT4a, searchDriver } from '../../actions/t4aActions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import DriverTable from './DriverTable';
import FullscreenLoading from '../../components/FullscreenLoading';

const companyList = [
  {
    name: 'Uni BC',
    code: '1'
  },
  {
    name: 'Uni ON',
    code: '2'
  },
  {
    name: 'Uni QC',
    code: '3'
  }
]

function T4A() {
  const dispatch = useDispatch();
  const num = useSelector(state => state.t4a.getT4aTotal);

  const [rowsSelected, setRowsSelected] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [startUpload, setStartUpload] = useState(false);
  const [input, setInput] = useState('');
  const [company, setCompany] = useState(companyList[0].code);
  const [loading, setLoading] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleFailClose = (event, reason) => {
    navigator.clipboard.writeText(errorMsg.toString())
    dispatch(showAlertSnackbar({ message: 'Copy error message(s) successfully!', type: 'success' }))
    if (reason === 'clickaway') {
      return;
    }
    setFailOpen(false);
  };

  function CustomizedInputBase() {

    function isNumber(n) {
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }

    function handleDriverSearch() {
      if (!input) {
        dispatch(fetchDriverList(1, 20));
      } else {
        if (isNumber(input)) {
          dispatch(searchDriver('driver_id', input));
        } else {
          dispatch(searchDriver('name', input));
        }
      }
    }

    return (
      <Paper
        component="form"
        sx={{
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400
        }}>
        <InputBase
          placeholder="Enter driver name or ID to search"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ marginLeft: 1, flex: 1 }}
        />
        <Divider 
          orientation="vertical"
          style={{
            height: 28,
            margin: 4,
            backgroundColor: 'black'
          }}
        />
        <IconButton
          color="primary"
          onClick={handleDriverSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
  function handleSubmit() {
    setStartUpload(true);
    let formData = new FormData();
    formData.append(' t4_file', selectedFile);
    shangjiaServerInstance.post(
      `/account/importdrivert4a`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
      .then(() => {
        setProgress(100);
        setSelectedFile(undefined);
        dispatch(showAlertSnackbar({ message: 'Imported successfully!', type: 'success' }));
      })
      .catch((err) => {
        setProgress(100);
        setSelectedFile(undefined);
        setErrorMsg(err.message);
        setFailOpen(true);
        dispatch(showAlertSnackbar({ message: 'Failed to import!', type: 'error' }));
      })
  }
  function handleClose() {
    setOpenModal(false);
    setStartUpload(false);
  }
  function handleGenerateSend() {
    if (rowsSelected) {
      if (rowsSelected.length > 0) {
        dispatch(generateSendT4a(rowsSelected.join()))
          .then((res) => {
            dispatch(showAlertSnackbar({ message: 'Generate and send successfully!', type: 'success' }))
          })
          .catch(() => {
            dispatch(showAlertSnackbar({ message: 'Failed to generate and send T4A!', type: 'error' }))
          })
      } else {
        dispatch(showAlertSnackbar({ message: 'Please select at least 1 driver!', type: 'error' }))
      }
    } else {
      dispatch(showAlertSnackbar({ message: 'Please select at least 1 driver!', type: 'error' }))
    }
  }
  function handleDownload() {
    setLoading(true);
    const axios = require('axios');
    const baseURL = 'https://sj.uniexpress.ca';
    const getXML = () => {
      return axios.get(`${baseURL}/account/driverT4aXml?company=${company}`, {
        responseType: 'blob',
      }).then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Tax-${company}.xml`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
    getXML();
  }

  useEffect(() => {
    if (selectedFile) {
      setOpenModal(true);
    }
  }, [selectedFile])
  useEffect(() => {
    if (!openModal) {
      setSelectedFile(undefined);
    }
    if (openModal) {
      setFailOpen(false);
    }
  }, [openModal])
  useEffect(() => {
    if (!startUpload) {
      setProgress(0)
    }
  }, [startUpload]);
  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setOpenModal(false);
        window.location.reload();
      }, 5000);
    }
  }, [progress])

  return (
    <>
      <Snackbar open={failOpen} autoHideDuration={10000} onClose={handleFailClose}>
        <Alert onClose={handleFailClose} severity="error">
          {
            errorMsg &&
            (
              Array.isArray(errorMsg) ?
                errorMsg.map((err) => (
                  <div>{err}</div>
                )) :
                <div>{errorMsg}</div>
            )
          }
        </Alert>
      </Snackbar>
      <Box flexGrow={1}>
        <Box display='flex' justifyContent='space-between' marginBottom={2}>
          <Box display='flex' alignItems='center'>
            {
              num &&
              num[0] &&
              <span style={{ marginRight: 15 }}>
                Total drivers: {num[0].total || 0}
              </span>
            }
            <span>&#x0007C;</span>
            {
              num &&
              num[0] &&
              <span style={{ marginLeft: 15 }}>
                T4A sent : {num[0].sent || 0}
              </span>
            }
          </Box>
          <Box style={{ border: '1px solid black', borderRadius: 4 }}>
            <CustomizedInputBase />
          </Box>
        </Box>
        <Divider />
        <Stack direction='row' alignItems='center' spacing={1} paddingY={2}>
          <Typography color='text.secondary'>
            Selected: {rowsSelected.length}
          </Typography>
          <Button
            onClick={handleGenerateSend}
            sx={{ color: '#ff9d00', fontWeight: 'bold' }}>
            Generate and Send T4A
          </Button>
          <Button
            onClick={handleDownload}
            sx={{ color: '#ff9d00', fontWeight: 'bold' }}>
            Download Tax File
          </Button>
          <Select
            disableUnderline={true}
            defaultValue={companyList[0].code}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            size='small'>
            {
              companyList.map((company) => (
                <MenuItem value={company.code}>{company.name}</MenuItem>
              ))
            }
          </Select>
          <input
            color="primary"
            type="file"
            id="t4a-file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ display: 'none' }}
          />
          <label htmlFor="t4a-file" style={{ marginLeft: 'auto' }}>
            <Button
              component="span"
              startIcon={<CloudUploadIcon />}
              sx={{
                fontSize: 18,
                fontWeight: 'bold'
              }}>
              Import data
            </Button>
          </label>
        </Stack>
        <DriverTable input={input} handleSelectedRows={setRowsSelected} />
      </Box>
      <Dialog
        open={openModal}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth>
        <DialogTitle
          sx={{
            color: '#a3a4a0',
            fontSize: 22,
            fontWeight: 500
          }}>
          Import drivers' information
        </DialogTitle>
        <DialogContent>
          {
            selectedFile &&
            <Typography sx={{ marginBottom: 2 }}>{selectedFile.name}</Typography>
          }
          <LinearProgress
            variant="determinate"
            value={progress}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
            <Button
              onClick={handleClose}
              disabled={progress !== 0}>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              disabled={progress !== 0}>
              Import
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <FullscreenLoading open={loading} />
    </>
  );
}

export default T4A
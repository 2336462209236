import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box, Divider, Checkbox, TextField, Button, Card, IconButton,
  List, ListItemButton, ListItem, ListItemText, ListItemIcon,
  Dialog, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import {
  updateUserInformation, updateUserRoles,
  getAbilityListByUser, deleteUser
} from '../../actions/userManagementActions';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CreateUser from "./CreateUser";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

// const SYSTEM_ADMINISTRATOR = 'System Administrator';
const SYSTEM_ADMINISTRATOR_ID = 1;

export default function Users(props) {
  const {
    rolesList, abilitiesList, usersList,
    customer, fetchData, setLoading, showSnack,
  } = props;
  const { t } = useTranslation('userManagement');
  const dispatch = useDispatch();
  // const tokenContents = useToken();
  // const customerList = useSelector(state => state.managementReducer.customers);
  // const authorizedCustomers = tokenContents.customer;
  // const userRoles = tokenContents.roles;

  const [selectedId, setSelectedId] = useState(0);
  const [selectedUserCustomer, setSelectedUserCustomer] = useState([]);
  const [selectedUserAbilities, setSelectedUserAbilities] = useState([]);
  const [filteredUserlist, setFilteredUserList] = useState([]);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // const userIsSystemAdministrator = tokenContents.roles.includes(SYSTEM_ADMINISTRATOR);

  useEffect(() => {
    setSelectedId(-1);
  }, [customer]);

  useEffect(() => {
    setFilteredUserList(usersList);
  }, [usersList]);

  const parseRoleListByUser = (roles, user, invert) => {
    if (!user) return [];

    return roles.filter(
      (role) => {
        let isRoleInUserRoles = user.roles.map(
          (role) => (role.role_id)
        ).includes(role.id);

        return !!(isRoleInUserRoles ^= invert);
      }
    )
  };

  const handleDeleteUser = async (userId) => {
    try {
      setLoading(true);
      await Promise.all([
        dispatch(deleteUser(userId)),
      ]);
      setSelectedId(-1);
      showSnack('success')(t('delete_user_success'));
    } catch (error) {
      showSnack('error')(t('delete_user_failed'));
    } finally {
      await fetchData();
      setDeleteOpen(false);
      setLoading(false);
    }
    /* TODO MVP version don't consider admin yet
    if (userIsSystemAdministrator) {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(deleteUser(roleId)),
        ]);
        setSelectedId(-1);
        showSnack('success')(t('delete_user_success'));
      } catch (error) {
        showSnack('error')(t('delete_user_failed'));
      } finally {
        await fetchData();
        setDeleteOpen(false);
        setLoading(false);
      }
    } else {
      showSnack('error')(t('delete_user_failed'));
    }
    */
  };

  const handleToggle = (value) => {
    if (value.id === SYSTEM_ADMINISTRATOR_ID) return;

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleUserListItemClick = async (user) => {
    if (!user) return;
    setSelectedId(user.id);
    setSelectedUserCustomer(user ? user.customer : []);

    setRight(parseRoleListByUser(rolesList, user, false));
    setLeft(parseRoleListByUser(rolesList, user, true));

    try {
      setLoading(true);
      const res = await dispatch(getAbilityListByUser(user.id));
      setSelectedUserAbilities(res.map(
        (permission) => (permission.ability_id)
      ));
    } catch (error) {
      showSnack('error')(t('network_error'));
    } finally {
      setLoading(false);
    }

  };

  // const handleCustomerListClick = (id) => {
  //   if (selectedUserCustomer.indexOf(id) === -1) {
  //     setSelectedUserCustomer([...selectedUserCustomer, id]);
  //   } else {
  //     setSelectedUserCustomer(
  //       selectedUserCustomer.filter(
  //         (customer) => (customer !== id)
  //       )
  //     );
  //   }
  // };

  const findUserById = () => {
    return usersList.find(user => user.id === selectedId)
  }

  const handleSaveUserClick = async () => {
    if (!findUserById().id) {
      showSnack('error')(t('user_id_not_exist'));
      return;
    }
    try {
      setLoading(true);

      await Promise.all([
        dispatch(updateUserInformation(
          findUserById().id,
          selectedUserCustomer.toString(),
        )),
        dispatch(updateUserRoles(
          findUserById().id,
          right.map((role) => (role.id)).toString(),
        )),
      ]);

      showSnack('success')(t('save_user_permission_success'));
    } catch (error) {
      showSnack('error')(t('save_user_permission_failed'));
    } finally {
      await fetchData();
      setLoading(false);
    }
  };

  const handleFilterUser = (strings) => {
    const filteredList = usersList.filter(user => {
      return user.username.match(new RegExp(strings, 'i'));
    });
    setFilteredUserList(filteredList);
  };

  const customList = (items, section) => (
    <List dense role="list" sx={{ height: '100%', overflow: 'auto' }}>
      {items.map((value) => {
        if (value.id === SYSTEM_ADMINISTRATOR_ID && section === "left") {
          return null;
        } else {
          return (
            <ListItemButton
              key={value.id}
              sx={{ paddingY: 0 }}
              onClick={() => handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disabled={
                    (value.id === SYSTEM_ADMINISTRATOR_ID && section === "right")
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={value.name}
                sx={(value.id === SYSTEM_ADMINISTRATOR_ID) ? { color: '#fa8200' } : undefined}
              />
            </ListItemButton>
          );
        }
      })}
    </List>
  );

  return (<>
    <CreateUser
      open={createOpen}
      setOpen={setCreateOpen}
    />
    <Dialog
      open={deleteOpen}
      onClose={() => setDeleteOpen(false)}>
      <DialogContent>
        <DialogContentText>
          {t('sure_to_delete_user')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={'cancel-delete-user'}
          onClick={() => setDeleteOpen(false)}
          color="primary">
          {t('cancel')}
        </Button>
        <Button
          id={'confirm-delete-user'}
          onClick={() => handleDeleteUser(findUserById()?.id)}
          color="primary"
          autoFocus>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>

    <Box display='flex' flexDirection='row' flex='1 0 auto' height='calc(100vh - 190px)'>
      <Box width='20%' display='flex' flexDirection='column'>
        <Box padding={2}>
          <TextField
            id="filled-search"
            label="Search User"
            type="search"
            variant="outlined"
            onChange={(event) => handleFilterUser(event.target.value)}
            size="small"
            fullWidth
          />
        </Box>
        <Divider />
        <List sx={{ flex: 1, overflow: 'auto' }}>
          {
            filteredUserlist.map(
              (user, index) => (
                <ListItemButton
                  key={user.id}
                  selected={selectedId === user.id}
                  sx={{ paddingY: 0 }}
                  onClick={() => { handleUserListItemClick(user) }}>
                  <ListItemText
                    id={'user-select-' + user.id}
                    primary={user.username}
                  />
                </ListItemButton>
              )
            )
          }
        </List>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box width='60%' display='flex' flexDirection='column'>
        <Box padding={2}>
          <TextField
            variant="outlined"
            size="small"
            value={findUserById()?.username ?? ''}
            label={t('account_name')}
            fullWidth
          />
        </Box>
        <Box flex={1} display='flex' paddingX={2} height='calc(100% - 140px)'>
          <Card variant='outlined' sx={{ width: '45%' }}>
            {customList(left, "left")}
          </Card>
          <Box width='10%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <IconButton
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              sx={{ border: '1px solid lightgray', marginBottom: 1 }}
              size='large'>
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              sx={{ border: '1px solid lightgray' }}
              size='large'>
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
          <Card variant='outlined' sx={{ width: '45%' }}>
            {customList(right, "right")}
          </Card>
        </Box>
        <Box display='flex' flexDirection='row' padding={2}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setCreateOpen(true)}>
            {t('create_user')}
          </Button>
          <Button
            variant='contained'
            color='error'
            // disabled={!userIsSystemAdministrator}
            sx={{ marginLeft: 'auto', marginRight: 1 }}
            onClick={() => setDeleteOpen(true)}>
            {t('delete_user')}
          </Button>
          <Button
            variant='contained'
            disabled={filteredUserlist.length === 0}
            onClick={() => { handleSaveUserClick() }}>
            {t('save_change')}
          </Button>
        </Box>
      </Box>

      <Divider orientation='vertical' flexItem />

      <Box width='20%' overflow='auto'>
        <List>
          {
            abilitiesList.filter(
              (ability) => (
                selectedUserAbilities.indexOf(ability.id) !== -1 &&
                ability.type === 'ui'
              )
            ).map(
              (ability) => (
                <ListItem 
                  sx={{ paddingY: 0 }}
                  key={ability.id}>
                  <ListItemText
                    primary={ability.title}
                  />
                </ListItem>
              )
            )
          }
        </List>
      </Box>
    </Box>

  </>);

}

import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import { useToken } from "./utilities/hooks";
import { TokenContextProvider } from "./contexts/TokenContext";
import Frame from './Frame'

function Routes({ routes }) {
  const { token, authorizedRoutes } = useToken()

  if (token) return (
    <Frame>
      {renderRoutes(routes.filter(route => authorizedRoutes.includes(route.path)))}
    </Frame>
  )
  return <Redirect to='/login' />
}

function Auth({ route }) {
  return (
    <TokenContextProvider>
      <Routes routes={route.routes} />
    </TokenContextProvider>
  );
}

export default Auth